import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
    height: 44,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.grayLight_1,
    borderRadius: 6,
    zIndex: 1,
  },
  buttonActive: {
    borderWidth: 2,
    borderColor: "#7133BF",
  },
  buttonText: {
    flex: 1,

    textAlign: "left",
    fontSize: fontSizes.small_2,
    fontFamily: "regular",
    color: colors.grayText,
    letterSpacing: 0.7,
  },
  buttonTextActive: {
    color: "#1C0638",
  },
  dropdown: {
    position: "absolute",
    top: 47,

    width: "100%",

    paddingTop: 6,
    paddingHorizontal: 8,
    paddingBottom: 8,

    backgroundColor: colors.white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.grayLight_1,
    borderRadius: 6,
  },
  overlay: {
    width: "100%",
    height: "100%",
  },
  item: {
    justifyContent: "center",
    alignItems: "flex-start",

    height: 32,

    marginBottom: 4,

    borderRadius: 4,
  },
  itemText: {
    paddingLeft: 8,

    fontSize: fontSizes.small_2,
    lineHeight: fontSizes.middle,
    color: "#1C0638",
    fontFamily: "regular",
    letterSpacing: 1,
  },
  itemActive: {
    backgroundColor: "#F4F3F5",
  },
  itemLine: {
    height: 1,

    marginBottom: 4,

    backgroundColor: "#DDDAE1",
  },
});

export default styles;
