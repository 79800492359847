import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  View,
  Linking,
  Alert,
  TouchableOpacity,
} from "react-native";
import moment from "moment";

import { ProgramType } from "../../types/workout";
import TimelineTitle from "./Title";
import ApproachItem from "./ApproachItem";

import styles from "./styles";
import WebView from "react-native-webview";
import Button from "../Button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../assets/theme";

const Timeline = ({
  item,
  programDatas = false,
}: {
  item: ProgramType;
  programDatas?: boolean;
}) => {
  const [currentId, setCurrentId] = useState<null | number>(null);
  const date = item?.program?.date_begin
    ? moment.utc(item?.program?.date_begin, "YYYY-MM-DD")
    : moment.utc();
  date.locale("RU");

  const handlePress = async (url: string) => {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  };

  useEffect(() => {
    const getCurrentId = async () => {
      const id = await AsyncStorage.getItem("currentTrainingId");
      setCurrentId(Number(id));
    };

    getCurrentId();
  }, []);

  return (
    <>
      {false ? (
        <View style={styles.loader}>
          <ActivityIndicator />
        </View>
      ) : (
        <>
          {programDatas ? (
            <Text style={styles.calendarDate}>
              {date?.add(item.day, "days").format("D MMM")}
            </Text>
          ) : null}
          {item?.exercises?.length ? (
            <FlatList
              pointerEvents="none"
              scrollEnabled={false}
              data={item?.exercises}
              renderItem={({ item: exercise, index: exerciseIndex }) => {
                return (
                  <>
                    <TimelineTitle
                      isStarted={exercise?.approachs?.some(
                        (approach) => approach?.user_result
                      )}
                      exerciseIndex={exerciseIndex}
                      title={exercise.exercise}
                    />
                    <FlatList
                      data={exercise?.approachs}
                      keyExtractor={(item, index) =>
                        `${item.id}${item.is_additional}`
                      }
                      renderItem={({
                        item: approachItem,
                        index: approachIndex,
                      }) => {
                        let highlightLine = false;
                        const isLastIndex =
                          approachIndex === exercise?.approachs?.length - 1;

                        if (!isLastIndex) {
                          // highlightLine = item.exercises[
                          //   exerciseIndex + 1
                          // ].approachs.some((approach) => !!approach.user_result);
                          highlightLine =
                            !!exercise.approachs[approachIndex + 1].user_result;
                        }

                        return (
                          <ApproachItem
                            title={exercise.exercise}
                            item={approachItem}
                            index={approachIndex}
                            isLastIndex={isLastIndex}
                            highlightLine={highlightLine}
                          />
                        );
                      }}
                    />
                  </>
                );
              }}
            />
          ) : (
            <View>
              <Text style={styles.programDescription}>{item.description}</Text>
              {item.link && (
                <Button
                  style={{
                    marginBottom: 30,
                    backgroundColor:
                      item.id === currentId
                        ? colors.purple
                        : item.id < currentId
                        ? colors.green
                        : colors.grayDark,
                  }}
                  title="Видео"
                  onPress={() => handlePress(item.link)}
                />
              )}
            </View>
          )}
        </>
      )}
    </>
  );
};

export default Timeline;
