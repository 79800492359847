import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CalendarField from "../CalendarField";
import Dropdown from "../Dropdown";

type SelectItem = {
  name: string;
  id: number;
  withLine: string;
};

type Props = {
  handleChangeData(data: string): void;
  type?: "program";
  position?: "top";
};

const WorkoutPlanner = ({ handleChangeData, type, position }: Props) => {
  const [selected, setSelected] = useState<SelectItem | null>(null);

  const [data, setData] = useState([
    {
      name: "Завтра",
      id: 1,
      withLine: "",
    },
    {
      name: "Через 2 дня",
      id: 2,
      withLine: "",
    },
    {
      name: "Через неделю",
      id: 3,
      withLine: "",
    },
    {
      name: "Через 2 недели",
      id: 4,
      withLine: "",
    },
    {
      name: "Через месяц",
      id: 5,
      withLine: "",
    },
    {
      name: "Выбрать день вручную",
      id: 6,
      withLine: "top",
    },
  ]);

  const handleChangeSelect = (item: SelectItem) => {
    const today = moment.utc();
    setSelected(item);

    switch (item.id) {
      case 0:
        handleChangeData("");
        break;
      case 1:
        handleChangeData(today.add(1, "day").format("YYYY-MM-DD"));
        break;
      case 2:
        handleChangeData(today.add(2, "day").format("YYYY-MM-DD"));
        break;
      case 3:
        handleChangeData(today.add(1, "week").format("YYYY-MM-DD"));
        break;
      case 4:
        handleChangeData(today.add(2, "week").format("YYYY-MM-DD"));
        break;
      case 5:
        handleChangeData(today.add(1, "month").format("YYYY-MM-DD"));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setSelected(data[data.length - 1]);
  }, []);

  useLayoutEffect(() => {
    if (type !== "program") {
      setData((data) => [
        {
          name: "Не запланирована",
          id: 0,
          withLine: "bottom",
        },
        ...data,
      ]);
    }
  }, []);

  return (
    <>
      <Dropdown
        data={data}
        name="Выберите дату"
        selected={selected}
        //@ts-expect-error
        onSelect={handleChangeSelect}
        position={position}
      />
      {selected?.id === 6 && <CalendarField onChange={handleChangeData} />}
    </>
  );
};

export default WorkoutPlanner;
