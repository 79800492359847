import api from "./instance";
import { API_PATHS } from "./paths";
import {
  ProgramByDayType,
  ProgramType,
  SaveApporachSuccess,
  SaveApproachParams,
  SaveProgramType,
  WorkoutProgramType,
  WorkoutsType,
  MainCalendarRequestSuccess,
  UpdateExerciseRequest,
  UpdateExerciseResponseSuccess,
  WorkoutTestType,
  SaveTestType,
  LoadCalendarParams,
  CalendarText,
} from "../types/workout";

export const loadWorkouts = () =>
  api.get<WorkoutsType>(`${API_PATHS.WORKOUTS}`);

export const loadLocations = () =>
  api.get<WorkoutsType>(`${API_PATHS.WORKOUT_LOCATIONS}`);

export const workoutProgram = (id?: number) =>
  api.get<WorkoutProgramType>(
    `${API_PATHS.WORKOUT_PROGRAM}?is_auto=&location_id=${id}`
  );

export const loadProgram = (id: number) =>
  api.get<ProgramType[]>(`${API_PATHS.PROGRAM}${id}`);

export const loadProgramByDay = (id: number) =>
  api.get<ProgramByDayType>(`${API_PATHS.PROGRAM_BY_DAY}${id}`);

export const loadExercisesByDay = (id: number) =>
  api.get<ProgramType>(`${API_PATHS.EXERCISES}${id}`);

export const loadExercisesByProgramByDay = (id: number) =>
  api.get<ProgramType>(`${API_PATHS.EXERCISES_PROGRAM_BY_DAY_ID}${id}`);

export const saveProgram = (data: SaveProgramType) =>
  api.post<any>(`${API_PATHS.PROGRAM_SAVE}`, data);

export const deleteProgram = () => api.post<any>(`${API_PATHS.PROGRAM_DELETE}`);

export const saveApproach = (data: SaveApproachParams) =>
  api.post<SaveApporachSuccess>(`${API_PATHS.SAVE_APPROACH}`, data);

export const loadMainCalendar = (params: LoadCalendarParams) =>
  api.get<MainCalendarRequestSuccess>(`${API_PATHS.CALENDAR_MAIN}`, { params });

export const updateExerciseDayDateStatus = ({
  id,
  data,
}: UpdateExerciseRequest) =>
  api.put<UpdateExerciseResponseSuccess>(
    `${API_PATHS.EXERCISE_UPDATE_DAY_DATE_STATUS}${id}`,
    data
  );

export const loadWorkoutTest = () =>
  api.get<WorkoutTestType>(`${API_PATHS.TEST}`);

export const saveTest = (data: SaveTestType) =>
  api.post<any>(`${API_PATHS.SAVE_TEST}`, data);

export const loadCalendarText = (params: { date: string; type?: number }) =>
  api.get<CalendarText[]>(`${API_PATHS.CALENDAR_TEXT}`, { params });
