import React from "react";
import { AppParamsList } from "../../navigation";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import styles from "./styles";
import { SafeAreaView, Text, View } from "react-native";
import Header from "../../components/Header";
import ChatTabBar from "./components/ChatTabBar/";

type NavProps = {
  route: RouteProp<AppParamsList, "Chat">;
  navigation: StackNavigationProp<AppParamsList, "Chat">;
};

const ChatContainer = ({ navigation, route }: NavProps) => {
  return <ChatScreen initChat={route.params?.initChat} />;
};

type Props = {
  initChat?: "converse" | "private";
};

const ChatScreen = ({ initChat }: Props) => {
  return (
    <SafeAreaView style={styles.container}>
      <Header title="Чат" showBackButton={false} screenName="Chat" />
      <ChatTabBar initChat={initChat} />
    </SafeAreaView>
  );
};

export default ChatContainer;
