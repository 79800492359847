import React from "react";
import styles from "./styles";
import { Pressable, View } from "react-native";

const Container = ({
  children,
  backgroundColor = "transparent",
}: {
  children: any;
  backgroundColor?: string;
}) => {
  return (
    <View style={[styles.container, { backgroundColor }]} pointerEvents="auto">
      {children}
    </View>
  );
};

export default Container;
