import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Label from "../../components/Label";
import Field from "../../components/Field";
import Header from "../../components/Header";
import Container from "../../components/Container";
import { AppParamsList } from "../../navigation";
import { ChangeProfileForm, ChangeProfileFormStat } from "../../types/profile";
import {
  selectProfile,
  selectProfileUpdateError,
  selectProfileUpdateIsLoading,
} from "../../store/selectors/profile";

import styles from "./styles";
import Button from "../../components/Button";
import { changeProfileRequest } from "../../store/actions/profile";

type NavProps = {
  route: RouteProp<AppParamsList, "ProfileStats">;
  navigation: StackNavigationProp<AppParamsList, "ProfileStats">;
};

const schema = yup.lazy((value) => {
  if (value === "") {
    return yup.string();
  }

  return yup.number().positive(" ").typeError("Введите число").nullable(true);
});

const ProfileStatsSchema = yup
  .object({
    // first_name: yup
    //   .string()
    //   .required("Имя не может быть пустым.")
    //   .trim()
    //   .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректное имя."),
    // last_name: yup
    //   .string()
    //   .required("Фамилия не может быть пустой.")
    //   .trim()
    //   .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректную фамилию."),
    weight: schema,
    waist: schema,
    hip: schema,
    neck: schema,
    height: schema,
    // password: yup.lazy((value) => {
    //   if (value === "") return yup.string();

    //   return yup.string().trim().min(6, "Пароль должен быть минимум 6 знаков");
    // }),
    // .string()
    // .notRequired()
    // // .required("Пароль не может быть пустым.")
    // .trim()
    // .min(6, "Пароль должен быть минимум 6 знаков")
    // .nullable(true),
    // email: yup
    //   .string()
    //   .required("Электронная почта не может быть пустой.")
    //   .trim()
    //   .email("Электронная почта не является действительным."),
    // phone: yup.string().trim().matches(phoneRegExp, {
    //   message: "Phone number is not valid",
    //   excludeEmptyString: true,
    // }),
  })
  .required();

const ProfileStats = ({ navigation, route }: NavProps) => {
  const dispatch = useDispatch();

  const profile = useSelector(selectProfile);
  const updateError = useSelector(selectProfileUpdateError);
  const isUpdating = useSelector(selectProfileUpdateIsLoading);

  const [headerHeight, setHeaderHeight] = useState(68);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState,
  } = useForm<ChangeProfileFormStat>({
    resolver: yupResolver(ProfileStatsSchema),
    defaultValues: {
      // weight: profile?.weight || "",
      // target_weight: profile?.target_weight || "",
      // waist: profile?.waist || "",
      // hip: profile?.hip || "",
      // bfp: profile?.bfp || '',
      // calories: profile?.calories || '',

      // вес
      weight: profile?.weight || null,

      // талия
      waist: profile?.waist || null,

      //обхват бедер
      hip: profile?.hip || null,

      // обхват шеи
      neck: profile?.neck || null,

      // рост
      height: profile?.height || null,

      // есть в апи, но нет в настройках
      // gender
      // birthday
      // receive_email_notifications
      // activity_level_id
      // target_weight
    },
    mode: "all",
  });

  const onСhangeProfile = (data: ChangeProfileFormStat) => {
    if (!profile) return;

    const changedData: any = {};

    let key: keyof ChangeProfileFormStat;
    for (key in data) {
      if (data[key] !== profile[key]) {
        changedData[key] = data[key];
      }
    }

    dispatch(
      changeProfileRequest({
        data: changedData,
      })
    );
  };

  useEffect(() => {
    const defaultValue = {
      // вес
      weight: profile?.weight || null,

      // талия
      waist: profile?.waist || null,

      //обхват бедер
      hip: profile?.hip || null,

      // обхват шеи
      neck: profile?.neck || null,

      // рост
      height: profile?.height || null,
    };

    reset(defaultValue);
  }, [
    profile?.weight,
    profile?.waist,
    profile?.hip,
    profile?.neck,
    profile?.height,
  ]);

  return (
    <Container>
      <Header
        onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
        showBackButton={true}
        backgroundColor="white"
        isContainerFixedTop
        screenName="ProfileStats"
      />
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.contentContainer,
          { paddingTop: headerHeight },
        ]}
        // showsVerticalScrollIndicator={false}
      >
        <View style={styles.contentBlock}>
          <Text style={styles.profileInfoTitle}>Измерения</Text>
          <View style={styles.profileInfoBlock}>
            <Text style={styles.profileInfoDescr}>
              Укажите ваши измерения, чтобы нагрузка тренировок соответствовала
              вашим параметрам
            </Text>
            <View style={styles.form}>
              <View style={styles.formTopBlock}>
                <View style={styles.inputField}>
                  <Label>Вес, кг</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder="Нет"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={5}
                          error={error?.message}
                        />
                      );
                    }}
                    name="weight"
                  />
                </View>
                <View style={styles.inputField}>
                  <Label>Талия, см</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder="Нет"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={5}
                          error={error?.message}
                        />
                      );
                    }}
                    name="waist"
                  />
                </View>
                <View style={styles.inputField}>
                  <Label>Бёдра, см</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder="Нет"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={5}
                          error={error?.message}
                        />
                      );
                    }}
                    name="hip"
                  />
                </View>
              </View>
              {/*<View style={styles.formTopBlock}>*/}
              {/*  <View style={styles.inputField}>*/}
              {/*    <Label>Одно бедро, см</Label>*/}
              {/*    <Controller*/}
              {/*      control={control}*/}
              {/*      render={({*/}
              {/*         field: { onChange, onBlur, value },*/}
              {/*         fieldState: { error },*/}
              {/*       }) => {*/}
              {/*        return (*/}
              {/*          <Field*/}
              {/*            // ref={countRef}*/}
              {/*            containerStyle={styles.field}*/}
              {/*            placeholder="Нет"*/}
              {/*            onChangeText={onChange}*/}
              {/*            onBlur={onBlur}*/}
              {/*            value={value ? String(value) : ""}*/}
              {/*            blurOnSubmit={false}*/}
              {/*            maxLength={5}*/}
              {/*            error={error?.message}*/}
              {/*          />*/}
              {/*        );*/}
              {/*      }}*/}
              {/*      name="weight"*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*  <View style={styles.inputField}>*/}
              {/*    <Label>Бицепс, см</Label>*/}
              {/*    <Controller*/}
              {/*      control={control}*/}
              {/*      render={({*/}
              {/*         field: { onChange, onBlur, value },*/}
              {/*         fieldState: { error },*/}
              {/*       }) => {*/}
              {/*        return (*/}
              {/*          <Field*/}
              {/*            // ref={countRef}*/}
              {/*            containerStyle={styles.field}*/}
              {/*            placeholder="Нет"*/}
              {/*            onChangeText={onChange}*/}
              {/*            onBlur={onBlur}*/}
              {/*            value={value ? String(value) : ""}*/}
              {/*            blurOnSubmit={false}*/}
              {/*            maxLength={5}*/}
              {/*            error={error?.message}*/}
              {/*          />*/}
              {/*        );*/}
              {/*      }}*/}
              {/*      name="waist"*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*  <View style={styles.inputField}>*/}
              {/*    <Label>Шея, см</Label>*/}
              {/*    <Controller*/}
              {/*      control={control}*/}
              {/*      render={({*/}
              {/*         field: { onChange, onBlur, value },*/}
              {/*         fieldState: { error },*/}
              {/*       }) => {*/}
              {/*        return (*/}
              {/*          <Field*/}
              {/*            // ref={countRef}*/}
              {/*            containerStyle={styles.field}*/}
              {/*            placeholder="Нет"*/}
              {/*            onChangeText={onChange}*/}
              {/*            onBlur={onBlur}*/}
              {/*            value={value ? String(value) : ""}*/}
              {/*            blurOnSubmit={false}*/}
              {/*            maxLength={5}*/}
              {/*            error={error?.message}*/}
              {/*          />*/}
              {/*        );*/}
              {/*      }}*/}
              {/*      name="hip"*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*</View>*/}
              <View style={styles.formMiddleBlock}>
                <View style={styles.inputField}>
                  <Label>Рост, см</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder="Нет"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={5}
                          error={error?.message}
                        />
                      );
                    }}
                    name="height"
                  />
                </View>
                <View style={styles.inputField}>
                  <Label>Шея, см</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder="Нет"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          // blurOnSubmit={false}
                          maxLength={5}
                          error={error?.message}
                        />
                      );
                    }}
                    name="neck"
                  />
                </View>
                <View style={{ width: "30%" }}></View>
              </View>
              {/* <View style={styles.formBottomBlock}>
                <View
                  style={[
                    styles.inputField,
                    { maxWidth: "47%", marginBottom: 0 },
                  ]}
                >
                  <Label>BFP, %</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder=""
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          blurOnSubmit={false}
                          maxLength={50}
                        />
                      );
                    }}
                    name="bfp"
                  />
                </View>
                <View
                  style={[
                    styles.inputField,
                    { maxWidth: "47%", marginBottom: 0 },
                  ]}
                >
                  <Label>Каллорий в день, ккал</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder=""
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          blurOnSubmit={false}
                          maxLength={50}
                        />
                      );
                    }}
                    name="calories"
                  />
                </View>
              </View> */}
            </View>
          </View>
        </View>
      </ScrollView>
      {formState?.isDirty && (
        // updateError

        <View style={styles.buttonBlock}>
          {!!updateError && (
            <View style={styles.errorContainer}>
              <Text style={styles.error}>{updateError}</Text>
            </View>
          )}
          <Button
            title="Сохранить"
            onPress={handleSubmit(onСhangeProfile)}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </View>
      )}
    </Container>
  );
};

export default ProfileStats;
