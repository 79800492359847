import {StyleSheet} from "react-native";
import {colors, fontSizes} from "../../assets/theme";

const styles = StyleSheet.create({
  workoutSlide: {
    marginTop: 24,
  },
  workoutSlideTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    marginBottom: 6,
    textTransform: "uppercase",
    fontSize: fontSizes.mini,
    fontFamily: "bold",
    color: colors.grayText,
  },
  workoutData: {
    flexDirection: "row",
    paddingHorizontal: 12,
    paddingVertical: 16,
    backgroundColor: colors.grayLight,
    borderRadius: 8,
  },
  workoutDataBlock: {
    width: "50%",
  },
  workoutDataRow: {
    flexDirection: "row",
    marginBottom: 6,
  },
  workoutDataTitle: {
    marginRight: 5,
    fontSize: fontSizes.mini,
    fontFamily: "regular",
    color: colors.grayText,
    whiteSpace: "nowrap",
  },
  workoutDataParam: {
    whiteSpace: "nowrap",
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.black,
  },
})

export default styles;
