import React from "react";
import { View, StyleSheet } from "react-native";

const ArrowDown = ({ color }: { color: string }) => {
  return (
    <View style={styles.arrowDown}>
      <View
        style={[
          styles.arrowLine,
          styles.arrowLineHorizontal,
          { backgroundColor: color },
        ]}
      />
      <View
        style={[
          styles.arrowLine,
          styles.arrowLineDiagonal,
          { backgroundColor: color },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  arrowDown: {
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  arrowLine: {
    position: "absolute",
  },
  arrowLineHorizontal: {
    width: 15,
    height: 3,
    borderRadius: 1,
    transform: [{ rotate: "65deg" }],
  },
  arrowLineDiagonal: {
    left: 14,
    width: 3,
    height: 15,
    borderRadius: 1,
    transform: [{ rotate: "25deg" }],
  },
});

export default ArrowDown;
