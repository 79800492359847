import { StyleSheet } from 'react-native';
import {colors} from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    width: 12,
    height: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    overflow: 'hidden',
  },
  false: {
    borderColor: colors.grayDark_2,
    borderWidth: 1,
  },
  true: {
    backgroundColor: colors.purple,
  },
});

export default styles;

