import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={10}
      height={18}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M9.49073 0.726861C9.8422 1.07833 9.8422 1.64818 9.49073 1.99965L2.49037 9.00001L9.49073 16.0004C9.8422 16.3518 9.8422 16.9217 9.49073 17.2732C9.13926 17.6246 8.56941 17.6246 8.21794 17.2732L0.581185 9.6364C0.229713 9.28493 0.229713 8.71509 0.581185 8.36361L8.21794 0.726861C8.56941 0.375389 9.13926 0.375389 9.49073 0.726861Z" fill="#7133BF" />
    </Svg>
  )
}

export default SvgComponent
