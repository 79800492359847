import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  label: {
    marginBottom: 2,
    fontSize: fontSizes.mini,
    fontFamily: "regular",
    color: colors.grayText,
    letterSpacing: 0.7,
  },
});

export default styles;
