import { Store } from "../reducers";
import { ProfileType, UserActivityType } from "../../types/profile";

export const selectProfile = (state: Store): ProfileType | null =>
  state.profile.data;

export const selectProfileUpdateIsLoading = (state: Store): boolean =>
  state.profile.isProfileUpdating;
export const selectProfileUpdateError = (state: Store): string =>
  state.profile.updateError;

export const selectUserActivity = (state: Store): UserActivityType[] | null =>
  state.activity.data;
export const selectUserActivityIsLoading = (state: Store): boolean =>
  state.activity.isLoading;
export const selectUserActivityError = (state: Store): string =>
  state.activity.error;

export const selectAvatar = (state: Store): string | undefined | null =>
  state.profile.data?.avatar;
export const selectIsAvatarUpdating = (state: Store): boolean =>
  state.profile.isAvatarUpdating;
export const selectAvatarUpdatingError = (state: Store): string =>
  state.profile.avatarError;
