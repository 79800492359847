import { handleActions } from "redux-actions";

import * as actions from "../../actions/checkout";
import { CheckoutTarif } from "../../../types/checkout";

type State = typeof initialState;

const initialState = {
  data: null as null | CheckoutTarif[],
  isLoading: false,
  error: "",
};

const tariffsReducer = handleActions<State, any>(
  {
    [actions.loadCheckoutTariffsRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadCheckoutTariffsSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadCheckoutTariffsSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadCheckoutTariffsFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadCheckoutTariffsFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default tariffsReducer;
