import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    padding: 12,
    marginBottom: 6,
    borderRadius: 6,
    backgroundColor: colors.purple,
  },
  buttonSecondary: {
    backgroundColor: colors.grayDark,
  },
  buttonDisabled: {
    backgroundColor: "#DACBEE",
  },
  buttonPressed: {
    backgroundColor: "#481588",
  },
  buttonDisabledSecondary: {
    backgroundColor: "#EEEBF1",
  },
  buttonPressedSecondary: {
    backgroundColor: "#BDB5C5",
  },
  title: {
    fontSize: fontSizes.small_2,
    fontFamily: "bold",
    color: colors.grayLight,
    letterSpacing: 0.7,
  },
  titleSecondary: {
    color: colors.black,
  },
  disabledText: {
    color: colors.grayText,
  },
  whiteBtn: {
    paddingVertical: 0,
    backgroundColor: "transparent",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  whiteBtnText: {
    color: colors.purple,
    fontSize: fontSizes.small,
    fontFamily: "semibold",
    letterSpacing: 0.7,
  },
  icon: {
    marginLeft: 6,
  },
});

export default styles;
