import React, { useEffect, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { AppParamsList } from "../../navigation";
import Header from "../../components/Header";
import { navigate } from "../../navigation/actions";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Subscription from "../../components/Subscription";
import {
  selectProfile,
  selectUserActivity,
} from "../../store/selectors/profile";
import { loadUserActivityRequest } from "../../store/actions/profile";
import UserStat from "../../components/UserStat";
import { loadWorkoutsRequest } from "../../store/actions/workout";
import { selectWorkoutData } from "../../store/selectors/workout";
import ProfileAvatarName from "./ProfileAvatarName";

import styles from "./styles";
import RecomendationsType from "./RecomendationsType";

type NavProps = {
  route: RouteProp<AppParamsList, "Profile">;
  navigation: StackNavigationProp<AppParamsList, "Profile">;
};

const ProfileContainer = ({ navigation }: NavProps) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const workoutData = useSelector(selectWorkoutData);
  const activityData = useSelector(selectUserActivity);

  const [headerHeight, setHeaderHeight] = useState(68);

  const subsIsActive = !!profile?.subscriptionsData?.length;

  useEffect(() => {
    if (!workoutData) {
      dispatch(loadWorkoutsRequest());
    }
  }, [workoutData]);

  useEffect(() => {
    if (!activityData) {
      dispatch(loadUserActivityRequest());
    }
  }, [activityData]);

  return (
    <View>
      <Container>
        <Header
          onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
          showBackButton={false}
          title="Профиль"
          backgroundColor="white"
          isContainerFixedTop
          screenName="Profile"
        />
        <ScrollView
          bounces={false}
          contentContainerStyle={[
            styles.contentContainer,
            { paddingTop: headerHeight },
          ]}
        >
          <View style={styles.contentBlock}>
            <ProfileAvatarName />
            {subsIsActive && (
              <View style={{ marginTop: 8, marginBottom: 16 }}>
                <Subscription />
              </View>
            )}
            <Button
              style={{ marginTop: 16 }}
              title={subsIsActive ? "Продлить подписку" : "Купить подписку"}
              whiteButton={true}
              onPress={() => navigate("Subscription")}
            />
            <View style={{ marginTop: 16 }}>
              <UserStat
                title="Цель и уровень активности"
                btnTitle="Изменить"
                goalActive={true}
                btnHandler={() => navigate("ExerciseGoal")}
              />
              <UserStat
                title="Измерения"
                btnTitle="Обновить"
                fullStat={false}
                btnHandler={() => navigate("ProfileStats")}
              />
              <RecomendationsType recType={profile?.recomendation_type} />
            </View>
          </View>
        </ScrollView>
      </Container>
    </View>
  );
};

export default ProfileContainer;
