import { Text, View } from "react-native";
import styles from "./styles";

import Button from "../../components/Button";
import { navigate, replace } from "../../navigation/actions";
import React from "react";

const QuizFinalScreen = ({ id }: { id: number }) => {
  return (
    <View style={styles.quizContent}>
      <View style={styles.quizCountBlock}>
        <Text style={styles.quizTitle}>Спасибо! Удачной тренировки.</Text>
      </View>
      <View style={styles.buttonBlock}>
        <Button
          title="Поехали!"
          onPress={() => {
            replace("WorkoutDay", {
              // day: item.day,
              // dayId: item.id,
              // initDate: item.date,
              // status: item.status,
              programDayId: id,
            });
          }}
        />
      </View>
    </View>
  );
};

export default QuizFinalScreen;
