import React, { useCallback, useEffect, useState } from "react";
import { ScrollView, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Field from "../../components/Field";
import Container from "../../components/Container";
import Label from "../../components/Label";
import Error from "../../components/Error";
import { colors } from "../../assets/theme";
import {
  loadExercisesByProgramDayIdRequest,
  saveApproachRequest,
} from "../../store/actions/workout";
import { SaveApproachParams, WorkoutStatus } from "../../types/workout";
import useUpdateExerciseData from "../../hooks/useUpdateExerciseData";

import styles from "./styles";
import {
  selectExercisesByProgramDayIdData,
  selectLocationsData,
  selectProgram,
  selectWorkoutProgram,
} from "../../store/selectors/workout";
import useExerciseInfo from "../../hooks/useExerciseInfo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { selectProfile } from "../../store/selectors/profile";

type NavProps = {
  route: RouteProp<AppParamsList, "Exercise">;
  navigation: StackNavigationProp<AppParamsList, "Exercise">;
};

const schema = yup.lazy((value) => {
  if (value === "") {
    return yup.string().matches(/^[1-9]+[0-9]*$/, "Введите число больше нуля.");
  }

  return yup.number().positive(" ").typeError("Введите число").nullable(true);
});

const exerciseSchema = yup
  .object({
    repeat: schema,
    weight: schema,
    pre: schema,
    // pre: yup.number().matches(/^[5-9]{9,10}$/, "Введите число в диапозоне 5-10."),
  })
  .required();

const ExerciseContainer = ({ navigation, route }: NavProps) => {
  const { id, programDayId, additional } = route.params;

  const dispatch = useDispatch();
  const program = useSelector(selectExercisesByProgramDayIdData);
  const programData = useSelector(selectProgram);
  const profile = useSelector(selectProfile);
  const workoutProgramData = useSelector(selectWorkoutProgram);

  const { updateExercise, isLoading, error } = useUpdateExerciseData("");
  const {
    repeat,
    weight,
    pre,
    isAdditional,
    title,
    exerciseName,
    calendarId,
    isLastExercise,
    nextId,
    nextAdditional,
    isSearchTraining,
  } = useExerciseInfo({ id, additional });

  const [visibleComment, setVisibleComment] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const countRef = React.useRef<TextInput>(null);
  const commentRef = React.useRef<TextInput>(null);

  // useEffect(() => {
  //   if (!program) {
  //     dispatch(loadExercisesByProgramDayIdRequest({ id: programDayId }));
  //   }
  // }, []);

  useFocusEffect(
    useCallback(() => {
      dispatch(loadExercisesByProgramDayIdRequest({ id: programDayId }));
    }, [])
  );

  const { control, handleSubmit, setValue, getValues, setFocus, reset } =
    useForm<Pick<SaveApproachParams, "weight" | "repeat" | "pre">>({
      resolver: yupResolver(exerciseSchema),
      defaultValues: {
        repeat: repeat || 0,
        weight: weight || 0,
        pre: pre || 0,
      },
      mode: "all",
    });

  const handlePressFinish = async () => {
    if (programData && workoutProgramData) {
      const currentProgramId = await AsyncStorage.getItem("currentProgramId");
      await AsyncStorage.setItem("active", "false");
      const trainingIndex = programData?.findIndex(
        (e) => e.id === programDayId
      );

      if (trainingIndex + 1 === programData.length) {
        const programIndex: number = workoutProgramData.findIndex(
          (e) => e.id === Number(currentProgramId)
        );
        if (programIndex + 1 === workoutProgramData.length) {
          await AsyncStorage.setItem(
            "currentProgramId",
            workoutProgramData[0].id.toString()
          );
        } else {
          console.log("ddaaad");
          await AsyncStorage.setItem(
            "currentProgramId",
            workoutProgramData[programIndex + 1].id.toString()
          );
          await AsyncStorage.setItem("currentTrainingId", "false");
        }
      } else {
        await AsyncStorage.setItem(
          "currentTrainingId",
          programData[trainingIndex + 1].id.toString()
        );
      }
    }
    updateExercise(
      calendarId,
      moment.utc().format("YYYY-MM-DD"),
      WorkoutStatus.end,
      (data) => {
        navigation.navigate("CompleteWorkout", { data });
      }
    );
  };

  const handlePressNext = ({
    weight,
    repeat,
    pre,
  }: Pick<SaveApproachParams, "weight" | "repeat" | "pre">) => {
    setIsSaving(true);
    setErrorMessage("");
    // Сохраняем результат тренировки
    dispatch(
      saveApproachRequest({
        data: {
          program_day_exercise_approach_id: id,
          weight: weight,
          repeat: repeat,
          pre: pre,
          view_weight: weight,
          view_repeat: repeat,
          view_pre: pre,
          is_additional: isAdditional,
        },
        stopLoading: () => setIsSaving(false),
        // При успешном сохранении результата тренировки выполняем одну из функций
        // Если тренировка была последней то заканчиваем с соответстсвующим статусом и переходим на экран окончания тренировки
        // Если еще есть не выполненные тренировки то переходим к ней
        // Если ничего не соответствует условию то переходим на экран к тренировкам
        func: () => {
          if (isLastExercise) {
            updateExercise(
              calendarId,
              moment.utc().format("YYYY-MM-DD"),
              WorkoutStatus.end,
              (data) => navigation.navigate("CompleteWorkout", { data })
            );
            return;
          }

          if (
            typeof nextId === "number" &&
            typeof nextAdditional === "number"
          ) {
            navigation.replace("Exercise", {
              programDayId,
              id: nextId,
              additional: nextAdditional,
            });
            return;
          }

          navigation.navigate("WorkoutDay", { programDayId });
        },
        errorFunc: (message) => {
          setErrorMessage(message);
        },
      })
    );
    dispatch(loadExercisesByProgramDayIdRequest({ id: programDayId }));
  };

  const handlePressComment = () => {
    setVisibleComment(true);
  };

  useEffect(() => {
    reset({
      repeat: repeat || 0,
      weight: weight || 0,
      pre: pre || 0,
    });
  }, [repeat, weight, pre]);

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header
          showBackButton={true}
          backButtonText={title}
          backButtonFunc={() =>
            navigation.navigate("WorkoutDay", { programDayId })
          }
          backgroundColor={colors.white}
        />
        <View style={styles.contentBlock}>
          <View style={styles.exerciseTitleBlock}>
            {/* <Text style={styles.exerciseTitle}>Подход №1</Text> */}
            <Text style={styles.exerciseType}>{exerciseName}</Text>
          </View>
          <View style={styles.exerciseCounts}>
            <View>
              <View style={styles.circle}>
                <Text style={styles.circleText}>{repeat || 0}</Text>
              </View>
              <Text style={styles.circleDescr}>Количество,{"\n"}раз</Text>
            </View>
            <View style={{ alignSelf: "flex-start" }}>
              <View style={styles.circle}>
                <Text style={styles.circleText}>{weight || 0}</Text>
              </View>
              <Text style={styles.circleDescr}>Вес, кг</Text>
            </View>
            <View>
              <View style={styles.circle}>
                <Text style={styles.circleText}>{pre || 0}</Text>
              </View>
              <Text style={styles.circleDescr}>RPE</Text>
            </View>
          </View>
          <View style={styles.exerciseTitleBlock}>
            <Text style={styles.exerciseTitle}>Результат</Text>
            <View style={styles.form}>
              <View>
                <Label>Количество</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        ref={countRef}
                        containerStyle={styles.field}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={String(value)}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="repeat"
                />
              </View>
              <View>
                <Label>Вес</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        ref={countRef}
                        containerStyle={styles.field}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={String(value)}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="weight"
                />
              </View>
              <View>
                <Label>RPE</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        ref={countRef}
                        containerStyle={styles.field}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={String(value)}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="pre"
                />
              </View>
            </View>
            {/* {!visibleComment ? (
              <Button
                title="Добавить комментарий"
                whiteButton={true}
                onPress={handlePressComment}
              />
            ) : null}
            {visibleComment ? (
              <View style={{ width: "100%" }}>
                <Label>Комментарий</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        ref={commentRef}
                        placeholder="Опишите, как далось вам упражнение"
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                      />
                    );
                  }}
                  name="comment"
                />
              </View>
            ) : null} */}
          </View>
        </View>
      </ScrollView>
      <View style={styles.buttonBlock}>
        <Error error={errorMessage || error} />
        <Button
          disabled={isSaving || isLoading || isSearchTraining}
          isLoading={isSaving || isLoading || isSearchTraining}
          title="Продолжить"
          onPress={handleSubmit(handlePressNext)}
        />
        <Button
          disabled={isSaving || isLoading || isSearchTraining}
          isLoading={isSaving || isLoading || isSearchTraining}
          title="Закончить тренировку"
          isSecondaryButton
          // style={{ backgroundColor: colors.grayDark }}
          // textStyle={{ color: colors.black }}
          onPress={handlePressFinish}
        />
      </View>
    </Container>
  );
};

export default ExerciseContainer;
