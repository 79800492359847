import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Pressable, ScrollView, Text, TextInput, View} from "react-native";
import {RouteProp} from "@react-navigation/native";
import {AppParamsList} from "../../navigation";
import {StackNavigationProp} from "@react-navigation/stack";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';
import styles from "./styles";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Field from "../../components/Field";
import IconCheck from "../../assets/images/svg/IconCheck";
import {navigate} from "../../navigation/actions";
import Container from "../../components/Container";
import Label from "../../components/Label";
import {colors} from "../../assets/theme";
import {passwordRecoveryRequest} from "../../store/actions/authentication";

type NavProps = {
  route: RouteProp<AppParamsList, 'NewPassword'>;
  navigation: StackNavigationProp<AppParamsList, 'NewPassword'>;
};

const newPasswordSchema = yup
  .object({
    password: yup.string().required('Пароль не может быть пустым.').trim().min(6),
  })
  .required();

const NewPasswordContainer = ({navigation}: NavProps) => {
  const dispatch = useDispatch();
  const [recordDone, setRecordDone] = useState(true);

  const { control, handleSubmit, setValue, getValues, setFocus, reset } =
    useForm({
      resolver: yupResolver(newPasswordSchema),
      defaultValues: {
        password: '',
      },
      mode: 'all',
    });

  const onSubmit = (data) => {
    // setRecordDone(false);
    console.log(data)
    // dispatch(
    //   passwordRecoveryRequest({
    //     data
    //   })
    // );
  };

  return (
    <NewPasswordScreen
      recordDone={recordDone}
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  )
}

const NewPasswordScreen = ({
  recordDone,
  control,
  handleSubmit,
  onSubmit,
}) => {
  const passwordRef = React.useRef<TextInput>(null);

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        {
          recordDone
          ? <View>
              <Header showBackButton={false} showMenu={false} />
              <View style={styles.contentBlock}>
                <Text style={styles.title}>Придумайте{'\n'}новый пароль</Text>
                <View style={styles.forgotPasswordBlock}>
                  <Text style={styles.forgotPasswordTitle}>для аккаунта </Text>
                  <Text style={styles.forgotPassword}>kkonstantin1985@gmail.com</Text>
                </View>
                <View style={styles.fieldWrap}>
                  <Label>Пароль</Label>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <Field
                          ref={passwordRef}
                          placeholder=""
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          blurOnSubmit={false}
                          maxLength={50}
                          error={error?.message}
                        />
                      );
                    }}
                    name="password"
                  />
                </View>
              </View>
            </View>
          : <View style={styles.contentBlock}>
              <View style={styles.completeDoneBlock}>
                <View style={styles.checkIcon}>
                  <IconCheck />
                </View>
                <Text style={styles.completeTextBlockTitle}>Новый пароль сохранён.</Text>
              </View>
            </View>
        }
      </ScrollView>
      <View style={styles.buttonBlock}>
        {
          recordDone
          ? <Button title='Готово' onPress={() => navigate('Main')} />
          : <Button title='Вернуться на главную' onPress={() => navigate('SignIn')} />
        }
      </View>
    </Container>
  )
}

export default NewPasswordContainer;
