import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import { DataItem } from "../../types/recomendations";
import IconRight from "../../assets/images/svg/IconRight";
import Button from "../../components/Button";
import moment from "moment";

type Props = {
  item: DataItem;
  isLastIndex: boolean;
  onPress: Function;
  index: number;
};

const MetricsItem = ({ item, isLastIndex, onPress, index }: Props) => {
  const [titleHeight, setTitleHeight] = useState(0);

  return (
    <>
      {index === 0 && (
        <View>
          <Text style={styles.dayTitle}>Неделя 1</Text>
          <View style={styles.dayCircleTitle}></View>
          <View style={styles.dayTimelineTitleBottom}></View>
        </View>
      )}
      {index === 7 && (
        <View>
          <Text style={styles.dayTitle}>Неделя 2</Text>
          <View style={styles.dayCircleTitle}></View>
          <View style={styles.dayTimelineTitleBottom}></View>
        </View>
      )}
      <View style={styles.day}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Text
            onLayout={(event) =>
              setTitleHeight(event.nativeEvent.layout.height)
            }
            style={[styles.exerciseTitle]}
          >
            {moment.utc(item.date).locale("ru").format("D MMMM").toString()}
          </Text>
          <View style={{ width: 35, justifyContent: "flex-start" }}>
            <Text style={styles.exerciseSubtitle}>
              {`${item.weight === undefined ? 0 : item.weight} кг`}
            </Text>
          </View>
          <Text style={styles.exerciseSubtitle}>
            {`${item.calories === undefined ? 0 : item.calories} ккал`}
          </Text>
          <Button
            title={
              item.calories === undefined || item.weight === undefined
                ? "Добавить"
                : "Обновить"
            }
            whiteButton={true}
            Icon={IconRight}
            onPress={() => onPress(item.date)}
          />
        </View>
        <View style={[styles.exerciseCircle]} />
        {!isLastIndex && (
          <View
            style={[
              styles.exerciseTimeline,
              {
                height: titleHeight,
              },
            ]}
          />
        )}
      </View>
    </>
  );
};

export default MetricsItem;
