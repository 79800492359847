import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { CalendarText } from "../../../types/workout";

type State = typeof initialState;

const initialState = {
  // data: [] as any[],
  // data: null as null | string,
  data: null as null | CalendarText[],
  isLoading: false,
  error: "",
};

const calendarTextReducer = handleActions<State, any>(
  {
    [actions.loadCalendarTextRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadCalendarTextSucces.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadCalendarTextSucces>
    ) => ({
      ...state,
      isLoading: false,
      // data: [...state.data, payload]
      data: payload,
    }),
    [actions.loadCalendarTextFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadCalendarTextFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default calendarTextReducer;
