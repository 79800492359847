import React, { useLayoutEffect } from "react";
import { Pressable, ScrollView, Text, TextInput, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { StackNavigationProp } from "@react-navigation/stack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { AppParamsList } from "../../navigation";

import { colors } from "../../assets/theme";
import { navigate } from "../../navigation/actions";
import Button from "../../components/Button";
import Field from "../../components/Field";
import Container from "../../components/Container";
import Label from "../../components/Label";
import { SignInFormType } from "../../types/authentication";
import {
  signInFailure,
  signInRequest,
} from "../../store/actions/authentication";
import {
  selectSignInError,
  selectSignInIsLoading,
} from "../../store/selectors/authentication";

import styles from "./styles";

const signInSchema = yup
  .object({
    email: yup
      .string()
      .required("Электронная почта не может быть пустой.")
      .trim()
      .email("Электронная почта не является действительным."),
    password: yup
      .string()
      .required("Пароль не может быть пустым.")
      .trim()
      .min(6),
  })
  .required();

type NavProps = {
  route: RouteProp<AppParamsList, "SignIn">;
  navigation: StackNavigationProp<AppParamsList, "SignIn">;
};

const SignInContainer = ({ navigation }: NavProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSignInIsLoading);
  const error = useSelector(selectSignInError);

  const emailRef = React.useRef<TextInput>(null);
  const passwordRef = React.useRef<TextInput>(null);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
      remember: true,
    },
    mode: "all",
  });

  useLayoutEffect(() => {
    dispatch(signInFailure(null));
  }, []);

  const onLogin = (data: SignInFormType) => {
    dispatch(
      signInRequest({
        data,
      })
    );
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <View style={styles.contentBlock}>
          <Text style={styles.title}>Вход</Text>
          <View style={styles.form}>
            <View style={styles.fieldWrap}>
              <Label>Электронный адрес</Label>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Field
                      ref={emailRef}
                      placeholder=""
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      blurOnSubmit={false}
                      maxLength={50}
                      error={error?.message}
                    />
                  );
                }}
                name="email"
              />
            </View>
            <View style={styles.fieldWrap}>
              <Label>Пароль</Label>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Field
                      ref={passwordRef}
                      placeholder=""
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      blurOnSubmit={false}
                      maxLength={50}
                      error={error?.message}
                      secureTextEntry
                    />
                  );
                }}
                name="password"
              />
            </View>
          </View>
          <Button
            title="Забыли пароль?"
            whiteButton={true}
            onPress={() => navigate("ForgotPassword")}
          />
        </View>
      </ScrollView>
      <View style={styles.buttonBlock}>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{error}</Text>
        </View>
        <Button
          title="Войти"
          onPress={handleSubmit(onLogin)}
          disabled={isLoading}
          isLoading={isLoading}
        />
        <Button
          title="Зарегистрироваться"
          isSecondaryButton
          // style={{ backgroundColor: colors.grayDark }}
          // textStyle={{ color: colors.black }}
          onPress={() => navigate("SignUp")}
        />
      </View>
    </Container>
  );
};

export default SignInContainer;
