import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 10,
  },
  outside: {
    height: 18,
    width: 18,
    borderRadius: 9,
    borderWidth: 2,
    borderColor: colors.purple,
    alignItems: "center",
    justifyContent: "center",
  },
  inside: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: colors.purple,
  },
  title: {
    marginLeft: 10,
    textAlign: "left",
    fontSize: fontSizes.small,
    color: colors.black,
  },
});

export default styles;
