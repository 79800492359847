import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ScrollView, Text, TextInput, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { AppParamsList } from "../../navigation";
import { StackNavigationProp } from "@react-navigation/stack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Field from "../../components/Field";
import IconCheck from "../../assets/images/svg/IconCheck";
import { navigate } from "../../navigation/actions";
import Container from "../../components/Container";
import Label from "../../components/Label";
import { colors } from "../../assets/theme";
import { passwordRecovery } from "../../api/authentication";
import { StatusCodes } from "../../types/api";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "ForgotPassword">;
  navigation: StackNavigationProp<AppParamsList, "ForgotPassword">;
};

const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .required("Электронная почта не может быть пустой.")
      .trim()
      .email("Электронная почта не является действительным."),
  })
  .required();

const ForgotPasswordContainer = ({ navigation }: NavProps) => {
  const dispatch = useDispatch();
  const [recordDone, setRecordDone] = useState(true);
  const [dataEmail, setDataEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const emailRef = React.useRef<TextInput>(null);

  const { control, handleSubmit, setValue, getValues, setFocus, reset } =
    useForm({
      resolver: yupResolver(forgotPasswordSchema),
      defaultValues: {
        email: "",
      },
      mode: "all",
    });

  const onSubmit = async (data: { email: string }) => {
    try {
      setIsLoading(true);
      setError("");
      const response = await passwordRecovery({ email: data.email });

      if (response.status === StatusCodes.success) {
        setDataEmail(data.email);
        setRecordDone(false);
      } else {
        setError(
          "Ошибка. Проверьте правильность введенных данных и попробуйте ещё раз"
        );
      }
    } catch (error) {
      setError(
        "Ошибка. Проверьте правильность введенных данных и попробуйте ещё раз"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        {recordDone ? (
          <View>
            <Header showBackButton={true} showMenu={false} />
            <View style={styles.contentBlock}>
              <Text style={styles.title}>Восстановить пароль</Text>
              <Text style={styles.conditions}>
                Укажите электронный адрес вашего акканунта, чтобы получить
                инструкцию со сменой пароля.
              </Text>
              <View style={styles.fieldWrap}>
                <Label>Электронный адрес</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        ref={emailRef}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="email"
                />
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.contentBlock}>
            <View style={styles.completeDoneBlock}>
              <View style={styles.checkIcon}>
                <IconCheck />
              </View>
              <Text style={styles.completeTextBlockTitle}>
                Инструкция со сменой пароля отправлена на почту{" "}
                {dataEmail ? dataEmail : ""}
              </Text>
            </View>
          </View>
        )}
      </ScrollView>
      <View style={styles.buttonBlock}>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{error}</Text>
        </View>
        {recordDone ? (
          <Button
            title="Отправить"
            onPress={handleSubmit(onSubmit)}
            isLoading={isLoading}
            disabled={isLoading}
          />
        ) : (
          <Button
            title="Вернуться на главную"
            onPress={() => navigate("SignIn")}
          />
        )}
      </View>
    </Container>
  );
};

export default ForgotPasswordContainer;
