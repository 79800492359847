import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {

};

function SvgComponent({ ...props }: Props) {
  return (
    <Svg
      width={64}
      height={116}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M29.4758 116H64V0H29.4758L0 19.4539V47.9917L28.8244 29.1809H29.4758V116Z" fill="white" />
    </Svg>
  );
}

export default SvgComponent;
