import {StyleSheet} from "react-native";
import {colors, fontSizes} from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 20,
  },
  title: {
    marginBottom: 16,
    fontSize: fontSizes.regular_1,
    fontFamily: 'bold',
    color: colors.black,
  },
});

export default styles;
