import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {

};

function SvgComponent({ ...props }: Props) {
  return (
    <Svg
      width={92}
      height={116}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M1.96413 116H92V90.7311H30.6405L44.5465 101.47V78.1756L30.5619 101.47L65.1307 73.3587C71.2064 68.4103 76.1036 63.9882 79.8224 60.0926C83.5935 56.197 86.3433 52.275 88.0717 48.3268C89.8002 44.3785 90.6644 39.8775 90.6644 34.8237V34.6658C90.6644 27.6642 88.7788 21.5838 85.0077 16.4248C81.2889 11.2131 76.025 7.18584 69.2161 4.34309C62.4594 1.4477 54.4981 0 45.3322 0C36.3757 0 28.493 1.63195 21.684 4.89585C14.875 8.1071 9.55878 12.6344 5.73527 18.4779C1.91176 24.2687 0 31.0334 0 38.772V39.5616H30.3262L30.4048 38.5351C30.6143 35.7976 31.3214 33.4023 32.526 31.3492C33.7307 29.2435 35.4068 27.6115 37.5542 26.4534C39.7541 25.2952 42.3467 24.7161 45.3322 24.7161C47.951 24.7161 50.2556 25.2426 52.2459 26.2954C54.2363 27.2957 55.7814 28.6907 56.8813 30.4806C58.0336 32.2705 58.6097 34.3499 58.6097 36.7189V36.8768C58.6097 38.9825 58.1907 41.0619 57.3527 43.115C56.567 45.1155 54.9957 47.4318 52.6388 50.064C50.3342 52.6435 46.8511 55.8548 42.1896 59.6978L1.96413 93.179V116Z" fill="white" />
    </Svg>
  );
}

export default SvgComponent;
