import api from "./instance";
import { API_PATHS } from "./paths";
import { MessageParamsType, MessageBodyType, MessageCreateParamsType, MessageCreateResponse } from '../types/chat';

export const loadMessages = (params: Partial<MessageParamsType>) => 
  api.get<MessageBodyType[]>(`${API_PATHS.MESSAGES_LOAD}`, {params});

export const createMessage = (params: Partial<MessageCreateParamsType>) => 
  api.post<MessageCreateResponse>(`${API_PATHS.MESSAGE_CREATE}`, params);

export const deleteMessage = (id: string) => 
  api.delete<boolean>(`${API_PATHS.MESSAGE_DELETE}${id}`);

export const createAttachment = (params:{id: string; file:any}) => 
  api.post<any>(`${API_PATHS.MESSAGE_CREATE_ATTACHMENT}${params.id}`, params.file);

export const loadUnviewedCount = (params:{type: string | null}) => 
  api.get<number>(`${API_PATHS.MESSAGES_NOT_VIEWED_COUNT}`, {params});

export const saveViews = (message_ids: {message_ids: string}) =>
  api.post<any>(`${API_PATHS.MESSAGES_SAVE_VIEWS}`, message_ids);

  export const deleteAttachment = (id: string) => 
  api.delete<boolean>(`${API_PATHS.ATTACHMENT_DELETE}${id}`);