import { StyleSheet } from "react-native";
import {colors, fontSizes} from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontFamily: "bold",
  },
  switcherTitle: {
    marginBottom: 6,
    textTransform: "uppercase",
    fontSize: fontSizes.mini,
    fontFamily: "bold",
    color: colors.grayText,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});

export default styles;
