import * as ImagePicker from "expo-image-picker";
import React, { useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import IconPlus from "../../../../assets/images/svg/IconPlus";
import IconDeleteImage from "../../../../assets/images/svg/IconDeleteImage";
import MessageSendIcon from "../../../../assets/images/svg/MessageSendIcon";
import Field from "../../../../components/Field";
import PressableButton from "../../../../components/Pressable";
import {
  createMessageRequest,
} from "../../../../store/actions/chat";
import { selectProfile } from "../../../../store/selectors/profile";
import styles from "./styles";

const windowWidth = Dimensions.get("window").width;

type Props = {
  userSelectId?: number | null;
  listType?: string;
  parentId? : number | null
};

const MessageInput = ({ userSelectId, listType, parentId }: Props) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [inputHeight, setInputHeight] = useState(32);
  const [inputValue, setInputValue] = useState("");
  const [mediasData, setMediasData] = useState<any[]>([]);
  const [isMediaSelected, setIsMediaSelected] = useState(false);

  const attachMedia = async () => {
    let result: any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
      quality: 1,
      base64: true,
      allowsMultipleSelection: true,
    });
    setMediasData([...mediasData, ...result?.selected]);
    setIsMediaSelected(true);
    if (result.cancelled) return;
  };

  const deleteMedia = (uri: string) => {
    const data = [...mediasData];
    const newData = data.filter((item) => item.uri !== uri);
    setMediasData(newData);
    if (newData.length === 0) setIsMediaSelected(false);
  };

  const sendMessage = (message: any) => {
    const files = [...mediasData];
    const sendAttacments = async () => {
      const newFiles: any[] = [];
      files.forEach(async (item, index) => {
        const result = await fetch( item.uri);
        const blobItem = await result.blob();
        const img = new File([blobItem], `file_${index}.jpeg`);
        const formData = new FormData();
        formData.append("file", img);
        newFiles.push(formData);
      })
        dispatch(
          createMessageRequest({
            data: {
              type: listType,
              message: message ? message.toString() : " ",
              parent_id: parentId,
              user_own_id: listType==="2"? userSelectId: null,
            },
            isMediaAttaching: true,
            file: newFiles,
          })
        );
    };

    if (files.length > 0) {
      sendAttacments();
    } else {
      dispatch(
        createMessageRequest({
          data: {
            type: listType,
            message: message.toString(),
            parent_id: parentId,
            user_own_id: listType==="2"? userSelectId: null,
          },
        })
      );
    }
    setInputValue("");
    setMediasData([]);
    setIsMediaSelected(false);
  };

  const ImageItem = ({ item }: any) => (
    <View style={{ width: 60, height: 60, marginRight: 8 }}>
      <PressableButton onPress={() => deleteMedia(item.uri)}>
        <View style={styles.deleteIcon}>
          <IconDeleteImage />
        </View>
      </PressableButton>
      <Image
        style={{ width: 60, height: 60, borderRadius: 4, zIndex: -1 }}
        source={{ uri: item.uri }}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.separator}></View>
      <View style={styles.mainWrapper}>
        <View style={styles.inputAndPlusWrapper}>
          <View style={styles.plusWrapper}>
            <PressableButton onPress={() => attachMedia()}>
              <IconPlus />
            </PressableButton>
          </View>
          <Field
            placeholder=""
            onChangeText={setInputValue}
            value={inputValue}
            containerStyle={{
              height: inputValue === "" ? 32 : inputHeight,
              maxHeight: 115,
              width: windowWidth * 0.7,
            }}
            inputStyle={{
              height: inputValue === "" ? 32 : inputHeight,
              maxHeight: 115,
              paddingVertical: 6,
              paddingHorizontal: 16,
            }}
            onContentSizeChange={(event) => {
              setInputHeight(event.nativeEvent.contentSize.height);
            }}
            multiline
            textAlignVertical="top"
          />
        </View>
        <PressableButton onPress={() => sendMessage(inputValue)}>
          <MessageSendIcon />
        </PressableButton>
      </View>
      {isMediaSelected && (
        <FlatList
          style={{ marginLeft: 16, marginBottom: 12 }}
          data={mediasData}
          renderItem={ImageItem}
          keyExtractor={(item) => item.uri}
          horizontal
        />
      )}
    </View>
  );
};

export default MessageInput;
