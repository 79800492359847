import { StyleSheet, Dimensions } from "react-native";
import { colors, fontSizes } from "../../../../assets/theme";
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  ownMessageBubble:{
    backgroundColor: colors.grayDark,
    marginLeft: '45%',
    borderRadius: 6,
    marginBottom: 12,
    marginTop: 5,
    marginRight: 20,
    maxWidth: 'fit-content',
    alignSelf: 'flex-end',
  },
  rightArrow: {
    position: "absolute",
    backgroundColor: colors.grayDark,
    width: 20,
    height: 14,
    bottom: 5,
    right: -18
  },
  
  rightArrowOverlap: {
    position: "absolute",
    backgroundColor: colors.grayLight,
    width: 20,
    height: 17,
    bottom: 3,
    borderBottomLeftRadius: 20,
    right: -20
  },
  otherMessageBubble:{
    backgroundColor: colors.white,
    marginRight: '45%',
    borderRadius: 6,
    marginBottom: 12,
    marginTop: 5,
    marginLeft: 20,
    maxWidth: '50%',
    alignSelf: 'flex-start',
  },
  leftArrow: {
    position: "absolute",
    backgroundColor: colors.white,
    width: 20,
    height: 14,
    bottom: 5,
    left: -18
  },
  
  leftArrowOverlap: {
    position: "absolute",
    backgroundColor: colors.grayLight,
    width: 20,
    height: 17,
    bottom: 3,
    borderBottomRightRadius: 20,
    left: -20
  },
  imageStyle: {
    width: 200,
    height: 120,
    padding: 0,
    margin: 0,
    borderRadius: 6,
  },
  messageContainer :{
    // flexDirection: "row",
    paddingRight: 10,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 6,
    justifyContent: "space-between",
  },
  userName: {
    color: "blue",
    paddingRight: 10,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 2,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "500",
  },
  messageText: {
    fontSize: fontSizes.small,
    color: colors.black,
    lineHeight: 18
  },
  messageDate: {
    fontSize: fontSizes.mini,
    lineHeight: 16,
    color: colors.grayText,
    opacity: 0.6,
    alignSelf: 'flex-end',
    marginLeft: 12,
    marginRight: 8
  },
  commentCount: {
    fontSize: fontSizes.mini,
    lineHeight: 16,
    color: colors.grayText,
    opacity: 0.6,
    alignSelf: 'flex-end',
    marginLeft: 2,
    marginRight: 8
  },
  modalContainer: {
    width: 233,
    height: 'fit-content',
    backgroundColor: colors.white,
    borderColor: colors.grayLight_1,
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 6,
    paddingVertical: 8,
    position: 'absolute',
    justifyContent: "space-between"
  },
  modalText: {
    fontSize: fontSizes.small_2,
    color: colors.black,
  },
  multiAttachmentsContainer : {
    minWidth: windowWidth*0.4,
    maxWidth: 'fit-content',
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  }
});

export default styles;