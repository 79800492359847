import { createAction } from "redux-actions";
import {
  DaysResponse,
  GetMetricsParams,
  GetMetricsResponse,
  MetricsType,
  SaveMetricsParams,
  SaveMetricsResponse,
} from "../../types/recomendations";

const scope = "RECOMENDATIONS";

export const loadUserMetricsRequest = createAction<{ data: GetMetricsParams }>(
  `${scope}/LOAD_USER_METRICS_REQUEST`
);
export const loadUserMetricsSuccess = createAction<GetMetricsResponse[]>(
  `${scope}/LOAD_USER_METRICS_SUCCESS`
);
export const loadUserMetricsFailure = createAction<string>(
  `${scope}/LOAD_USER_METRICS_FAILURE`
);

export const saveMetricsRequest = createAction<{ data: SaveMetricsParams }>(
  `${scope}/SAVE_METRICS_REQUEST`
);
export const saveMetricsSuccess = createAction<SaveMetricsResponse[]>(
  `${scope}/SAVE_METRICS_SUCCESS`
);
export const saveMetricsFailure = createAction<string>(
  `${scope}/SAVE_METRICS_FAILURE`
);

export const loadMetricsListRequest = createAction(
  `${scope}/LOAD_METRICS_LIST_REQUEST`
);
export const loadMetricsListSuccess = createAction<MetricsType[]>(
  `${scope}/LOAD_METRICS_LIST_SUCCESS`
);
export const loadMetricsListFailure = createAction<string>(
  `${scope}/LOAD_METRICS_LIST_FAILURE`
);

export const loadRecomendationsRequest = createAction(
  `${scope}/LOAD_RECOMENDATIONS_REQUEST`
);
export const loadRecomendationsSuccess = createAction<any>(
  `${scope}/LOAD_RECOMENDATIONS_SUCCESS`
);
export const loadRecomendationsFailure = createAction<string>(
  `${scope}/LOAD_RECOMENDATIONS_FAILURE`
);

export const loadDaysRequest = createAction(`${scope}/LOAD_DAYS_REQUEST`);
export const loadDaysSuccess = createAction<DaysResponse[]>(
  `${scope}/LOAD_DAYS_SUCCESS`
);
export const loadDaysFailure = createAction<string>(
  `${scope}/LOAD_DAYS_FAILURE`
);
