import { createAction } from "redux-actions";

import {
    MessageBodyType,
    MessageCreateResponse,
    MessageParamsType,
    MessageCreateParamsType
} from "./../../types/chat";


const scope = "CHAT";

export const loadMessagesRequest = createAction<{data: Partial<MessageParamsType>}>(`${scope}/LOAD_MESSAGES_REQUEST`);
export const loadMessagesSuccess = createAction<MessageBodyType[]>(`${scope}/LOAD_MESSAGES_SUCCESS`);
export const loadMessagesCommonSuccess = createAction<MessageBodyType[]>(`${scope}/LOAD_MESSAGES_COMMON_SUCCESS`);
export const loadMessagesFailure = createAction<string>(`${scope}/LOAD_MESSAGES_FAILURE`);

export const createMessageRequest = createAction<{data: Partial<MessageCreateParamsType>, isMediaAttaching?: boolean, file?: any}>(`${scope}/CREATE_MESSAGE_REQUEST`);
export const createMessageSuccess = createAction<boolean>(`${scope}/CREATE_MESSAGE_SUCCESS`);
export const createMessageSuccessResponse = createAction<MessageCreateResponse>(`${scope}/CREATE_MESSAGE_SUCCESS_RESPONSE`);
export const createMessageFailure = createAction<string>(`${scope}/CREATE_MESSAGE_FAILURE`);

export const createAttachmentRequest = createAction<{params:{id: string; file:any}}>(`${scope}/CREATE_ATTACHMENT_REQUEST`);
export const createAttachmentSucces = createAction<boolean>(`${scope}/CREATE_MESSAGE_SUCCESS`);
export const createAttachmentFailure = createAction<string>(`${scope}/CREATE_MESSAGE_FAILURE`);

export const loadUnviewedRequest = createAction(`${scope}/LOAD_NOT_VIEWED_COUNT`);
export const loadUnviewedSuccess = createAction<{common: number; all: number; support: number} | null>(`${scope}/LOAD_NOT_VIEWED_SUCCESS`);
export const loadUnviewedError = createAction<string>(`${scope}/LOAD_NOT_VIEWED_ERROR`);

export const saveViewesRequest = createAction<{message_ids: string}>(`${scope}/SAVE_VIEWED_REQUEST`);
export const saveUnviewedSuccess = createAction<boolean>(`${scope}/SAVE_VIEWED_SUCCESS`);

export const deleteMessageRequest = createAction<{id: string}>(`${scope}/DELETE_MESSAGE_REQUEST`);

export const deleteAttachmentRequest = createAction<{id: string}>(`${scope}/DELETE_ATTACHMENT_REQUEST`);

export const openThread = createAction<number | null>(`${scope}/OPEN_THREAD`);
