import {
  all,
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import * as recApi from "../../api/recomendations";
import * as recActions from "../actions/recomendations";
import { StatusCodes } from "../../types/api";

type LoadUserMetricsResponse = SagaReturnType<typeof recApi.loadUserMetrics>;

function* loadUserMetrics({
  payload,
}: ReturnType<typeof recActions.loadUserMetricsRequest>) {
  try {
    console.log(payload);
    const response: LoadUserMetricsResponse = yield call(
      recApi.loadUserMetrics,
      payload.data
    );
    if (response.status === StatusCodes.success) {
      console.log(response.data[0]);
      yield put(recActions.loadUserMetricsSuccess(response.data[0]));
    } else {
      yield put(
        recActions.loadUserMetricsFailure("Не удалось получить данные")
      );
    }
  } catch (err) {
    yield put(recActions.loadUserMetricsFailure("Не удалось получить данные"));
  }
}

type SaveMetricsResponse = SagaReturnType<typeof recApi.saveMetrics>;

function* saveMetrics({
  payload,
}: ReturnType<typeof recActions.saveMetricsRequest>) {
  try {
    const response: SaveMetricsResponse = yield call(
      recApi.saveMetrics,
      payload.data
    );

    if (response.status === StatusCodes.success) {
      yield put(recActions.saveMetricsSuccess(response.data));
    } else {
      yield put(recActions.saveMetricsFailure("Не удалось сохранить данные"));
    }
  } catch (err) {
    yield put(recActions.saveMetricsFailure("Не удалось сохранить данные"));
  }
}

type LoadMetricsListResponse = SagaReturnType<typeof recApi.loadMetricsList>;

function* loadMetricsList() {
  try {
    const response: LoadMetricsListResponse = yield call(
      recApi.loadMetricsList
    );

    if (response.status === StatusCodes.success) {
      yield put(recActions.loadMetricsListSuccess(response.data));
    } else {
      yield put(
        recActions.loadMetricsListFailure("Не удалось получить данные")
      );
    }
  } catch (err) {
    yield put(recActions.loadMetricsListFailure("Не удалось получить данные"));
  }
}

type LoadRecomendationsResponse = SagaReturnType<
  typeof recApi.loadRecomendations
>;

function* loadRecomendations() {
  try {
    const response: LoadRecomendationsResponse = yield call(
      recApi.loadRecomendations
    );

    if (response.status === StatusCodes.success) {
      yield put(recActions.loadRecomendationsSuccess(response.data));
    } else {
      yield put(
        recActions.loadRecomendationsFailure("Не удалось получить данные")
      );
    }
  } catch (err) {
    yield put(
      recActions.loadRecomendationsFailure("Не удалось получить данные")
    );
  }
}

type LoadDaysResponse = SagaReturnType<typeof recApi.loadDays>;

function* loadDays() {
  try {
    const response: LoadDaysResponse = yield call(recApi.loadDays);

    if (response.status === StatusCodes.success) {
      yield put(recActions.loadDaysSuccess(response.data));
    } else {
      yield put(recActions.loadDaysFailure("Не удалось получить данные"));
    }
  } catch (err) {
    yield put(recActions.loadDaysFailure("Не удалось получить данные"));
  }
}

export default function* () {
  yield all([takeEvery(recActions.loadUserMetricsRequest, loadUserMetrics)]);
  yield all([takeLatest(recActions.saveMetricsRequest, saveMetrics)]);
  yield all([takeLatest(recActions.loadMetricsListRequest, loadMetricsList)]);
  yield all([
    takeLatest(recActions.loadRecomendationsRequest, loadRecomendations),
  ]);
  yield all([takeLatest(recActions.loadDaysRequest, loadDays)]);
}
