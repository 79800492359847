import { combineReducers } from "redux";
import { resetStore } from "../actions/application";

import appReducer from "./application";
import signinReducer from "./signin";
import signupReducer from "./signup";
import profileReducer from "./profile";
import workoutProgramReducer from "./workoutProgram";
import programReducer from "./program";
import exercisesReducer from "./exercises";
import workoutReducer from "./workout";
import checkoutReducer from "./checkout";
import activityReducer from "./activity";
import chatReducer from "./chat";
import recomendationReducer from "./recomendations";

export type Store = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  application: appReducer,
  signin: signinReducer,
  signup: signupReducer,
  profile: profileReducer,
  workoutProgram: workoutProgramReducer,
  program: programReducer,
  exercises: exercisesReducer,
  workout: workoutReducer,
  checkout: checkoutReducer,
  activity: activityReducer,
  chat: chatReducer,
  recomendations: recomendationReducer,
});

const reducer = (state: Store | undefined, action: any) => {
  if (action?.type === resetStore.toString()) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export default reducer;
