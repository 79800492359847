import api from "./instance";
import { API_PATHS } from "./paths";

import {
  CheckoutTarif,
  CheckoutMethod,
  CheckoutTinkof,
  CheckoutTinkofRequest,
} from "../types/checkout";

export const loadCheckoutTariffs = () =>
  api.get<CheckoutTarif[]>(`${API_PATHS.CHECKOUT_TARIFFS}`);

export const loadCheckoutMethods = () =>
  api.get<CheckoutMethod>(`${API_PATHS.CHECKOUT_METHODS}`);

export const createCheckoutTinkof = (data: CheckoutTinkofRequest) =>
  api.post<CheckoutTinkof>(`${API_PATHS.CHECKOUT_TINKOF_CREATE}`, data);
