import { Store } from "../reducers";
import {
  DaysResponse,
  GetMetricsResponse,
  MetricsType,
  SaveMetricsResponse,
} from "../../types/recomendations";

export const selectUserMetricsData = (
  state: Store
): GetMetricsResponse[] | null => state.recomendations.userMetrics.data;
export const selectUserMetricsLoading = (state: Store): boolean =>
  state.recomendations.userMetrics.isLoading;
export const selectUserMetricsError = (state: Store): string =>
  state.recomendations.userMetrics.error;

export const selectSaveMetricsData = (
  state: Store
): SaveMetricsResponse[] | null => state.recomendations.saveMetrics.data;
export const selectSaveMetricsLoading = (state: Store): boolean =>
  state.recomendations.saveMetrics.isLoading;
export const selectSaveMetricsError = (state: Store): string =>
  state.recomendations.saveMetrics.error;

export const selectMetricsListData = (state: Store): MetricsType[] | null =>
  state.recomendations.metricsList.data;
export const selectMetricsListLoading = (state: Store): boolean =>
  state.recomendations.metricsList.isLoading;
export const selectMetricsListError = (state: Store): string =>
  state.recomendations.metricsList.error;

export const selectRecomendationsData = (state: Store): any | null =>
  state.recomendations.recomendationsList.data;
export const selectRecomendationsLoading = (state: Store): boolean =>
  state.recomendations.recomendationsList.isLoading;
export const selectRecomendationsError = (state: Store): string =>
  state.recomendations.recomendationsList.error;

export const selectDaysData = (state: Store): DaysResponse | null =>
  state.recomendations.days.data;
export const selectDaysLoading = (state: Store): boolean =>
  state.recomendations.days.isLoading;
export const selectDaysError = (state: Store): string =>
  state.recomendations.days.error;
