import React from "react";
import styles from "./styles";
import { Text, View } from "react-native";
import Button from "../Button";
import IconRight from "../../assets/images/svg/IconRight";
import { navigate } from "../../navigation/actions";
import { useSelector } from "react-redux";
import {
  selectProfile,
  selectUserActivity,
} from "../../store/selectors/profile";
import { selectWorkoutData } from "../../store/selectors/workout";

type Props = {
  title: string;
  btnTitle: string;
  btnHandler: () => void;
  fullStat?: boolean;
  goalActive?: boolean;
};

const UserStat = ({
  title,
  btnTitle,
  btnHandler,
  fullStat = false,
  goalActive = false,
}: Props) => {
  const profile = useSelector(selectProfile);
  const workoutData = useSelector(selectWorkoutData);
  const activityData = useSelector(selectUserActivity);

  const userActivity =
    activityData?.find((activity) => {
      if (activity?.id === profile?.activity_level_id) {
        return activity?.name;
      }
    })?.name || " -";

  const userWorkout =
    workoutData?.find((workout) => {
      if (workout?.id === profile?.group_id) {
        return workout?.name;
      }
    })?.name || " -";

  return (
    <View style={styles.workoutSlide}>
      <View style={styles.workoutSlideTop}>
        <Text style={styles.title}>{title}</Text>
        <View>
          <Button
            title={btnTitle}
            whiteButton={true}
            Icon={IconRight}
            onPress={btnHandler}
          />
        </View>
      </View>
      {goalActive ? (
        <View style={styles.workoutData}>
          <View style={styles.workoutDataBlock}>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Цель</Text>
              <Text style={styles.workoutDataParam}>
                {workoutData ? `${userWorkout}` : " -"}
              </Text>
            </View>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Уровень активности</Text>
              <Text style={styles.workoutDataParam}>
                {activityData ? `${userActivity}` : " -"}
              </Text>
            </View>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Желаемый вес</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.target_weight ? `${profile?.target_weight} кг` : " -"}
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.workoutData}>
          <View style={styles.workoutDataBlock}>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Талия</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.waist ? `${profile?.waist} см` : " -"}
              </Text>
            </View>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Бёдра</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.hip ? `${profile?.hip} см` : " -"}
              </Text>
            </View>
            {fullStat && (
              <>
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>Одно бедро</Text>
                  <Text style={styles.workoutDataParam}>43 см</Text>
                </View>
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>Бицепс</Text>
                  <Text style={styles.workoutDataParam}>32 см</Text>
                </View>
              </>
            )}
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Шея</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.neck ? `${profile?.neck} см` : " -"}
              </Text>
            </View>
          </View>
          <View style={styles.workoutDataBlock}>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Вес</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.weight ? `${profile?.weight} кг` : " -"}
              </Text>
            </View>
            <View style={styles.workoutDataRow}>
              <Text style={styles.workoutDataTitle}>Рост</Text>
              <Text style={styles.workoutDataParam}>
                {profile?.height ? `${profile?.height} см` : " -"}
              </Text>
            </View>
            {fullStat && (
              <>
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>BFP</Text>
                  <Text style={styles.workoutDataParam}>29%</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.workoutDataTitle}>Калорий</Text>
                  <Text style={styles.workoutDataParam}>2200 ккал</Text>
                </View>
              </>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default UserStat;
