import React from "react";
import { Text, View } from "react-native";

import IconQuizFirst from "../../assets/images/svg/IconQuizFirst";
import IconQuizSecond from "../../assets/images/svg/IconQuizSecond";
import Button from "../../components/Button";
import { colors } from "../../assets/theme";

import styles from "./styles";
import { WorkoutTestType } from "../../types/workout";

const QuizScreen = ({
  quiz,
  quizPage,
  handleAnswerPress,
}: {
  quiz?: WorkoutTestType;
  quizPage: number;
  handleAnswerPress(balls: number): void;
}) => {
  return (
    <View style={styles.quizContent}>
      <Text style={styles.quizDescr}>
        Нагрузка сегодняшней тренировки будет скорректирована в зависимости от
        вашего настроения и самочувствия
      </Text>
      <View style={styles.quizCountBlock}>
        <View style={styles.quizCount}>
          {quizPage === 1 ? <IconQuizFirst /> : <IconQuizSecond />}
        </View>
        <Text style={styles.quizTitle}>{quiz?.title || ""}</Text>
      </View>
      <View style={styles.buttonBlock}>
        {!!quiz?.answers &&
          quiz?.answers?.map((answer) => {
            return (
              <Button
                key={`${answer.balls}${answer.title}`}
                title={answer.title}
                isSecondaryButton
                // style={{ backgroundColor: colors.grayDark }}
                // textStyle={{ color: colors.black }}
                onPress={() => handleAnswerPress(answer.balls)}
              />
            );
          })}
      </View>
    </View>
  );
};

export default QuizScreen;
