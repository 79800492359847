import { FlatList, SafeAreaView } from "react-native";
import React, { useState, useCallback } from "react";
import MessageBody from "../MessageBody";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMessagesRequest,
  createMessageSuccess,
  saveViewesRequest
} from "../../../../store/actions/chat";
import {
  selectMessageIsCreate,
  selectMessagesLoad,
} from "../../../../store/selectors/chat";
import { selectProfile } from "../../../../store/selectors/profile";
import MessageInput from "../MessageInput";
import Loader from "../../../../components/Loader";
import { useFocusEffect } from "@react-navigation/native";

type Props = {
  listType?: "string";
};
const SupportMessageList = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectMessagesLoad);
  const isMessageCreate = useSelector(selectMessageIsCreate);
  const profile = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      dispatch(
        loadMessagesRequest({
          data: {
            type: "2",
            limit: 3000,
          },
        })
      );
      setIsLoading(false)
      return () => {setIsLoading(true)}
    }, [])
  );


  useFocusEffect(
    useCallback(() => {
      let timer1 = setInterval(() => {
        dispatch(
          loadMessagesRequest({
            data: {
              type: "2",
              limit: 3000,
            },
          })
        );
      }, 2000);
      return () => {
        clearInterval(timer1);
      };
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (messages) {
        const newArr = [...messages];
        const reciewedMessages = newArr.filter(
          (item) => item.user_id !== profile?.id
        );
        let arrUnviewed = reciewedMessages.filter((item) => !item.is_view);
        if (arrUnviewed.length > 0 ) {
          const ids = Array.from(arrUnviewed, ({ id }) => id).join(",");
          dispatch(saveViewesRequest({ message_ids: ids }));
        }
      }
    }, [messages])
  );

  useFocusEffect(
    useCallback(() => {
      dispatch(
        loadMessagesRequest({
          data: {
            type: "2",
            limit: 3000,
          },
        })
      );
      dispatch(createMessageSuccess(false));
    }, [isMessageCreate])
  );

  if (isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent:'center' }}>
        <Loader />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <FlatList
        inverted={true}
        data={messages}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <MessageBody
            messageText={item.message}
            messageId={item.id}
            messageUserId={item.user_id}
            messageDate={item.created_at}
            messageAttachments={item.attachments}
          />
        )}
      />
      <MessageInput userSelectId={profile?.id} listType="2" />
    </SafeAreaView>
  );
};

export default SupportMessageList;
