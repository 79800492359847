import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={27}
      height={19}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M0.5 1.44998C0.5 2.19556 1.10442 2.79998 1.85 2.79998H25.25C25.9956 2.79998 26.6 2.19556 26.6 1.44998C26.6 0.704391 25.9956 0.0999756 25.25 0.0999756H1.85C1.10442 0.0999756 0.5 0.704391 0.5 1.44998Z" fill="#000" />
      <Path d="M1.85 10.9C1.10442 10.9 0.5 10.2956 0.5 9.54998C0.5 8.80439 1.10442 8.19998 1.85 8.19998H25.25C25.9956 8.19998 26.6 8.80439 26.6 9.54998C26.6 10.2956 25.9956 10.9 25.25 10.9H1.85Z" fill="#000" />
      <Path d="M1.85 19C1.10442 19 0.5 18.3956 0.5 17.65C0.5 16.9044 1.10442 16.3 1.85 16.3H25.25C25.9956 16.3 26.6 16.9044 26.6 17.65C26.6 18.3956 25.9956 19 25.25 19H1.85Z" fill="#000" />
    </Svg>
  )
}

export default SvgComponent
