import React from "react";
import { NavigationContainerRef, StackActions } from "@react-navigation/native";

export const navigationRef = React.createRef<NavigationContainerRef<any>>();

export const navigate = (name: string, params?: object) => {
  navigationRef.current?.navigate(name, params);
};

export const goBack = () => {
  navigationRef?.current?.goBack();
};

export const getState = () => navigationRef.current?.getState();

export const replace = (name: string, params?: object) =>
  navigationRef.current?.dispatch(StackActions.replace(name, params));
