import { StyleSheet, Platform } from "react-native";
import { getStatusBarHeight } from "react-native-status-bar-height";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    // paddingHorizontal: 20,
    // paddingTop: 40,
    paddingTop: Platform.select({
      ios: getStatusBarHeight(true),
      android: 0,
      web: 2,
    }),
  },
  containerFixed: {
    //@ts-expect-error
    position: "fixed",

    width: "100%",

    zIndex: 10,
  },
  containerBorder: {
    borderBottomColor: "#DDDAE1",
    borderBottomWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 65,
    marginHorizontal: 20,
  },
  title: {
    fontSize: fontSizes.big,
    lineHeight: fontSizes.big,
    fontFamily: "bold",
    color: colors.black,
  },
  skip: {
    position: "absolute",
    top: 5,
    right: -10,
    height: 50,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  arrowContainer: {},
  arrow: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  arrowText: {
    marginLeft: 11,
    fontSize: fontSizes.small,
    fontFamily: "semibold",
    color: colors.purple,
  },
});

export default styles;
