import React from "react";
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  ViewStyle,
  TextStyle,
  StyleProp,
  TouchableOpacityProps,
  View,
  ActivityIndicator,
} from "react-native";
import { SvgProps } from "react-native-svg";

import Pressable from "../Pressable";
import styles from "./styles";

type Props = TouchableOpacityProps & {
  title: string;
  textStyle?: StyleProp<TextStyle>;
  whiteButton?: boolean;
  Icon?(props: SvgProps): JSX.Element;
  iconStyle?: StyleProp<ViewStyle>;
  isLoading?: boolean;
  disabled?: boolean;
  isSecondaryButton?: boolean;
};

const Button = ({
  title,
  textStyle,
  style,
  disabled = false,
  whiteButton = false,
  Icon = undefined,
  iconStyle,
  isLoading = false,
  isSecondaryButton = false,
  ...props
}: Props) => {
  const containerStyle = StyleSheet.compose<ViewStyle>(
    [styles.button],
    [style, isSecondaryButton && styles.buttonSecondary]
  );
  const textStyleComposed = StyleSheet.compose<TextStyle>(textStyle, [
    // disabled && styles.disabledText,
    isSecondaryButton && styles.titleSecondary,
  ]);
  const titleStyle = [styles.title, textStyleComposed];

  const iconStyles = [styles.icon, iconStyle];

  return (
    <View>
      {whiteButton ? (
        <TouchableOpacity
          activeOpacity={0.85}
          style={styles.whiteBtn}
          disabled={disabled}
          {...props}
        >
          <Text style={styles.whiteBtnText} numberOfLines={1}>
            {title}
          </Text>
          {Icon && (
            <View style={iconStyles}>
              <Icon />
            </View>
          )}
        </TouchableOpacity>
      ) : (
        <Pressable
          activeOpacity={0.85}
          style={containerStyle}
          disabled={disabled}
          disabledStyles={
            isSecondaryButton
              ? styles.buttonDisabledSecondary
              : styles.buttonDisabled
          }
          pressedStyles={
            isSecondaryButton
              ? styles.buttonPressedSecondary
              : styles.buttonPressed
          }
          {...props}
        >
          {isLoading ? (
            <ActivityIndicator color={"#1C0638"} />
          ) : (
            <Text style={titleStyle} numberOfLines={1}>
              {title}
            </Text>
          )}
        </Pressable>
      )}
    </View>
  );
};

export default Button;
