import { handleActions } from "redux-actions";

import * as actions from "../actions/chat";
import { MessageBodyType, MessageCreateResponse } from "../../types/chat";

type ChatState = typeof initialState;

const initialState = {
  supportMessages: null as null | MessageBodyType[],
  commonMessages: null as null | MessageBodyType[],
  isLoading: false,
  error: "",
  CreateError: "",
  isCreateSucces: false,
  unviewedCount: null as null | {common: number; all: number; support: number},
  unviewedLoadError: "",
  createMessageResponse: null as null | MessageCreateResponse,
  openThreadParentId: null as null | number,
  viewsSave: false
};

const chatReducer = handleActions<ChatState, any>(
  {
    [actions.loadMessagesRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadMessagesSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMessagesSuccess>
    ) => ({
      ...state,
      isLoading: false,
      supportMessages: [...payload],
    }),
    [actions.loadMessagesCommonSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMessagesCommonSuccess>
    ) => ({
      ...state,
      isLoading: false,
      commonMessages: [...payload],
    }),
    [actions.loadMessagesFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMessagesFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),

    [actions.createMessageRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      isCreateSucces: false,
      error: "",
    }),
    [actions.createMessageSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.createMessageSuccess>
    ) => ({
      ...state,
      isLoading: false,
      isCreateSucces: payload,
    }),
    [actions.createMessageFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.createMessageFailure>
    ) => ({
      ...state,
      isLoading: false,
      isCreateSucces: false,
      isCreateError: payload,
    }),
    [actions.createMessageSuccessResponse.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.createMessageSuccessResponse>
    ) => ({
      ...state,
      isLoading: false,
      createMessageResponse: payload,
    }),

    [actions.loadUnviewedRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.loadUnviewedSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUnviewedSuccess>
    ) => ({
      ...state,
      isLoading: false,
      unviewedCount: payload,
    }),
    [actions.loadUnviewedError.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUnviewedError>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    [actions.saveUnviewedSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.saveUnviewedSuccess>
    ) => ({
      ...state,
      viewsSave: payload,
    }),
    [actions.openThread.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.openThread>
    ) => ({
      ...state,
      openThreadParentId: payload
    }),
  },
  initialState
);

export default chatReducer;
