import { handleActions } from 'redux-actions';

import * as actions from '../actions/workout';

type ExercisesState = typeof initialState;

const initialState = {
  data: null as null | ExercisesType,
  isLoading: false,
  error: '',
};

const exercisesReducer = handleActions<ExercisesState, any>(
  {
    [actions.exercisesRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: '',
    }),
    [actions.exercisesSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.exercisesSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.exercisesFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.exercisesFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default exercisesReducer;
