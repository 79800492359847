import {
  all,
  call,
  put,
  SagaReturnType,
  takeLatest,
} from "redux-saga/effects";

import * as chatApi from "../../api/chat";
import * as chatActions from "../actions/chat";
import { StatusCodes } from "../../types/api";

type LoadMessagesResponse = SagaReturnType<typeof chatApi.loadMessages>;

function* loadMessages({
  payload,
}: ReturnType<typeof chatActions.loadMessagesRequest>) {
  try {
    const response: LoadMessagesResponse = yield call(
      chatApi.loadMessages,
      payload.data
    );

    if (response.status === StatusCodes.success) {
      if (payload.data.type === "1") {
        yield put(chatActions.loadMessagesCommonSuccess(response.data));
      } else {
        yield put(chatActions.loadMessagesSuccess(response.data));
      }
    } else {
      yield put(
        chatActions.loadMessagesFailure("Не удалось получить сообщения")
      );
    }
  } catch (err) {
    yield put(chatActions.loadMessagesFailure("Не удалось получить сообщения"));
  }
}

type CreateMessageResponse = SagaReturnType<typeof chatApi.createMessage>;
type CreateAttachResponse = SagaReturnType<typeof chatApi.createAttachment>;

function* createMessage({
  payload,
}: ReturnType<typeof chatActions.createMessageRequest>) {
  try {
    const response: CreateMessageResponse = yield call(
      chatApi.createMessage,
      payload.data
    );
    if (payload.isMediaAttaching) {
      if (payload.file.length > 1) {
        let arrOfAttachResponse: any[] = [];
        for (let i = 0; i < payload.file.length; i++) {
          arrOfAttachResponse.push(payload.file[i]);
          yield call(chatApi.createAttachment, {
            id: response.data.id.toString(),
            file: payload.file[i],
          });
        }
      } else {
        const attachResponse: CreateAttachResponse = yield call(
          chatApi.createAttachment,
          { id: response.data.id.toString(), file: payload.file[0] }
        );

      }
    }

    if (response.status === StatusCodes.success) {
      yield put(chatActions.createMessageSuccess(true));
      yield put(chatActions.createMessageSuccessResponse(response.data));
    } else {
      yield put(
        chatActions.createMessageFailure("Не удалось отправить сообщение")
      );
    }
  } catch (err) {
    yield put(
      chatActions.createMessageFailure("Не удалось отправить сообщение")
    );
  }
}

type DeleteMessageResponse = SagaReturnType<typeof chatApi.deleteMessage>;
function* deleteMessage({
  payload,
}: ReturnType<typeof chatActions.deleteMessageRequest>) {
  try {
    const response: DeleteMessageResponse = yield call(
      chatApi.deleteMessage,
      payload.id
    );
    if (response.status === StatusCodes.success) {
      yield put(chatActions.createMessageSuccess(true));
    } else {
      yield put(
        chatActions.createMessageFailure("Не удалось удалить сообщение")
      );
    }
  } catch (err) {
    yield put(chatActions.createMessageFailure("Не удалось удалить сообщение"));
  }
}

type DeleteAttachmentResponse = SagaReturnType<typeof chatApi.deleteAttachment>;
function* deleteAttachment({
  payload,
}: ReturnType<typeof chatActions.deleteAttachmentRequest>) {
  try {
    const response: DeleteAttachmentResponse = yield call(
      chatApi.deleteAttachment,
      payload.id
    );
    if (response.status === StatusCodes.success) {
      yield put(chatActions.createMessageSuccess(true));
    } else {
      yield put(
        chatActions.createMessageFailure("Не удалось удалить сообщение")
      );
    }
  } catch (err) {
    yield put(chatActions.createMessageFailure("Не удалось удалить сообщение"));
  }
}

type LoadViewsCountResponse = SagaReturnType<typeof chatApi.loadUnviewedCount>;
function* loadUnviewed({
  payload,
}: ReturnType<typeof chatActions.loadUnviewedRequest>) {
  try {
    const commonResponse: LoadViewsCountResponse = yield call(
      chatApi.loadUnviewedCount,
      {type: "1"}
    );
    const supportResponse: LoadViewsCountResponse = yield call(
      chatApi.loadUnviewedCount,
      {type: "2"}
    );
    const allResponse: LoadViewsCountResponse = yield call(
      chatApi.loadUnviewedCount,
      {type: null}
    );
    const response = {
      common: commonResponse.data,
      support: supportResponse.data,
      all: allResponse.data
    }
    if (allResponse.status === StatusCodes.success) {
      yield put(chatActions.loadUnviewedSuccess(response));
    } else {
      yield put(
        chatActions.loadUnviewedError(
          "Не удалось получить количество непросмотренных сообщений"
        )
      );
    }
  } catch (err) {
    yield put(
      chatActions.loadUnviewedError(
        "Не удалось получить количество непросмотренных сообщений"
      )
    );
  }
}

type SaveViewsResponse = SagaReturnType<typeof chatApi.saveViews>;

function* saveViews({
  payload,
}: ReturnType<typeof chatActions.saveViewesRequest>) {
  try {
    const response: SaveViewsResponse = yield call(chatApi.saveViews, payload);
    if (response.status === StatusCodes.success) {

      yield put(chatActions.saveUnviewedSuccess(response.data));
    } else {
      console.log("error of saving views");
    }
  } catch (err) {
    console.log("error of saving views");
  }
};

export default function* () {
  yield all([takeLatest(chatActions.loadMessagesRequest, loadMessages)]);
  yield all([takeLatest(chatActions.createMessageRequest, createMessage)]);
  yield all([takeLatest(chatActions.deleteMessageRequest, deleteMessage)]);
  yield all([takeLatest(chatActions.deleteAttachmentRequest, deleteAttachment)]);
  yield all([takeLatest(chatActions.loadUnviewedRequest, loadUnviewed)]);
  yield all([takeLatest(chatActions.saveViewesRequest, saveViews)]);
}
