import api from './instance';
import {
  SignUpFormType,
  SignUpResponseType,
  SignInResponseType,
  SignInFormType,
  PasswordRecoveryFormType,
} from '../types/authentication';
import { API_PATHS } from './paths';

export const signIn = (params: SignInFormType) =>
  api.post<SignInResponseType>(`${API_PATHS.LOGIN}`, params);

export const signUp = (params: SignUpFormType) =>
  api.post<SignUpResponseType>(`${API_PATHS.SIGNUP}`, params);

export const passwordRecovery = (params: PasswordRecoveryFormType) =>
  api.post(`${API_PATHS.PASSWORD_RECOVERY}`, params);
