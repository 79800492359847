import { handleActions } from "redux-actions";
import { GetMetricsResponse } from "../../../types/recomendations";

import * as actions from "../../actions/recomendations";

type State = typeof initialState;

const initialState = {
  data: null as null | GetMetricsResponse[],
  isLoading: false,
  error: "",
};

const userMetricsReducer = handleActions<State, any>(
  {
    [actions.loadUserMetricsRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadUserMetricsSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUserMetricsSuccess>
    ) => ({
      ...state,
      isLoading: false,
      error: "",
      data: payload,
    }),
    [actions.loadUserMetricsFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUserMetricsFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default userMetricsReducer;
