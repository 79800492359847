import { View, Text, SafeAreaView, Image, FlatList, Dimensions } from "react-native";
import React, { useCallback, useRef } from "react";
import { navigate, goBack } from "../../../../navigation/actions";
import { AppParamsList } from "../../../../navigation";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Header from "../../../../components/Header";
import IconClose from "../../../../assets/images/svg/IconClose";
import PressableButton from "../../../../components/Pressable";
import * as chatActions from "../../../../store/actions/chat";
import { useDispatch, useSelector } from "react-redux";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

type NavProps = {
  route: RouteProp<AppParamsList, "FullScreenImage">;
  navigation: StackNavigationProp<AppParamsList, "FullScreenImage">;
};

const FullScreenImage = ({ route, navigation }: NavProps) => {
  const dispath = useDispatch();
  const flatlistRef = useRef<FlatList>(null);

  useFocusEffect( 
    useCallback(() => {
      flatlistRef.current?.scrollToIndex({
        animated: false,
        index: route.params.startIndex
      })
    }, [])
  );

  const deleteAttachment = (id: number) => {
    dispath(chatActions.deleteAttachmentRequest({ id: id.toString() }));
    goBack();
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "black" }} >
    <FlatList
      ref={flatlistRef}
      data={route.params.allAttachments}
      horizontal={true}
      getItemLayout={(data, index) => (
        { length: windowWidth, offset: windowWidth * index, index }
      )}
      pagingEnabled={true}
      keyExtractor={(item: any) => item.id.toString()}
      renderItem={({ item }: any) => (
        <View style={{width: windowWidth, height: windowHeight}}>
          <View
            style={{
              flexDirection: "row-reverse",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <PressableButton
              style={{
                width: 36,
                height: 36,
                marginBottom: 16,
              }}
              onPress={() => goBack()}
              pressedStyles={{ opacity: 0.4 }}
              hoverStyles={{ opacity: 0.7 }}
            >
              <IconClose fill="white" />
            </PressableButton>
            {route.params.isOwn && (
              <PressableButton
                style={{
                  alignItems: "center",
                  marginLeft: 20,
                  marginTop: 10,
                }}
                onPress={() => deleteAttachment(item.id)}
                pressedStyles={{ opacity: 0.4 }}
                hoverStyles={{ opacity: 0.7 }}
              >
                <Text style={{ color: "white", fontSize: 16 }}>Удалить</Text>
              </PressableButton>
            )}
          </View>
          <Image
            source={{ uri: item.file }}
            style={{ flex: 1, resizeMode: "contain" }}
          />
          </View>
        
      )}
    />
    </SafeAreaView>
  );
};

export default FullScreenImage;
