import React, { useEffect } from "react";
import { AppParamsList } from "../../navigation";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";

import Calendar from "./Calendar";

import { selectActiveTrainingData } from "../../store/selectors/workout";
import { colors } from "../../assets/theme";
import moment from "moment";
import { loadMainCalendarRequest } from "../../store/actions/workout";

type NavProps = {
  route: RouteProp<AppParamsList, "Main">;
  navigation: StackNavigationProp<AppParamsList, "Main">;
};

const CalendarContainer = ({ navigation, route }: NavProps) => {
  const today = moment.utc().locale("ru").format("D MMMM");
  const dispatch = useDispatch();
  const { programDayId } = useSelector(selectActiveTrainingData);

  const getDatesArray = () => {
    let startDate = moment();
    let endDate = moment();
    startDate = startDate.subtract(30, "days");
    endDate = endDate.add(30, "days");
    const datesArray = [];
    dispatch(
      loadMainCalendarRequest({
        params: {
          start_date: startDate.format("YYYY-MM-DD"),
          end_date: endDate.format("YYYY-MM-DD"),
        },
      })
    );
  };

  useEffect(() => {
    getDatesArray();
  }, []);

  return (
    <>
      <Header
        showBackButton={false}
        title="Календарь"
        screenName="Calendar"
        isContainerFixedTop
        showBorderOnFixed={false}
        backgroundColor={colors.white}
        // showHeader={false}
      />
      <View
        style={[
          {
            flex: 1,
            paddingTop: 50,
          },
        ]}
      >
        <Calendar />
      </View>
    </>
  );
};

export default CalendarContainer;
