import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useSelector } from "react-redux";
import Main from "../screens/Main";
import WorkoutList from "../screens/WorkoutList";
import Workout from "../screens/Workout";
import Exercise from "../screens/Exercise";
import CompleteWorkout from "../screens/CompleteWorkout";
import Planning from "../screens/Planning";
import Chat from "../screens/Chat";
import Nutrition from "../screens/Nutrition";
import Articles from "../screens/Articles";
import Profile from "../screens/Profile";
import Menu from "../screens/Menu";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp";
import ForgotPassword from "../screens/ForgotPassword";
import NewPassword from "../screens/NewPassword";
import Subscription from "../screens/Subscription";
import ProfileInfo from "../screens/Profile/ProfileInfo";
import ProfileStats from "../screens/Profile/ProfileStats";
import ExerciseGoal from "../screens/Profile/ExerciseGoal";
import FullScreenImage from "../screens/Chat/components/FullScreenImage";
import Quiz from "../screens/Quiz";
import { colors } from "../assets/theme";
import {
  selectIsAuthenticated,
  selectIsLoadingApplication,
} from "../store/selectors/application";
import {
  Exercises,
  ProgramType,
  UpdateExerciseResponseSuccess,
  WorkoutProgramType,
} from "../types/workout";
import WorkoutDay from "../screens/WorkoutDay";
import PlanningProgram from "../screens/PlanningProgram";
import PasswordInstruction from "../screens/PasswordInstruction";
import RecomendationsScreen from "../screens/Recomendations";
import CalendarContainer from "../screens/Calendar";
import CommonChat from "../screens/Chat/CommonChat";
import SupportChat from "../screens/Chat/SupportChat";

export type AppParamsList = {
  Main: undefined;
  WorkoutList: undefined;
  Workout: { id: number; location_id: number };
  // Workout: { data: WorkoutProgramType };
  // WorkoutDay: { data: ProgramType };
  // WorkoutDay: { day: number; dayId: number; initDate: string; status: number };
  WorkoutDay: { programDayId: number };
  Exercise: {
    // exercise: Exercises;
    // repeat: number;
    // weight: number;
    // pre: number;
    id: number;
    // is_additional: number;
    // exercise: string;
    // title: string;
    // dayId: number;
    // isLastExercise: boolean;
    programDayId: number;
    additional: number;
  };
  CompleteWorkout: { data: UpdateExerciseResponseSuccess };
  Planning: { id: number; initDate: string; status: number };
  PlanningProgram: { title: string; id: number };
  Chat: { initChat?: "converse" | "private" };
  Nutrition: undefined;
  Articles: undefined;
  Profile: undefined;
  Menu: { screenName?: string };
  SignIn: undefined;
  SignUp: undefined;
  ForgotPassword: undefined;
  NewPassword: undefined;
  Subscription: undefined;
  ProfileInfo: undefined;
  ProfileStats: undefined;
  ExerciseGoal: undefined;
  Quiz: { programDayId: number };
  PasswordInstruction: { email: string | undefined };
  FullScreenImage: {
    imageUri: string;
    isOwn: boolean;
    attacmentId: number;
    allAttachments: any;
    startIndex: number;
  };
  Recomendations: undefined;
  Calendar: undefined;
};

const Stack = createStackNavigator<AppParamsList>();

const AppNavigation = () => {
  const isAuth = useSelector(selectIsAuthenticated);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: colors.white, flex: 1 },
        animationEnabled: false,
      }}
    >
      {isAuth ? (
        <>
          <Stack.Group>
            <Stack.Screen
              name="Main"
              component={Main}
              options={{ title: "Главная" }}
            />
            <Stack.Screen
              name="WorkoutList"
              component={WorkoutList}
              options={{ title: "Список тренировок" }}
            />
            <Stack.Screen
              name="Workout"
              component={Workout}
              options={{ title: "Подробности тренировки" }}
            />
            <Stack.Screen
              name="WorkoutDay"
              component={WorkoutDay}
              options={{ title: "Подробности тренировки" }}
            />
            <Stack.Screen
              name="Exercise"
              component={Exercise}
              options={{ title: "Упражнение" }}
            />
            <Stack.Screen
              name="CompleteWorkout"
              component={CompleteWorkout}
              options={{ title: "Тренировка закончена" }}
            />
            <Stack.Screen
              name="Planning"
              component={Planning}
              options={{ title: "Перепланировать тренировку" }}
            />
            <Stack.Screen
              name="PlanningProgram"
              component={PlanningProgram}
              options={{ title: "Запланировать тренировку" }}
            />
            <Stack.Screen
              name="Chat"
              component={Chat}
              options={{ title: "Chat" }}
            />
            <Stack.Screen
              name="Profile"
              component={Profile}
              options={{ title: "Профиль" }}
            />
            <Stack.Screen
              name="Nutrition"
              component={Nutrition}
              options={{ title: "Nutrition" }}
            />
            <Stack.Screen
              name="Articles"
              component={Articles}
              options={{ title: "Articles" }}
            />

            <Stack.Screen
              name="Subscription"
              component={Subscription}
              options={{ title: "Подписка" }}
            />

            <Stack.Screen
              name="ProfileInfo"
              component={ProfileInfo}
              options={{ title: "Личные данные" }}
            />

            <Stack.Screen
              name="ProfileStats"
              component={ProfileStats}
              options={{ title: "Измерения" }}
            />

            <Stack.Screen
              name="ExerciseGoal"
              component={ExerciseGoal}
              options={{ title: "Цель и уровень активности" }}
            />

            <Stack.Screen
              name="Quiz"
              component={Quiz}
              options={{ title: "Опрос перед началом тренировки" }}
            />

            <Stack.Screen
              name="Menu"
              component={Menu}
              options={{ title: "Меню" }}
            />
            <Stack.Screen
              name="PasswordInstruction"
              component={PasswordInstruction}
              options={{ title: "Готово" }}
            />
            <Stack.Screen
              name="FullScreenImage"
              component={FullScreenImage}
              options={{ title: "FullScreenImage" }}
            />
            <Stack.Screen
              name="Recomendations"
              component={RecomendationsScreen}
              options={{ title: "Recomendations" }}
            />
            <Stack.Screen
              name="Calendar"
              component={CalendarContainer}
              options={{ title: "Calendar" }}
            />
          </Stack.Group>
          {/* <Stack.Group
            screenOptions={{
              presentation: "transparentModal",
              animationEnabled: false,
            }}
          >
          </Stack.Group> */}
        </>
      ) : (
        <Stack.Group>
          <Stack.Screen
            name="SignIn"
            component={SignIn}
            options={{ title: "Вход" }}
          />
          <Stack.Screen
            name="SignUp"
            component={SignUp}
            options={{ title: "Регистрация" }}
          />
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPassword}
            options={{ title: "Восстановить пароль" }}
          />
          <Stack.Screen
            name="NewPassword"
            component={NewPassword}
            options={{ title: "Новый пароль" }}
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};

export default AppNavigation;
