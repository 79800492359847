import { View, Text } from "react-native";
import React from "react";
import CommonMessageList from "./components/MessageList/CommonMessageList";
import ThreadMessageList from "./components/MessageList/ThreadMessageList";
import { useSelector } from "react-redux";
import { selectOpenThreadId } from "../../store/selectors/chat";

const CommonChat = () => {
  const threadParentId = useSelector(selectOpenThreadId);

  return (
    <View style={{ flex: 1 }}>
      {threadParentId ? <ThreadMessageList /> : <CommonMessageList />}
    </View>
  );
};

export default CommonChat;
