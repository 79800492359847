import { fork } from "redux-saga/effects";

import initialization from "./initialization";
import authSagas from "./authentication";
import profileSagas from "./profile";
import workoutSagas from "./workout";
import checkoutSagas from "./checkout";
import chatSagas from "./chat";
import recomendationsSagas from "./recomendations";

export default function* () {
  yield fork(initialization);
  yield fork(authSagas);
  yield fork(profileSagas);
  yield fork(workoutSagas);
  yield fork(checkoutSagas);
  yield fork(chatSagas);
  yield fork(recomendationsSagas);
}
