import React, { useEffect, useState } from "react";
import styles from "./styles";
import {
  TextInput,
  View,
  Text,
  TouchableOpacity,
  TextInputProps,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { SvgProps } from "react-native-svg";

export type FieldProps = TextInputProps & {
  // placeholder: string;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  IconRight?(props: SvgProps): JSX.Element;
  iconRightHandler?(): void;
  iconRightStyles?: StyleProp<ViewStyle>;
  // IconLeft?(props: SvgProps): JSX.Element;
  // iconLeftHandler?(): void;
  error?: string;
  multiline?: boolean;
};

const Field = React.forwardRef<TextInput, FieldProps>(
  (
    {
      // placeholder,
      containerStyle,
      inputStyle,
      value,
      IconRight,
      iconRightHandler,
      iconRightStyles,
      // IconLeft,
      // iconLeftHandler,
      error,
      ...props
    },
    ref
  ) => {
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
    useEffect(() => {
      if (value) {
        setIsPlaceholderVisible(false);
      } else {
        setIsPlaceholderVisible(true);
      }
    }, [value]);

    const container = [styles.inputContainer, containerStyle];
    const style = [styles.input, inputStyle];

    return (
      <View>
        <View style={[...container, error ? styles.errorBorder : {}]}>
          {/* {isPlaceholderVisible && (
            <View pointerEvents="box-none" style={styles.center}>
              <Text style={styles.placeholder}>{placeholder}</Text>
            </View>
          )} */}
          <TextInput
            ref={ref}
            style={style}
            autoCorrect={false}
            autoComplete="off"
            underlineColorAndroid="transparent"
            value={value || ""}
            {...props}
          />
          {IconRight && (
            <TouchableOpacity onPress={iconRightHandler} style={styles.icon}>
              <IconRight style={iconRightStyles} />
            </TouchableOpacity>
          )}
        </View>
        {error && (
          <View style={styles.errorContainer}>
            <Text style={styles.error}>{error}</Text>
          </View>
        )}
      </View>
    );
  }
);

export default Field;
