import React, { forwardRef, useEffect } from "react";
import { View } from "react-native";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconCalendar from "../../assets/images/svg/IconCalendar";
import { colors, fontSizes } from "../../assets/theme";
import ru from "date-fns/locale/ru";
import moment from "moment";
registerLocale("ru", ru);

type Props = {
  value: string | null;
  onChange(date: string): void;
  fieldStyle: any;
};

export const ProfileCalendar = ({ value, onChange, fieldStyle }: Props) => {
  // console.log(moment.utc(newDate, "YYYY-MM-DD").toDate());
  const pickerField = {
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
    padding: 12,
    backgroundColor: colors.white,
    borderRadius: 6,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.grayLight_1,
    textAlign: "left",
    fontSize: fontSizes.small_2,
    fontFamily: "regular",
    color: colors.black,
    cursor: "pointer",
  };

  const ExampleCustomInput = forwardRef<any, any>(({ value, onClick }, ref) => (
    <View style={{ position: "relative" }}>
      <button
        className="example-custom-input"
        style={{ ...pickerField, ...fieldStyle }}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
      <IconCalendar
        pointerEvents="none"
        style={{
          position: "absolute",
          top: 12,
          right: 10,

          width: 25,
        }}
      />
    </View>
  ));

  useEffect(() => {
    onChange(moment.utc().format("YYYY-MM-DD"));
  }, []);

  return (
    <View>
      <DatePicker
        locale={"ru"}
        selected={value ? moment.utc(value, "YYYY-MM-DD").toDate() : new Date()}
        onChange={(date: Date) => {
          onChange(moment.utc(date).format("YYYY-MM-DD"));
        }}
        customInput={<ExampleCustomInput />}
        maxDate={new Date()}
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd.MM.yyyy"
      />
    </View>
  );
};

export default ProfileCalendar;
