import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectExercisesByProgramDayIdData } from "../store/selectors/workout";
import { Approach, ProgramType } from "../types/workout";

function useFindFirstUpcomingExercise() {
  const program = useSelector(selectExercisesByProgramDayIdData);

  const [exercise, setExercise] = useState<Approach | null>(null);
  const [exerciseIndex, setExercieIndex] = useState<number | null>(null);
  // const [exerciseUncompleteTitle, setExerciseUncompleteTitle] = useState("");
  // const [missedWorkoutsNumber, setMissedWorkoutsNumber] = useState<
  //   number | null
  // >(null);

  const findFirstUpcomingExercise = (program: ProgramType) => {
    let approachIndex: null | number = null;
    const exerciseUncompletedIndex = program?.exercises?.findIndex(
      (exercise) => {
        const approachUncompleteIndex = exercise.approachs.findIndex(
          (approach) => !approach?.user_result
        );

        if (approachUncompleteIndex < 0) return false;

        approachIndex = approachUncompleteIndex;
        return true;
        // const isEvery = exercprogramise?.approachs?.every((approach) => {
        //   return !approach?.user_result;
        // });

        // return isEvery;
      }
    );

    if (exerciseUncompletedIndex < 0) {
      setExercise(null);
    } else if (Number.isInteger(approachIndex)) {
      //@ts-expect-error
      setExercieIndex(exerciseUncompletedIndex + approachIndex);
      setExercise(
        //@ts-expect-error
        program?.exercises[exerciseUncompletedIndex].approachs[approachIndex]
      );
      // setExerciseUncompleteTitle(
      //   program?.exercises[exerciseUncompletedIndex]?.exercise
      // );
    }

    const sumMissedWorkouts = program.exercises.reduce(
      (prevValue, currValue) => {
        const isNotCompletedCount = currValue?.approachs?.filter(
          (approach) => !approach?.user_result
        ).length;

        return isNotCompletedCount
          ? isNotCompletedCount + prevValue
          : prevValue;
      },
      0
    );

    // setMissedWorkoutsNumber(sumMissedWorkouts);
  };

  useEffect(() => {
    if (program) {
      findFirstUpcomingExercise(program);
    }
  }, [program]);

  return {
    exercise,
    exerciseIndex,
    // exerciseUncompleteTitle,
    // missedWorkoutsNumber,
  };
}

export default useFindFirstUpcomingExercise;
