import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  select,
  takeLatest,
  takeLeading,
  takeEvery,
} from "redux-saga/effects";
import { Alert } from "react-native";
import axios from "axios";

import * as workoutApi from "../../api/workout";
import * as workoutActions from "../actions/workout";
import { StatusCodes } from "../../types/api";
import * as profileActions from "../actions/profile";
import * as profileApi from "../../api/profile";
import { navigate } from "../../navigation/actions";

type LoadWorkoutsResponse = SagaReturnType<typeof workoutApi.loadWorkouts>;

function* loadWorkouts() {
  try {
    const response: LoadWorkoutsResponse = yield call(workoutApi.loadWorkouts);

    if (response.status === StatusCodes.success) {
      const workoutData = Object.values(response.data);
      yield put(workoutActions.loadWorkoutsSuccess(workoutData));
    } else {
      yield put(workoutActions.loadWorkoutsFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.loadWorkoutsFailure());
  }
}

type LoadLocationsResponse = SagaReturnType<typeof workoutApi.loadLocations>;

function* loadLocations() {
  try {
    const response: LoadLocationsResponse = yield call(
      workoutApi.loadLocations
    );

    if (response.status === StatusCodes.success) {
      // const locationsData = Object.values(response.data);
      const locationsData = [];
      for (const [key, value] of Object.entries(response.data)) {
        console.log(`${key}: ${value}`);
        const data = { id: Number(key), name: value };
        locationsData.push(data);
      }
      yield put(workoutActions.loadLocationsSuccess(locationsData));
    } else {
      yield put(workoutActions.loadLocationsFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.loadLocationsFailure());
  }
}

type WorkoutProgramResponse = SagaReturnType<typeof workoutApi.workoutProgram>;

function* workoutProgram({
  payload,
}: ReturnType<typeof workoutActions.workoutsProgramRequest>) {
  try {
    const response: WorkoutProgramResponse = yield call(
      workoutApi.workoutProgram,
      payload
    );

    if (response.status === StatusCodes.success) {
      yield put(workoutActions.workoutsProgramSuccess(response.data));
    } else {
      yield put(workoutActions.workoutsProgramFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.workoutsProgramFailure());
  }
}

type ProgramResponse = SagaReturnType<typeof workoutApi.loadProgram>;

function* loadProgram({
  payload,
}: ReturnType<typeof workoutActions.loadProgramRequest>) {
  try {
    const response: ProgramResponse = yield call(
      workoutApi.loadProgram,
      payload
    );

    if (response.status === StatusCodes.success) {
      yield put(
        workoutActions.loadProgramSuccess(Object.values(response.data))
      );
    } else {
      yield put(workoutActions.loadProgramFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.loadProgramFailure());
  }
}

type ExercisesResponse = SagaReturnType<typeof workoutApi.loadExercisesByDay>;

function* exercises({
  payload,
}: ReturnType<typeof workoutActions.exercisesRequest>) {
  try {
    const response: ExercisesResponse = yield call(
      workoutApi.loadExercisesByDay,
      payload
    );
    if (response.status === StatusCodes.success) {
      yield put(workoutActions.exercisesSuccess(response.data));
    } else {
      yield put(workoutActions.exercisesFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.exercisesFailure());
  }
}

type SaveProgramResponse = SagaReturnType<typeof workoutApi.saveProgram>;

function* saveProgram({
  payload,
}: ReturnType<typeof workoutActions.saveProgramRequest>) {
  try {
    const response: SaveProgramResponse = yield call(
      workoutApi.saveProgram,
      payload.data
    );
    if (response.status === StatusCodes.success) {
      yield put(
        profileActions.loadProfileSuccess({
          program_id: payload.data.location_id,
        })
      );
      // yield delay(500);
      navigate("Main");
    } else {
      alert("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
    }
    // console.log(response);
  } catch (err) {
    // console.log("err", err);
    alert("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
  }
}

type DeleteProgramResponse = SagaReturnType<typeof workoutApi.deleteProgram>;

function* deleteProgramThenSave({
  payload,
}: ReturnType<typeof workoutActions.deleteProgramRequest>) {
  try {
    const response: DeleteProgramResponse = yield call(
      workoutApi.deleteProgram
    );

    if (response.status === StatusCodes.success) {
      yield put(workoutActions.saveProgramRequest({ data: payload.data }));
    } else {
      alert("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === StatusCodes.unprocessable) {
        yield put(workoutActions.saveProgramRequest({ data: payload.data }));
      }
    } else {
      // console.log("err", err);
      alert("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
    }
  }
}

type SaveApproachResponse = SagaReturnType<typeof workoutApi.saveApproach>;

function* saveApproach({
  payload,
}: ReturnType<typeof workoutActions.saveApproachRequest>) {
  try {
    // yield delay(2000);
    // navigate("WorkoutDay", { day: payload.day });
    const response: SaveApproachResponse = yield call(
      workoutApi.saveApproach,
      payload.data
    );
    if (response.status === StatusCodes.success) {
      payload.stopLoading();
      // console.log("payload.func", payload.func);
      if (payload.func) {
        payload.func();
      }
    } else {
      payload.stopLoading();
      payload.errorFunc("Ошибка. Не удалось сохранить результат");
    }
  } catch (err) {
    // console.log("err", err);
    payload.stopLoading();

    if (axios.isAxiosError(err)) {
      const response = err.response?.data as { message: string };
      if (response) {
        const errorMessages =
          response?.message || "Ошибка. Не удалось сохранить результат";
        payload.errorFunc(errorMessages);
        // yield put(
        //   workoutActions.loadExercisesByProgramDayIdFailure(errorMessages)
        // );
      }
    } else {
      payload.errorFunc("Ошибка. Не удалось сохранить результат");
    }
  }
}

type loadMainCalendarResponse = SagaReturnType<
  typeof workoutApi.loadMainCalendar
>;

function* loadMainCalendar({
  payload,
}: ReturnType<typeof workoutActions.loadMainCalendarRequest>) {
  try {
    const response: loadMainCalendarResponse = yield call(
      workoutApi.loadMainCalendar,
      payload.params
    );

    if (response.status === StatusCodes.success) {
      yield put(workoutActions.loadMainCalendarSuccess(response.data));

      const calendarItem = response.data?.find(
        (calendar) => calendar.status === 2
      );

      yield put(
        workoutActions.saveStartedTrainingInfo({
          programDayId: calendarItem ? calendarItem?.program_day_id : null,
        })
      );
      // DEPRECEATED (27.06.22 changed)
      // yield put(
      //   workoutActions.loadMainCalendarSuccess(
      //     Object.values(response.data).flat()
      //   )
      // );
    } else {
      yield put(
        workoutActions.loadMainCalendarFailure("Ошибка. Не удалось загрузить.")
      );
    }
  } catch (err) {
    // console.log("err", err);
    yield put(
      workoutActions.loadMainCalendarFailure("Ошибка. Не удалось загрузить.")
    );
  }
}

type loadWorkoutTestResponse = SagaReturnType<
  typeof workoutApi.loadWorkoutTest
>;

function* loadWorkoutTest() {
  try {
    const response: loadWorkoutTestResponse = yield call(
      workoutApi.loadWorkoutTest
    );

    if (response.status === StatusCodes.success) {
      const workoutTestData = Object.values(response.data);
      yield put(workoutActions.loadWorkoutTestSuccess(workoutTestData));
    } else {
      yield put(workoutActions.loadWorkoutTestFailure());
    }
  } catch (err) {
    // console.log("err", err);
    yield put(workoutActions.loadWorkoutTestFailure());
  }
}

type saveTestResponse = SagaReturnType<typeof workoutApi.saveTest>;

function* saveTest({
  payload,
}: ReturnType<typeof workoutActions.saveTestRequest>) {
  try {
    const response: saveTestResponse = yield call(
      workoutApi.saveTest,
      payload.data
    );
    if (response.status === StatusCodes.success) {
      // yield delay(500);
    } else {
      alert(
        "Ошибка. Не удалось сохранить результаты теста, попробуйте ещё раз"
      );
    }
    // console.log(response);
  } catch (err) {
    // console.log("err", err);
    alert("Ошибка. Не удалось сохранить результаты теста, попробуйте ещё раз");
  }
}

type loadExercisesByDayResponse = SagaReturnType<
  typeof workoutApi.loadExercisesByProgramByDay
>;

function* loadExercisesByProgramDayId({
  payload,
}: ReturnType<typeof workoutActions.loadExercisesByProgramDayIdRequest>) {
  try {
    const response: loadExercisesByDayResponse = yield call(
      workoutApi.loadProgramByDay,
      payload.id
    );

    console.log("response", response);

    if (response.status === StatusCodes.success) {
      yield put(
        workoutActions.loadExercisesByProgramDayIdSuccess(response.data)
      );
    } else {
      yield put(
        workoutActions.loadExercisesByProgramDayIdFailure(
          "Ошибка. Не получилось загрузить"
        )
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const response = err.response?.data as { message: string };
      if (response) {
        const errorMessages =
          response?.message || "Ошибка. Не получилось загрузить";
        yield put(
          workoutActions.loadExercisesByProgramDayIdFailure(errorMessages)
        );
      }
    } else {
      yield put(
        workoutActions.loadExercisesByProgramDayIdFailure(
          "Ошибка. Не получилось загрузить"
        )
      );
    }
  }
}
let map = new Map();

type loadCalendarTextResponse = SagaReturnType<
  typeof workoutApi.loadCalendarText
>;
function* loadCalendarText({
  payload,
}: ReturnType<typeof workoutActions.loadCalendarTextRequest>) {
  try {
    const response: loadCalendarTextResponse = yield call(
      workoutApi.loadCalendarText,
      payload
    );
    console.log("response", response);
    if (response.status === StatusCodes.success) {
      yield put(workoutActions.loadCalendarTextSucces(response.data));
    } else {
      yield put(
        workoutActions.loadCalendarTextFailure(
          "Ошибка. Не получилось загрузить"
        )
      );
    }
  } catch (err) {
    yield put(
      workoutActions.loadCalendarTextFailure("Ошибка. Не получилось загрузить")
    );
  }
}

export default function* () {
  yield all([takeLatest(workoutActions.loadWorkoutsRequest, loadWorkouts)]);
  yield all([takeLatest(workoutActions.loadLocationsRequest, loadLocations)]);
  yield all([
    takeLatest(workoutActions.workoutsProgramRequest, workoutProgram),
  ]);
  yield all([takeLatest(workoutActions.loadProgramRequest, loadProgram)]);
  // yield all([takeLatest(workoutActions.programByDayRequest, programByDay)]);
  yield all([takeLatest(workoutActions.exercisesRequest, exercises)]);
  yield all([takeLatest(workoutActions.saveProgramRequest, saveProgram)]);
  yield all([
    takeLatest(workoutActions.deleteProgramRequest, deleteProgramThenSave),
  ]);
  yield all([takeLatest(workoutActions.saveApproachRequest, saveApproach)]);
  yield all([
    takeLatest(workoutActions.loadMainCalendarRequest, loadMainCalendar),
  ]);
  yield all([
    takeLatest(workoutActions.loadWorkoutTestRequest, loadWorkoutTest),
  ]);
  yield all([takeLatest(workoutActions.saveTestRequest, saveTest)]);
  yield all([
    takeLatest(
      workoutActions.loadExercisesByProgramDayIdRequest,
      loadExercisesByProgramDayId
    ),
  ]);
  yield all([
    takeEvery(workoutActions.loadCalendarTextRequest, loadCalendarText),
  ]);
}
