import React, { useLayoutEffect } from "react";
import { Pressable, ScrollView, Text, TextInput, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { StackNavigationProp } from "@react-navigation/stack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Field from "../../components/Field";
import Container from "../../components/Container";
import Label from "../../components/Label";
import { colors } from "../../assets/theme";
import {
  signUpFailure,
  signUpRequest,
} from "../../store/actions/authentication";
import { SignUpFormType } from "../../types/authentication";
import {
  selectSignUpError,
  selectSignUpIsLoading,
} from "../../store/selectors/authentication";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "SignUp">;
  navigation: StackNavigationProp<AppParamsList, "SignUp">;
};

const signUpSchema = yup
  .object({
    first_name: yup
      .string()
      .required("Имя не может быть пустым.")
      .trim()
      .min(2, "Имя должно состоять минимум из 2 букв")
      .max(32)
      .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректное имя."),
    last_name: yup
      .string()
      .trim()
      .min(2, "Фамилия должна состоять минимум из 2 букв")
      .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректно фамилию."),
    email: yup
      .string()
      .required("Электронная почта не может быть пустой.")
      .trim()
      .email("Электронная почта не является действительным."),
    password: yup
      .string()
      .required("Пароль не может быть пустым.")
      .trim()
      .min(6, "Пароль должен быть минимум 6 знаков"),
    // password2: yup
    //   .string()
    //   .required("Пароль не может быть пустым.")
    //   .trim()
    //   .min(6)
    //   .oneOf([yup.ref("password"), null], "Пароли должны совпадать"),
  })
  .required();

const SignUpContainer = ({ navigation }: NavProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectSignUpIsLoading);
  const error = useSelector(selectSignUpError);

  const nameRef = React.useRef<TextInput>(null);
  const emailRef = React.useRef<TextInput>(null);
  const passwordRef = React.useRef<TextInput>(null);

  const { control, handleSubmit } = useForm<
    Omit<SignUpFormType, "password2" | "agree">
  >({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      // password2: "",
    },
    mode: "all",
  });

  useLayoutEffect(() => {
    dispatch(signUpFailure(null));
  }, []);

  const onSubmit = (data: Omit<SignUpFormType, "password2" | "agree">) => {
    dispatch(
      signUpRequest({
        data: {
          ...data,
          password2: data.password,
          agree: true,
        },
      })
    );
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header showBackButton={true} showMenu={false} />
        <View style={styles.contentBlock}>
          <Text style={styles.title}>Регистрация</Text>
          <View style={styles.fieldWrap}>
            <Label>Имя</Label>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <Field
                    ref={nameRef}
                    placeholder=""
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                    blurOnSubmit={false}
                    maxLength={50}
                    error={error?.message}
                  />
                );
              }}
              name="first_name"
            />
          </View>
          <View style={styles.fieldWrap}>
            <Label>Фамилия</Label>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <Field
                    ref={nameRef}
                    placeholder=""
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                    blurOnSubmit={false}
                    maxLength={50}
                    error={error?.message}
                  />
                );
              }}
              name="last_name"
            />
          </View>
          <View style={styles.fieldWrap}>
            <Label>Электронный адрес</Label>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <Field
                    ref={emailRef}
                    placeholder=""
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                    blurOnSubmit={false}
                    maxLength={50}
                    error={error?.message}
                  />
                );
              }}
              name="email"
            />
          </View>
          <View style={styles.fieldWrap}>
            <Label>Пароль</Label>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <Field
                    ref={passwordRef}
                    placeholder=""
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                    blurOnSubmit={false}
                    maxLength={50}
                    error={error?.message}
                    secureTextEntry
                  />
                );
              }}
              name="password"
            />
          </View>
          {/* <View style={styles.fieldWrap}>
            <Label>Повторно введите пароль</Label>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <Field
                    ref={passwordRef}
                    placeholder=""
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                    blurOnSubmit={false}
                    maxLength={50}
                    error={error?.message}
                  />
                );
              }}
              name="password2"
            />
          </View> */}
        </View>
      </ScrollView>
      <View style={styles.buttonBlock}>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{error}</Text>
        </View>
        <Button
          title="Готово"
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <Text style={styles.conditions}>
          Нажимая кнопку, вы даёте согласие{"\n"}на обработку ваших персональных
          данных
        </Text>
      </View>
    </Container>
  );
};

export default SignUpContainer;
