import { Store } from "../reducers";
import {
  ProgramByDayType,
  ProgramType,
  WorkoutProgramType,
  WorkoutsType,
  MainCalendarItem,
  WorkoutTestType,
  LocationsType,
  CalendarText,
} from "../../types/workout";

export const selectWorkoutData = (state: Store): WorkoutsType[] | null =>
  state.workout.workout.data;

export const selectLocationsData = (state: Store): WorkoutsType[] | null =>
  state.workout.locations.data;

export const selectWorkoutProgram = (
  state: Store
): null | WorkoutProgramType[] => state.workoutProgram.data;

export const selectProgram = (state: Store): ProgramType[] | null =>
  state.program.data;
export const selectProgramIsLoading = (state: Store): boolean =>
  state.program.isLoading;
export const selectProgramError = (state: Store): string => state.program.error;

export const selectExercises = (state: Store): any | null =>
  state.exercises.data;

export const selectMainCalendarData = (
  state: Store
): MainCalendarItem[] | null => state.workout.calendar.data;
export const selectMainCalendarLoading = (state: Store): boolean =>
  state.workout.calendar.isLoading;
export const selectMainCalendarError = (state: Store): string =>
  state.workout.calendar.error;

export const selectActiveTrainingData = (
  state: Store
): { programDayId: null | number } => state.workout.currentTraining.data;

export const selectWorkoutTestData = (state: Store): WorkoutTestType[] | null =>
  state.workout.test.data;
export const selectWorkoutTestIsLoading = (state: Store): boolean =>
  state.workout.test.isLoading;

// данные о программе дня
export const selectExercisesByProgramDayIdData = (
  state: Store
): ProgramType | null => state.workout.exerciseDay.data;
export const selectExercisesByProgramDayIdIsLoading = (state: Store): boolean =>
  state.workout.exerciseDay.isLoading;
export const selectExercisesByProgramDayIdError = (state: Store): string =>
  state.workout.exerciseDay.error;

export const selectCalendarText = (state: Store): null | CalendarText[] =>
  state.workout.calendarText.data;
