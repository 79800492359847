import React, { useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import { useSelector } from "react-redux";
import IconRight from "../../assets/images/svg/IconRight";
import { getState, navigate } from "../../navigation/actions";
import { selectProfile } from "../../store/selectors/profile";
import { selectActiveTrainingData } from "../../store/selectors/workout";
import PressableButton from "../Pressable";

type Props = {};

const Status = (props: Props) => {
  const profile = useSelector(selectProfile);
  const { programDayId } = useSelector(selectActiveTrainingData);
  const navState = getState();
  let showBanner = true;

  if (navState) {
    const route = navState?.routes[navState?.routes?.length - 1];

    if (
      route?.name === "WorkoutDay" ||
      route?.name === "Planning" ||
      route?.name === "Exercise"
    ) {
      showBanner = false;
    } else {
      showBanner = true;
    }
  }
  // const workoutState = navState?.routes.find(
  //   (route) => route.name === "WorkoutDay"
  // );

  if (programDayId && showBanner) {
    return (
      <PressableButton
        hoverStyles={styles.opacity}
        pressedStyles={styles.opacity}
        style={styles.container}
        onPress={() => {
          navigate("WorkoutDay", {
            programDayId,
          });
        }}
      >
        <View style={styles.dot} />
        <Text style={styles.text}>Вернуться к тренировке</Text>
        <IconRight color="white" />
      </PressableButton>
    );
  }

  if (
    profile?.subscriptions_days_left &&
    profile?.subscriptions_days_left < 7
  ) {
    return (
      <PressableButton
        hoverStyles={styles.opacity}
        pressedStyles={styles.opacity}
        style={[styles.container, styles.containerGray]}
      >
        <View style={[styles.dot, styles.dotRed]} />
        <Text style={[styles.text, styles.textBlack]}>
          Подписка закончится через 6 дней
        </Text>
        <IconRight color="#5E536B" />
      </PressableButton>
    );
  }

  return null;
};

export default Status;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    height: 44,

    backgroundColor: "#7133BF",
  },
  containerGray: {
    backgroundColor: "#F4F3F5",
  },
  dot: {
    width: 8,
    height: 8,

    borderRadius: 4,
    backgroundColor: "#9CF444",
  },
  dotRed: {
    backgroundColor: "#E43A59",
  },
  text: {
    marginLeft: 8,
    marginRight: 18,

    fontFamily: "bold",
    fontSize: 14,
    lineHeight: 20,
    color: "#F4F3F5",
  },
  textBlack: {
    color: "#5E536B",
  },
  opacity: {
    opacity: 0.7,
  },
});
