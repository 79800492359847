import { handleActions } from "redux-actions";

import * as actions from "../actions/authentication";
import { SignUpErrorsType } from "../../types/authentication";

type State = typeof initialState;

const initialState = {
  data: null,
  isLoading: false,
  error: null as string | null,
};

const signUpReducer = handleActions<State, any>(
  {
    [actions.signUpRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.signUpSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.signUpSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.signUpFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.signUpFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default signUpReducer;
