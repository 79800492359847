import React, { useCallback, useEffect, useState } from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import CommonChat from "../../CommonChat";
import SupportChat from "../../SupportChat";
import { colors, fontSizes } from "../../../../assets/theme";
import { Text, View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUnviewedCount,
  selectViewsSave,
} from "../../../../store/selectors/chat";
import { useFocusEffect } from "@react-navigation/native";
import { loadUnviewedRequest } from "../../../../store/actions/chat";

type Props = {
  initChat?: "converse" | "private";
};

const ChatTabBar = ({ initChat }: Props) => {
  const Tab = createMaterialTopTabNavigator();

  const unviewedCount = useSelector(selectUnviewedCount);
  const isViewsSave = useSelector(selectViewsSave);
  const dispatch = useDispatch();
  const [chatType, setChatType] = useState("");

  useFocusEffect(
    useCallback(() => {
      dispatch(loadUnviewedRequest());

      setChatType(initChat ? initChat : "converse");
    }, [isViewsSave, initChat])
  );

  useEffect(() => {
    setChatType(initChat ? initChat : "converse");
  }, [initChat]);

  return (
    <Tab.Navigator
      initialRouteName={initChat}
      screenOptions={{
        tabBarStyle: { width: "92%", alignSelf: "center" },
        tabBarLabelStyle: {
          fontSize: fontSizes.small,
          fontFamily: "bold",
          textTransform: "capitalize",
        },
        tabBarActiveTintColor: colors.purple,
        tabBarInactiveTintColor: colors.grayText,
        tabBarIndicatorStyle: {
          borderBottomColor: colors.purple,
          borderBottomWidth: 2,
        },
        tabBarItemStyle: { flexDirection: "row-reverse" },
      }}
    >
      <Tab.Screen
        name="converse"
        component={CommonChat}
        options={{
          tabBarLabel: "Общий",
          tabBarIcon: () => (
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                backgroundColor: "#F4F3F5",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3,
                marginLeft: 4,
              }}
            >
              <Text
                style={{
                  fontSize: fontSizes.small,
                  fontFamily: "bold",
                  color: colors.grayText,
                }}
              >
                {unviewedCount?.common}
              </Text>
            </View>
          ),
          tabBarShowIcon: !!unviewedCount?.common,
        }}
      />
      <Tab.Screen
        name="private"
        component={SupportChat}
        options={{
          tabBarLabel: "Поддержка",
          tabBarIcon: () => (
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                backgroundColor: "#F4F3F5",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3,
                marginLeft: 4,
              }}
            >
              <Text
                style={{
                  fontSize: fontSizes.small,
                  fontFamily: "bold",
                  color: colors.grayText,
                }}
              >
                {unviewedCount?.support}
              </Text>
            </View>
          ),
          tabBarShowIcon: !!unviewedCount?.support,
        }}
      />
    </Tab.Navigator>
  );
};

export default ChatTabBar;
