import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "../Dropdown";
import {
  selectProfile,
  selectUserActivity,
} from "../../store/selectors/profile";
import {
  changeProfileRequest,
  loadUserActivityRequest,
} from "../../store/actions/profile";
import { UserActivityType } from "../../types/profile";

type DropdownWithActivity = {
  // selected: UserActivityType | null;
  // setSelected: React.Dispatch<React.SetStateAction<UserActivityType | null>>;
  onChange(id: number): void;
  value: number | null;
};

const DropdownWithActivity = ({
  // selected,
  // setSelected,
  onChange,
  value,
}: DropdownWithActivity) => {
  const profile = useSelector(selectProfile);
  const activityData = useSelector(selectUserActivity);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<UserActivityType | null>(null);

  useEffect(() => {
    let defaultSelected = false;
    if (activityData && profile?.activity_level_id && !defaultSelected) {
      setSelected(activityData.find((workout) => workout.id === value) || null);
      defaultSelected = true;
    }
  }, [activityData, profile]);

  useEffect(() => {
    if (!selected) return;
    if (profile?.activity_level_id === selected.id) return;

    onChange(selected.id);
    // dispatch(
    //   changeProfileRequest({
    //     data: {
    //       activity_level_id: selected.id,
    //     },
    //   })
    // );
  }, [selected]);

  return (
    <Dropdown
      data={activityData}
      name="Выберите активность"
      selected={selected}
      onSelect={(item) => setSelected(item)}
    />
  );
};

export default DropdownWithActivity;
