import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import styles from "./styles";

const RadioButton = ({
  selected,
  text,
  onPress,
}: {
  selected: boolean;
  text?: string;
  onPress: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <View style={styles.outside}>
        {selected ? <View style={styles.inside} /> : null}
      </View>
      {text ? (
        <Text
          style={[
            styles.title,
            { fontFamily: selected ? "semibold" : "regular" },
          ]}
        >
          {text}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

export default RadioButton;
