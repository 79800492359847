import React from "react";
import {
  KeyboardAvoidingView,
  Platform,
  StatusBar,
  Text,
  View,
} from "react-native";
import { useFonts } from "expo-font";
import { Provider } from "react-redux";
import { NavigationContainer } from "@react-navigation/native";

import { navigationRef } from "./src/navigation/actions";
import Navigation from "./src/navigation";
import { store } from "./src/store";

const linking = {
  prefixes: ["brazgovsky://"],
  config: {
    screens: {
      SignIn: "authorization",
      SignUp: "registration",
      ForgotPassword: "forgotPassword",
      Main: "main",
      Menu: "menu",
      Profile: "profile",
      Subscription: "subscription",
      Planning: "planning",
      WorkoutList: {
        path: "workouts",
      },
      Workout: {
        path: "workout",
      },
      WorkoutDay: {
        path: "workoutDay/:programDayId",
        parse: {
          programDayId: (id: number) => `${id}`,
        },
      },
      Exercise: {
        path: "exercise",
        // parse: {
        //   exercise: (exercise) => `${exercise}`,
        // }
      },
      Quiz: {
        path: "quiz",
      },
      ProfileInfo: "info",
      ExerciseGoal: "goal",
      ProfileStats: "stats",
    },
  },
};

const App = () => {
  const [loaded] = useFonts({
    regular: require("./src/assets/fonts/SFProDisplay-Regular.ttf"),
    light: require("./src/assets/fonts/SFProDisplay-Light.ttf"),
    bold: require("./src/assets/fonts/SFProDisplay-Bold.ttf"),
    semibold: require("./src/assets/fonts/SFProDisplay-Semibold.ttf"),
    black: require("./src/assets/fonts/SFProDisplay-Black.ttf"),
  });

  if (!loaded) {
    return null;
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
    >
      <Provider store={store}>
        <StatusBar backgroundColor={"transparent"} />
        <NavigationContainer
          linking={linking}
          fallback={<Text>Загрузка...</Text>}
          ref={navigationRef}
        >
          <Navigation />
        </NavigationContainer>
        {/*<GlobalLoader />*/}
      </Provider>
    </KeyboardAvoidingView>
  );
};

export default App;
