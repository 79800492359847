import { handleActions } from "redux-actions";

import * as actions from "../actions/profile";
import { UserActivityType } from "../../types/profile";
import { capitalize } from "../../helpers/word";

type State = typeof initialState;

const initialState = {
  data: null as null | UserActivityType[],
  isLoading: false,
  error: "",
};

const activityReducer = handleActions<State, any>(
  {
    [actions.loadUserActivityRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadUserActivitySuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUserActivitySuccess>
    ) => {
      // Увеличиваем первую букву в предложении
      const activity = payload.map((activity) => {
        return {
          name: activity?.name ? capitalize(activity.name) : "",
          id: activity?.id,
        };
      });

      return {
        ...state,
        isLoading: false,
        data: activity,
      };
    },
    [actions.loadUserActivityFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadUserActivityFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default activityReducer;
