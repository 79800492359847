import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteProp, useFocusEffect } from "@react-navigation/native";

import { selectExercisesByProgramDayIdData } from "../store/selectors/workout";

type Props = {
  id: number;
  additional: number;
};

const useExerciseInfo = ({ id, additional }: Props) => {
  const program = useSelector(selectExercisesByProgramDayIdData);

  const [repeat, setRepeat] = useState<number | null>(0);
  const [weight, setWeight] = useState<number | null>(0);
  const [pre, setPre] = useState<number | null>(0);
  const [isAdditional, setIsAdditional] = useState(0);
  const [title, setTitle] = useState("");
  const [exerciseName, setExerciseName] = useState("");
  const [calendarId, setCalendarId] = useState(0);

  const [isLastExercise, setIsLastExercise] = useState(false);

  const [isSearchTraining, setIsSearchTraining] = useState(false);

  const [nextId, setNextId] = useState<number | null>(null);
  const [nextAdditional, setNextAdditional] = useState<number | null>(null);

  useFocusEffect( useCallback(() => {
    if (program) {
      setIsSearchTraining(true);
      // Поиск группы тренировок в которой находится id передаваемый в хук
      const exerciseItem = program.exercises.find(
        (exercise, index, exercisesArr) => {
          // Поиск тренировки по id передаваемому в хук
          const approachItemIndex = exercise?.approachs.findIndex(
            (approach) =>
              approach?.id === Number(id) &&
              approach?.is_additional === Number(additional)
          );

          // Если не найдена тренировка, то переходим к следующему элементу
          if (approachItemIndex < 0) return false;

          const approachItem = exercise?.approachs[approachItemIndex];

          // Если тренировка не последняя то сохраняем id следующей тренировки
          if (
            approachItemIndex <
            exercise?.approachs.length - 1
            // approachItem?.id !==
            //   exercise?.approachs[exercise.approachs.length - 1]?.id ||
            // (approachItem?.id ===
            //   exercise?.approachs[exercise.approachs.length - 1]?.id &&
            //   approachItem?.is_additional !==
            //     exercise?.approachs[exercise.approachs.length - 1]
            //       ?.is_additional)
          ) {
            setNextId(exercise.approachs[approachItemIndex + 1].id);
            setNextAdditional(
              exercise.approachs[approachItemIndex + 1].is_additional
            );
          } else if (index !== exercisesArr?.length - 1) {
            setNextId(exercisesArr[index + 1].approachs[0].id);
            setNextAdditional(
              exercisesArr[index + 1].approachs[0].is_additional
            );
          }

          // Если это последняя тренировка в exercises то сохраняем об этом информацию
          if (
            // approachItem?.id ===
            //   exercise?.approachs[exercise.approachs.length - 1]?.id &&
            approachItemIndex === exercise?.approachs.length - 1 &&
            index === exercisesArr?.length - 1
          ) {
            setIsLastExercise(true);
          }

          setRepeat(approachItem?.view_data.repeat);
          setWeight(approachItem?.view_data.weight);
          setPre(approachItem?.view_data.pre);
          setIsAdditional(approachItem?.is_additional);

          // Если тренировка найдена то выходим из цикла и сохраняем её в exerciseItem
          return true;
        }
      );

      if (exerciseItem) {
        setExerciseName(exerciseItem.exercise);
      }

      setTitle(program.program.name);
      setCalendarId(program.calendar.id);

      setIsSearchTraining(false);
    }
  }, [program, id]))

  return {
    repeat,
    weight,
    pre,
    isAdditional,
    title,
    exerciseName,
    calendarId,
    isLastExercise,
    nextId,
    nextAdditional,
    isSearchTraining,
  };
};

export default useExerciseInfo;
