import { createAction } from 'redux-actions';
import {User} from "../../types/authentication";

const scope = 'APPLICATION';

const setAuthentication = createAction<{ token: string; user: User }>(
  `${scope}/SET_AUTHENTICATION`
);

const resetStore = createAction(`${scope}/RESET_STORE`);
const clearAuthentication = createAction(`${scope}/CLEAR_AUTHENTICATION`);

const startInitialization = createAction(`${scope}/START_INITIALIZATION`);
const stopInitialization = createAction(`${scope}/STOP_INITIALIZATION`);

const showGlobalLoader = createAction(`${scope}/SHOW_GLOBAL_LOADER`);
const hideGlobalLoader = createAction(`${scope}/HIDE_GLOBAL_LOADER`);

export {
  setAuthentication,
  resetStore,
  clearAuthentication,
  startInitialization,
  stopInitialization,
  showGlobalLoader,
  hideGlobalLoader,
};
