import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 20,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
  completeTextBlock: {
    paddingTop: 57,
    paddingBottom: 60,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.grayLight_1,
  },
  completeTextBlockTitle: {
    marginBottom: 16,

    textAlign: "center",
    fontSize: fontSizes.middle,
    lineHeight: 26,
    fontFamily: "semibold",
    color: colors.black,
    letterSpacing: 0.7,
  },
  completeTextBlockDescr: {
    textAlign: "center",
    fontSize: fontSizes.small,
    lineHeight: fontSizes.middle,
    fontFamily: "regular",
    color: colors.grayDark_1,
    letterSpacing: 0.7,
  },
  completeRepeatBlock: {
    marginTop: 20,
  },
  selectRepeatWorkout: {
    marginTop: 12,
    zIndex: 123,
  },
  field: {
    width: "100%",
    marginBottom: 20,
  },
  completeDoneBlock: {
    marginTop: 60,
    marginBottom: 34,
    alignItems: "center",
  },
  checkIcon: {
    justifyContent: "center",
    alignItems: "center",
    width: 120,
    height: 120,
    marginBottom: 32,
    borderRadius: 60,
    backgroundColor: colors.purple,
  },
  buttonBlock: {
    width: "100%",
    paddingTop: 11,
    paddingBottom: 10,
    paddingHorizontal: 16,
  },
});

export default styles;
