import { View, Text } from "react-native";
import React from "react";
import RadioButton from "../../components/RadioButton";
import { ProfileType } from "../../types/profile";
import { useDispatch } from "react-redux";
import { changeProfileRequest } from "../../store/actions/profile";
import styles from "./styles";

const RecomendationsType = ({ recType }: { recType: number | undefined }) => {
  const dispatch = useDispatch();

  const onPress = (type: number) => {
    dispatch(
      changeProfileRequest({
        data: {
          recomendation_type: type,
        },
      })
    );
  };
  return (
    <View style={styles.recomendationSlide}>
      <Text style={styles.title}>Питание</Text>
      <View style={styles.recomendationData}>
        <RadioButton
          onPress={() => (recType === 1 ? null : onPress(1))}
          text="Буду считать калории"
          selected={recType === 1}
        />
        <RadioButton
          onPress={() => (recType === 2 ? null : onPress(2))}
          text="Получать рекомендации по пищевому поведению"
          selected={recType === 2}
        />
      </View>
    </View>
  );
};

export default RecomendationsType;
