import { FlatList, SafeAreaView, Text, View } from "react-native";
import React, { useState, useCallback } from "react";
import MessageBody from "../MessageBody";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMessagesRequest,
  createMessageSuccess,
  openThread,
  saveViewesRequest,
} from "../../../../store/actions/chat";
import {
  selectMessageIsCreate,
  selectMessagesCommonLoad,
  selectOpenThreadId,
} from "../../../../store/selectors/chat";
import { selectProfile } from "../../../../store/selectors/profile";
import MessageInput from "../MessageInput";
import { useFocusEffect } from "@react-navigation/native";
import Loader from "../../../../components/Loader";
import { MessageBodyType } from "../../../../types/chat";
import Header from "../../../../components/Header";

type Props = {
  listType?: "string";
};
const ThreadMessageList = () => {
  const dispatch = useDispatch();
  const commonMessages = useSelector(selectMessagesCommonLoad);
  const isMessageCreate = useSelector(selectMessageIsCreate);
  const threadParentId = useSelector(selectOpenThreadId);
  const profile = useSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [threadMessagesList, setThreadMessagesList] = useState<
    MessageBodyType[] | null
  >(null);

  useFocusEffect(
    useCallback(() => {
      dispatch(
        loadMessagesRequest({
          data: {
            type: "1",
            limit: 3000,
          },
        })
      );
      setIsLoading(false);
      return () => {
        setIsLoading(true);
      };
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      let timer1 = setInterval(() => {
        dispatch(
          loadMessagesRequest({
            data: {
              type: "1",
              limit: 3000,
            },
          })
        );
      }, 2000);
      return () => {
        clearInterval(timer1);
        dispatch(openThread(null));
      };
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (commonMessages && isMessageCreate) {
        dispatch(
          loadMessagesRequest({
            data: {
              type: "1",
              limit: 3000,
            },
          })
        );
        dispatch(createMessageSuccess(false));
      }
    }, [isMessageCreate])
  );

  useFocusEffect(
    useCallback(() => {
      if (commonMessages) {
        const newArr = commonMessages
          ?.filter((item) => item.id === threadParentId)
          .concat(
            commonMessages?.filter((item) => item.id === threadParentId)[0]
              .childs
          )
          .reverse();
        const reciewedMessages = newArr.filter(
          (item) => item.user_id !== profile?.id
        );

        let arrUnviewed = reciewedMessages.filter((item) => !item.is_view);
        if (arrUnviewed.length > 0) {
          const ids = Array.from(arrUnviewed, ({ id }) => id).join(",");

          dispatch(saveViewesRequest({ message_ids: ids }));
        }
      }
    }, [commonMessages])
  );

  if (isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent: "center" }}>
        <Loader />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        showMenu={false}
        backButtonText="В общий чат"
        backButtonFunc={() => dispatch(openThread(null))}
        backgroundColor="#FFFFFF"
        hideStatus={true}
        customStyle={{
          height: 44,
          borderBottomColor: "#DDDAE1",
          borderBottomWidth: 1,
          justifyContent: "center",
        }}
      />
      <FlatList
        inverted={true}
        data={commonMessages
          ?.filter((item) => item.id === threadParentId)
          .concat(
            commonMessages?.filter((item) => item.id === threadParentId)[0]
              .childs
          )
          .reverse()}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <MessageBody
            messageText={item.message}
            messageId={item.id}
            messageUserId={item.user_id}
            messageDate={item.created_at}
            messageAttachments={item.attachments}
            isCommon={true}
            userFirstName={item.user.first_name}
            userLastName={item.user.last_name}
          />
        )}
      />
      <MessageInput
        userSelectId={profile?.id}
        listType="1"
        parentId={threadParentId}
      />
    </SafeAreaView>
  );
};

export default ThreadMessageList;
