export const numberOf = (numberof: number, value: string, suffix: string[]) => {
  var keys = [2, 0, 1, 1, 1, 2];
  var mod = numberof % 100;
  var suffix_key = mod > 4 && mod < 20 ? 2 : keys[Math.min(mod % 10, 5)];

  return value + suffix[suffix_key];
};

// делает заглавным первую букву в предложении
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// удаляет теги вида <p></p> из текста, вероятно не будет работать с <img />
export const striptext = (str: string | null | undefined) => {
  if (!str) return null;

  return str.replace(/<(?:.|\n)*?>/gm, "");
};
