import React, { useCallback } from "react";
import { Text, View, FlatList } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useDispatch, useSelector } from "react-redux";

import { AppParamsList } from "../../navigation";

import IconClose from "../../assets/images/svg/IconClose";
import { signOutRequest } from "../../store/actions/authentication";
import Pressable from "../../components/Pressable";

import styles from "./styles";
import { selectUnviewedCount } from "../../store/selectors/chat";
import {
  loadUnviewedRequest,
  loadUnviewedSuccess,
} from "../../store/actions/chat";
import { useFocusEffect } from "@react-navigation/native";
import { selectProfile } from "../../store/selectors/profile";

const menu = [
  {
    name: "Главная",
    link: "Main",
  },
  {
    name: "Программы",
    link: "WorkoutList",
  },
  {
    name: "Чат",
    link: "Chat",
  },
  {
    name: "Профиль",
    link: "Profile",
  },
  {
    name: "Рекомендации по питанию",
    link: "Recomendations",
  },
  {
    name: "Календарь",
    link: "Calendar",
  },
];

type NavProps = {
  route: RouteProp<AppParamsList, "Menu">;
  navigation: StackNavigationProp<AppParamsList, "Menu">;
};

const MenuContainer = ({ navigation, route }: NavProps) => {
  const params = route.params;
  const dispatch = useDispatch();
  const unviewedCount = useSelector(selectUnviewedCount);
  const profile = useSelector(selectProfile);

  // const handlePressBackground = (event: GestureResponderEvent) => {
  //   if (event.target === event.currentTarget) {
  //     navigation.goBack();
  //   }
  // };

  const handlePressButtonClose = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(
        (params?.screenName as keyof AppParamsList) || "Main"
      );
    }
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(loadUnviewedRequest());
    }, [])
  );

  const handleNavigationButtonPress = (value: keyof AppParamsList) => {
    navigation.replace(value);
  };

  const onPressSignOut = () => {
    dispatch(signOutRequest());
  };

  return (
    <View
      style={styles.background}
      pointerEvents="auto"
      // onPress={handlePressBackground}
    >
      <FlatList
        data={menu}
        // style={styles.listContainer}
        ListHeaderComponent={
          <Pressable
            style={styles.closeButton}
            pressedStyles={{ opacity: 0.4 }}
            hoverStyles={{ opacity: 0.7 }}
            onPress={handlePressButtonClose}
          >
            <IconClose />
          </Pressable>
        }
        ListFooterComponent={<View style={{ height: 30 }} />}
        keyExtractor={(item) => item.name}
        renderItem={({ item, index }) => {
          return item.name === "Рекомендации по питанию" &&
            profile?.recomendation_type === 2 ? null : (
            <Pressable
              onPress={() =>
                handleNavigationButtonPress(item.link as keyof AppParamsList)
              }
              style={[
                {
                  justifyContent: "space-between",
                  height: 60,
                  marginBottom: 4,
                  paddingHorizontal: 12,
                  borderRadius: 8,
                  alignItems: "center",
                  flexDirection: "row",
                },
                params.screenName === item.link
                  ? { backgroundColor: "#DDD7E3" }
                  : {},
              ]}
              hoverStyles={{ opacity: 0.7 }}
              pressedStyles={{ opacity: 0.7 }}
            >
              <Text style={styles.navigationButtonText}>{item.name}</Text>
              {item.name === "Чат" && (
                <View style={styles.unviewedCircle}>
                  <Text style={styles.unviewedText}>{unviewedCount?.all}</Text>
                </View>
              )}
            </Pressable>
          );
        }}
      />
      <View style={styles.logoutButtonContainer}>
        <Pressable
          onPress={onPressSignOut}
          style={styles.logoutButton}
          pressedStyles={{ opacity: 0.4 }}
          hoverStyles={{ opacity: 0.7 }}
        >
          <Text style={styles.navigationButtonText}>Выход</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default MenuContainer;
