import { handleActions } from "redux-actions";
import { MetricsType } from "../../../types/recomendations";

import * as actions from "../../actions/recomendations";

type State = typeof initialState;

const initialState = {
  data: null as null | MetricsType[],
  isLoading: false,
  error: "",
};

const metricsListReducer = handleActions<State, any>(
  {
    [actions.loadMetricsListRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadMetricsListSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMetricsListSuccess>
    ) => ({
      ...state,
      isLoading: false,
      error: "",
      data: payload,
    }),
    [actions.loadMetricsListFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMetricsListFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default metricsListReducer;
