import React, { useEffect, useState } from "react";
import { Platform, ScrollView, Text, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AppParamsList } from "../../navigation";
import Header from "../../components/Header";
import Button from "../../components/Button";
import { colors } from "../../assets/theme";
import { navigate } from "../../navigation/actions";
import Container from "../../components/Container";
import { selectProfile } from "../../store/selectors/profile";
import WorkoutDate from "../../components/WorkoutDate";
import Workouts from "../../components/Workouts";
import { SaveProgramType } from "../../types/workout";
import useSaveProgram from "../../hooks/useSaveProgram";

import styles from "./styles";
import { striptext } from "../../helpers/word";
import { loadProgramRequest } from "../../store/actions/workout";
import {
  selectProgram,
  selectProgramError,
  selectProgramIsLoading,
} from "../../store/selectors/workout";
import Loader from "../../components/Loader";

type NavProps = {
  route: RouteProp<AppParamsList, "Workout">;
  navigation: StackNavigationProp<AppParamsList, "Workout">;
};

const Workout = ({ navigation, route }: NavProps) => {
  const dispatch = useDispatch();

  const data = useSelector(selectProgram);
  const isProgramLoading = useSelector(selectProgramIsLoading);
  const programError = useSelector(selectProgramError);
  const profile = useSelector(selectProfile);

  const {
    deleteThenSaveProgram,
    deleteCurrentProgram,
    saveProgram,
    isLoading,
    error,
  } = useSaveProgram("");

  const [planning, setPlanning] = useState(false);

  const { id, location_id } = route?.params;

  const handlePressStart = async () => {
    if (!data) return;
    const requestData: {
      data: SaveProgramType;
    } = {
      data: {
        location_id: location_id,
        program_id: id,
        date_begin: "",
        first_training_id: "",
      },
    };

    if (!data[0]?.program?.date_begin) {
      requestData.data.date_begin = moment.utc().format("YYYY-MM-DD");
    }

    if (data) {
      requestData.data.first_training_id = data[0].id.toString();
    }

    if (profile?.location_id) {
      await deleteThenSaveProgram(requestData.data, "Main");
      return;
    }
    await saveProgram(requestData.data, "Main");
  };

  useEffect(() => {
    dispatch(loadProgramRequest(id));
  }, [id]);

  if (isProgramLoading) {
    return <Loader />;
  }

  return (
    <>
      <View style={{ paddingBottom: 130 }}>
        <Header
          showBackButton={true}
          backButtonFunc={() => navigation.navigate("WorkoutList")}
        />
        <View style={{ paddingHorizontal: 15 }}>
          <View style={styles.workoutBlock}>
            <Text style={styles.workoutName}>
              {!!data && !!data.length ? data[0]?.program?.name : ""}
            </Text>
            <View style={styles.workoutTimeBlock}>
              {!!data && !!data.length && (
                <WorkoutDate
                  id={location_id}
                  date_begin={data[0]?.program?.date_begin}
                />
              )}
            </View>
          </View>
          <View style={styles.workoutDescription}>
            {!!data && !!data.length && (
              <Text style={styles.workoutDescriptionText}>
                {striptext(data[0]?.program?.description)}
              </Text>
            )}
          </View>
          {!!data && !!data.length && (
            <Workouts id={data[0]?.program?.id} type={"list"} />
          )}
        </View>
      </View>
      <View style={styles.buttonBlock}>
        {/* <Button
          isLoading={isLoading}
          disabled={isLoading}
          title="Начать тренировку"
          onPress={handlePressStart}
        /> */}
        {/* {!!data && !!data.length && !data[0]?.program?.date_begin && (
          <Button
            disabled={isLoading}
            title={planning ? "Запланировать" : "Перезапланировать"}
            isSecondaryButton
            // style={{ backgroundColor: colors.grayDark }}
            // textStyle={{ color: colors.black }}
            onPress={() =>
              navigate("PlanningProgram", {
                title: data[0]?.program?.name,
                id: location_id,
              })
            }
          />
        )} */}
      </View>
    </>
  );
};

export default Workout;
