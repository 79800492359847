import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={8}
      height={8}
      fill="none"
      viewBox="0 0 8 8"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M0.774095 0.774092C0.598359 0.949828 0.598359 1.23475 0.774095 1.41049L3.21361 3.85001L0.774095 6.28952C0.598359 6.46526 0.598359 6.75018 0.774095 6.92592C0.949831 7.10166 1.23476 7.10166 1.41049 6.92592L3.85001 4.4864L6.28953 6.92592C6.46526 7.10166 6.75019 7.10166 6.92592 6.92592C7.10166 6.75018 7.10166 6.46526 6.92592 6.28952L4.48641 3.85001L6.92592 1.41049C7.10166 1.23475 7.10166 0.949828 6.92592 0.774092C6.75019 0.598356 6.46526 0.598356 6.28953 0.774092L3.85001 3.21361L1.41049 0.774092C1.23476 0.598356 0.949831 0.598356 0.774095 0.774092Z"
        fill="#5E536B" 
      />
    </Svg>
  )
}

export default SvgComponent