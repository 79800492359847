import React, { useEffect, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  ScrollView,
  View,
  Text,
  FlatList,
  Modal,
  Pressable,
} from "react-native";
import Slider from "@react-native-community/slider";
import { useDispatch, useSelector } from "react-redux";
import { AppParamsList } from "../../navigation";
import Header from "../../components/Header";
import Container from "../../components/Container";
import {
  loadUserMetricsRequest,
  loadDaysRequest,
  loadRecomendationsRequest,
} from "../../store/actions/recomendations";
import {
  selectDaysData,
  selectDaysLoading,
  selectDaysError,
  selectUserMetricsData,
  selectUserMetricsError,
  selectUserMetricsLoading,
  selectRecomendationsLoading,
  selectRecomendationsData,
  selectRecomendationsError,
} from "../../store/selectors/recomendations";

import styles from "./styles";
import { DataItem } from "../../types/recomendations";
import MetricsItem from "./MetricsItem";
import MetricsPopUp from "../../components/MetricsPopUp";
import Loader from "../../components/Loader";
import CaloriesSlider from "./CaloriesSlider";

type NavProps = {
  route: RouteProp<AppParamsList, "Profile">;
  navigation: StackNavigationProp<AppParamsList, "Profile">;
};

const RecomendationsScreen = ({ navigation }: NavProps) => {
  const [data, setData] = useState<DataItem[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [metricDate, setMetricDate] = useState<string>("");
  const [slider, setSlider] = useState<number>(0);
  const dispatch = useDispatch();

  const recomendationsData = useSelector(selectRecomendationsData);
  const recomendationsLoading = useSelector(selectRecomendationsLoading);
  const recomendationsError = useSelector(selectRecomendationsError);

  const usersMetrics = useSelector(selectUserMetricsData);
  const usersLoading = useSelector(selectUserMetricsLoading);
  const usersError = useSelector(selectUserMetricsError);

  const daysData = useSelector(selectDaysData);
  const daysLoading = useSelector(selectDaysLoading);
  const daysError = useSelector(selectDaysError);

  const [headerHeight, setHeaderHeight] = useState(68);

  useEffect(() => {
    if (!daysData) {
      dispatch(loadDaysRequest());
    }
  }, [daysData]);

  useEffect(() => {
    if (!recomendationsData) {
      dispatch(loadRecomendationsRequest());
    }
  }, [recomendationsData]);

  useEffect(() => {
    formData();
  }, [daysData, usersMetrics]);

  const formData = () => {
    const weightCaloriesMetrcis = usersMetrics?.filter(
      (e) => e.metric_id === 2 || e.metric_id === 7
    );
    console.log("daysData", daysData);
    if (daysData) {
      const mainData: DataItem[] = daysData.map((e) => {
        let weight = weightCaloriesMetrcis?.filter(
          (i) => i.date === e && i.metric_id === 2
        )[0];
        let calories = weightCaloriesMetrcis?.filter(
          (i) => i.date === e && i.metric_id === 7
        )[0];
        let obj: DataItem = {
          date: e,
          weight: weight?.value,
          calories: calories?.value,
        };
        return obj;
      });
      const arr = mainData.shift();
      setData(mainData);
      console.log("formData", mainData);
    }
  };

  const openModal = (metricDate: string) => {
    setModalVisible(true);
    setMetricDate(metricDate);
  };

  // if (recomendationsLoading || daysLoading || usersLoading) {
  //   return (
  //     <View style={{ flex: 1, justifyContent: "center" }}>
  //       <Loader />
  //     </View>
  //   );
  // }

  return (
    <View>
      <Container>
        <Header
          onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
          showBackButton={true}
          backgroundColor="white"
          isContainerFixedTop
          screenName="Recomendations"
        />
        <ScrollView
          bounces={false}
          contentContainerStyle={[
            styles.contentContainer,
            { paddingTop: headerHeight },
          ]}
        >
          <View style={styles.contentBlock}>
            <Text style={styles.title}>Рекомендации по питанию</Text>
            {recomendationsData ? (
              recomendationsData.recommendation_weight_loss.length > 0 && (
                <View style={styles.purpleBlock}>
                  <Text style={styles.purpleBlockText}>
                    {recomendationsData.recommendation_weight_loss[0]}
                  </Text>
                  <Text style={styles.purpleBlockText}>
                    {recomendationsData.recommendation_weight_loss[1]}
                  </Text>
                </View>
              )
            ) : (
              <View style={styles.purpleBlock}>
                <Text style={styles.purpleBlockText}>
                  Для получения рекомендаций по питанию необходимо ввести данные
                  за две недели
                </Text>
              </View>
            )}
            {recomendationsData && (
              <View style={styles.whiteBlock}>
                <Text style={styles.whiteBlockText}>
                  Уровень базового обмена:{" "}
                  {recomendationsData.calories_base_metabolism} ккал
                </Text>
                <Text style={styles.whiteBlockText}>
                  Уровень поддержки: {recomendationsData.support_calories} ккал
                </Text>
              </View>
            )}
            <View style={{ marginTop: 30 }}>
              {data.map((item, index) => {
                return (
                  <MetricsItem
                    key={index}
                    isLastIndex={index === data.length - 1}
                    item={item}
                    index={index}
                    onPress={(metricDate: string) => openModal(metricDate)}
                  />
                );
              })}
            </View>
          </View>
          <View style={styles.sliderContainer}>
            <View style={styles.sliderTitleContainer}>
              <Text style={styles.sliderTitle}>Рекомендуемые калории:</Text>
              <Text style={styles.sliderTitleRed}> 1981Kk</Text>
            </View>
            <CaloriesSlider recCal={1981} />
          </View>
        </ScrollView>
      </Container>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <Pressable
          onPress={() => setModalVisible(false)}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Pressable
            onPress={(e) => {
              e.stopPropagation();
            }}
          >
            <MetricsPopUp
              date={metricDate}
              closePopUp={() => setModalVisible(false)}
              isRecomendation={true}
            />
          </Pressable>
        </Pressable>
      </Modal>
    </View>
  );
};

export default RecomendationsScreen;
