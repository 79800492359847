import * as React from "react";
import Svg, { SvgProps, Path, Rect } from "react-native-svg";

type Props = SvgProps & {
  color?: string;
};

function SvgComponent({ ...props }: Props) {
  return (
    <Svg
      width={32}
      height={32}
      fill="none"
      viewBox="0 0 32 32"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        width={32} 
        height={32}
        rx={4} 
        fill="#7133BF"
      />
      <Path
        d="M25 16.4312C25 16.1937 24.9062 15.9844 24.7187 15.8031L18.5031 9.60625C18.4031 9.50625 18.3 9.43437 18.1937 9.39062C18.0937 9.34687 17.9875 9.325 17.875 9.325C17.6437 9.325 17.45 9.40312 17.2937 9.55937C17.1375 9.70937 17.0593 9.9 17.0593 10.1312C17.0593 10.2437 17.0781 10.3531 17.1156 10.4594C17.1531 10.5594 17.2093 10.6469 17.2843 10.7219L19.3843 12.8594L23.1062 16.2531L23.2937 15.7844L20.275 15.5969H8.41558C8.17183 15.5969 7.97183 15.675 7.81558 15.8312C7.66558 15.9875 7.59058 16.1875 7.59058 16.4312C7.59058 16.675 7.66558 16.875 7.81558 17.0312C7.97183 17.1875 8.17183 17.2656 8.41558 17.2656H20.275L23.2937 17.0781L23.1062 16.6187L19.3843 20.0031L17.2843 22.1406C17.2093 22.2156 17.1531 22.3062 17.1156 22.4125C17.0781 22.5125 17.0593 22.6187 17.0593 22.7312C17.0593 22.9625 17.1375 23.1531 17.2937 23.3031C17.45 23.4594 17.6437 23.5375 17.875 23.5375C18.1 23.5375 18.3031 23.45 18.4843 23.275L24.7187 17.0594C24.9062 16.8781 25 16.6687 25 16.4312Z" 
        fill="white"
      />
    </Svg>
  );
}

export default SvgComponent;
