import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 16,
    marginTop: 29,
  },
  title: {
    textAlign: "center",
    fontSize: fontSizes.big,
    fontFamily: "bold",
    color: colors.black,
  },
  conditions: {
    marginTop: 16,

    textAlign: "center",
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
    letterSpacing: 0.7,
  },
  fieldWrap: {
    marginTop: 28,
  },
  completeDoneBlock: {
    marginTop: 60,
    marginBottom: 34,
    alignItems: "center",
  },
  checkIcon: {
    justifyContent: "center",
    alignItems: "center",
    width: 120,
    height: 120,
    marginBottom: 32,
    borderRadius: 60,
    backgroundColor: colors.purple,
  },
  completeTextBlockTitle: {
    marginBottom: 16,
    textAlign: "center",
    fontSize: fontSizes.middle,
    fontFamily: "semibold",
    color: colors.black,
  },
  forgotPasswordBlock: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
  },
  forgotPasswordTitle: {
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  forgotPassword: {
    fontSize: fontSizes.small,
    fontFamily: "semibold",
    color: colors.grayDark_1,
  },
  buttonBlock: {
    width: "100%",
    paddingTop: 11,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  errorContainer: {
    position: "absolute",
    top: -20,
    left: 0,
    right: 0,

    alignItems: "center",

    paddingHorizontal: 16,
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
    textAlign: "center",
  },
});

export default styles;
