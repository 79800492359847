import React, { useEffect, useState } from "react";
import { View, Text, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { StackNavigationProp } from "@react-navigation/stack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Label from "../../components/Label";
import {
  changeProfileRequest,
  loadUserActivityRequest,
} from "../../store/actions/profile";
import {
  selectProfile,
  selectProfileUpdateError,
  selectProfileUpdateIsLoading,
  selectUserActivity,
} from "../../store/selectors/profile";
import { WorkoutsType } from "../../types/workout";
import Header from "../../components/Header";
import Container from "../../components/Container";
import { RouteProp } from "@react-navigation/native";
import { AppParamsList } from "../../navigation";
import Field from "../../components/Field";
import { ChangeProfileFormGoal } from "../../types/profile";
import DropdownWithGoal from "../../components/DropdownWithGoal";
import DropdownWithActivity from "../../components/DropdownWithActivity";
import Button from "../../components/Button";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "ExerciseGoal">;
  navigation: StackNavigationProp<AppParamsList, "ExerciseGoal">;
};

const ExerciseGoal = ({}: NavProps) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const activityData = useSelector(selectUserActivity);
  const updateError = useSelector(selectProfileUpdateError);
  const isUpdating = useSelector(selectProfileUpdateIsLoading);

  const [headerHeight, setHeaderHeight] = useState(68);

  const [selected, setSelected] = useState<WorkoutsType | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState,
  } = useForm<ChangeProfileFormGoal>({
    // resolver: yupResolver(ProfileInfoSchema),
    defaultValues: {
      activity_level_id: profile?.activity_level_id || null,
      target_weight: profile?.target_weight || null,
    },
    mode: "all",
  });

  const onСhangeProfile = (data: ChangeProfileFormGoal) => {
    if (!profile) return;

    const changedData: any = {};

    let key: keyof ChangeProfileFormGoal;
    for (key in data) {
      if (data[key] !== profile[key]) {
        changedData[key] = data[key];
      }
    }

    dispatch(
      changeProfileRequest({
        data: changedData,
      })
    );
  };

  useEffect(() => {
    const defaultValue = {
      activity_level_id: profile?.activity_level_id || null,
      target_weight: profile?.target_weight || null,
    };

    reset(defaultValue);
  }, [profile?.activity_level_id, profile?.target_weight]);

  useEffect(() => {
    if (!activityData) {
      dispatch(loadUserActivityRequest());
    }
  }, [activityData]);

  return (
    <Container>
      <Header
        onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
        showBackButton={true}
        backgroundColor="white"
        isContainerFixedTop
        screenName="ExerciseGoal"
      />
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.contentContainer,
          { paddingTop: headerHeight },
        ]}
        // showsVerticalScrollIndicator={false}
      >
        <View style={styles.contentBlock}>
          <Text style={styles.profileInfoTitle}>Цель и уровень активности</Text>
          <View style={styles.profileInfoBlock}>
            <Text style={styles.profileInfoDescr}>
              От указанных данных будет зависеть нагрузка и частота упражнений
            </Text>
            <View style={styles.selectBlock}>
              <Label>Цель</Label>
              <DropdownWithGoal selected={selected} setSelected={setSelected} />
            </View>
            <View style={styles.formBottomBlock}>
              <View style={{ width: "47%", marginBottom: 0 }}>
                <Label>Уровень активности</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <DropdownWithActivity
                        onChange={onChange}
                        value={value}
                        // selected={activitySelected}
                        // setSelected={setActivitySelected}
                      />
                    );
                  }}
                  name="activity_level_id"
                />
              </View>
              <View
                style={[
                  styles.inputField,
                  { maxWidth: "47%", marginBottom: 0 },
                ]}
              >
                <Label>Желаемый вес, кг</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        // ref={countRef}
                        containerStyle={styles.field}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        // blurOnSubmit={false}
                        maxLength={50}
                      />
                    );
                  }}
                  name="target_weight"
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {formState?.isDirty && (
        // updateError

        <View style={styles.buttonBlock}>
          {!!updateError && (
            <View style={styles.errorContainer}>
              <Text style={styles.error}>{updateError}</Text>
            </View>
          )}
          <Button
            title="Сохранить"
            onPress={handleSubmit(onСhangeProfile)}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </View>
      )}
    </Container>
  );
};

export default ExerciseGoal;
