import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  Platform,
  ViewProps,
  BackHandler,
  ViewStyle,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

import IconMenu from "../../assets/images/svg/IconMenu";
import { goBack, navigate } from "../../navigation/actions";
import IconBack from "../../assets/images/svg/IconBack";

import styles from "./styles";
import { AppParamsList } from "../../navigation";
import { StackNavigationProp } from "@react-navigation/stack";
import Pressable from "../Pressable";
import Status from "../Status";

export const HeaderMenu = ({ screenName = "" }: { screenName?: string }) => {
  const onPressRightIcon = () => {
    navigate("Menu", { screenName });
  };

  return (
    <Pressable
      // activeOpacity={0.5}
      style={styles.skip}
      pressedStyles={{ opacity: 0.4 }}
      hoverStyles={{ opacity: 0.7 }}
      onPress={onPressRightIcon}
    >
      <IconMenu />
    </Pressable>
  );
};

type Props = ViewProps & {
  backgroundColor?: string;
  showBackButton?: boolean;
  showMenu?: boolean;
  title?: string;
  children?: React.ReactNode;
  backButtonText?: string;
  backButtonFunc?(): void;
  isContainerFixedTop?: boolean;
  screenName?: string;
  showHeader?: boolean;
  showBorderOnFixed?: boolean;
  customStyle?: ViewStyle;
  hideStatus?: boolean;
};

const Header = ({
  backgroundColor = "transparent",
  backButtonText,
  backButtonFunc,
  showBackButton = true,
  showMenu = true,
  title,
  children,
  isContainerFixedTop = false,
  screenName = "",
  showHeader = true,
  showBorderOnFixed = true,
  customStyle,
  hideStatus,
  ...props
}: Props) => {
  const navigation = useNavigation<StackNavigationProp<AppParamsList>>();

  const [isBorderShown, setIsBorderShown] = useState(false);

  const handleScroll = () => {
    if (!showBorderOnFixed) return;
    if (!isContainerFixedTop) return;
    const position = window.pageYOffset;

    if (position) {
      setIsBorderShown(true);
    } else {
      setIsBorderShown(false);
    }
  };

  if (Platform.OS === "web") {
    useEffect(() => {
      window?.addEventListener("scroll", handleScroll);

      return () => {
        window?.removeEventListener("scroll", handleScroll);
      };
    }, []);
  }
  const onPressLeftIcon = () => {
    if (backButtonFunc) {
      backButtonFunc();
      return;
    }

    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate("Main");
  };

  return (
    <View
      {...props}
      style={[
        styles.container,
        Platform.OS === "web" && isContainerFixedTop
          ? styles.containerFixed
          : {},
        isBorderShown ? styles.containerBorder : {},
        {
          backgroundColor,
        },
        customStyle,
      ]}
    >
      {hideStatus ? null : <Status />}
      {showHeader && (
        <View style={styles.header}>
          {showBackButton && (
            <TouchableOpacity
              activeOpacity={0.5}
              style={styles.arrowContainer}
              onPress={onPressLeftIcon}
            >
              <View style={styles.arrow}>
                <IconBack />
                <Text style={styles.arrowText}>
                  {backButtonText ? backButtonText : "Назад"}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          {!!title && <Text style={styles.title}>{title}</Text>}
          {showMenu && <HeaderMenu screenName={screenName} />}
        </View>
      )}
      {children}
    </View>
  );
};

export default Header;
