import { View, Text, FlatList, SafeAreaView } from "react-native";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMessagesRequest,
  createMessageSuccess,
  saveViewesRequest
} from "../../../../store/actions/chat";
import {
  selectMessageIsCreate,
  selectMessagesLoad,
} from "../../../../store/selectors/chat";
import PressableButton from "../../../../components/Pressable";
import MessageInput from "../MessageInput";
import MessageBody from "../MessageBody";
import { useFocusEffect } from "@react-navigation/native";
import Loader from "../../../../components/Loader";
import { selectProfile } from "../../../../store/selectors/profile";

type UsersListType = {
  id: number;
  first_name: string;
  last_name: string;
  avatar: null | string;
};

const AdminMessageList = () => {
  const [usersList, setUsersList] = useState<UsersListType[]>([]);
  const [userSelectId, setUserSelectedId] = useState<number | null>(null);
  const [showUserList, setShowUserList] = useState<boolean>(false);
  const dispatch = useDispatch();
  const messages = useSelector(selectMessagesLoad);
  const isMessageCreate = useSelector(selectMessageIsCreate);
  const [isLoading, setIsLoading] = useState(true);
  const profile = useSelector(selectProfile);


  useFocusEffect(
    useCallback(() => {
      dispatch(
        loadMessagesRequest({
          data: {
            type: "2",
            limit: 3000,
          },
        })
      );
      setIsLoading(false)
      return () => {setIsLoading(true)}
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if(userSelectId) {
      if (messages) {
        const newArr = [...messages];
        const reciewedMessages = newArr.filter(
          (item) => item.user_id !== profile?.id
        );
        let arrUnviewed = reciewedMessages.filter((item) => !item.is_view);
        if (arrUnviewed.length > 0) {
          const ids = Array.from(arrUnviewed, ({ id }) => id).join(",");
          dispatch(saveViewesRequest({ message_ids: ids }));
        }
      }
    }
    }, [messages])
  );

  useFocusEffect(
    useCallback(() => {
      if(userSelectId) {
      let timer1 = setInterval(() => {
        dispatch(
          loadMessagesRequest({
            data: {
              type: "2",
              limit: 3000,
              user_own_id: userSelectId
            },
          })
        );
      }, 2000);
      return () => {
        clearInterval(timer1);
      };
    }
    }, [userSelectId])
  );

  useFocusEffect(
    useCallback(() => {
      dispatch(
        loadMessagesRequest({
          data: {
            type: "2",
            limit: 3000,
            user_own_id: userSelectId
          },
        })
      );
      dispatch(createMessageSuccess(false));
    }, [isMessageCreate])
  );

  const onListPress = () => {
    setShowUserList(!showUserList);
    dispatch(
      loadMessagesRequest({
        data: {
          type: "2",
          limit: 3000,
        },
      })
    );
    if (messages && !userSelectId) {
      const usersListFromMessages = Array.from(messages, ({ user }) => user);
      let tmpArray: number[] = [];
      const itemCheck = (item: any) => {
        if (tmpArray.indexOf(item.id) === -1 && item.id !== profile?.id) {
          tmpArray.push(item.id);
          return true;
        }
        return false;
      };
      const uniqUsersList = usersListFromMessages.filter((item) =>
        itemCheck(item)
      );
      setUsersList(uniqUsersList);
    }
    setUserSelectedId(null);
  };

  const onUserPress = (id: number) => {
    setUserSelectedId(id);
    setShowUserList(false);
    dispatch(
      loadMessagesRequest({
        data: {
          type: "2",
          limit: 3000,
          user_own_id: id,
        },
      })
    );
  };

  if (isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent:'center' }}>
        <Loader />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 1, zIndex: 1 }}>
        <PressableButton
          onPress={() => onListPress()}
          style={{
            width: "100%",
            height: 40,
            backgroundColor: "white",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderColor: "#7133BF",
            borderWidth: 2,
            borderRadius: 4,
          }}
        >
          <Text>Список пользователей</Text>
        </PressableButton>
        {showUserList && (
          <FlatList
            data={usersList}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => (
              <PressableButton
                onPress={() => onUserPress(item.id)}
                style={{
                  width: "100%",
                  height: 40,
                  backgroundColor: "white",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomColor: "black",
                  borderBottomWidth: 1,
                }}
              >
                <Text>
                  {item.first_name} {item.last_name}
                </Text>
              </PressableButton>
            )}
          />
        )}
      </View>
      {userSelectId && (
        <FlatList
          inverted={true}
          data={messages}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => (
            <MessageBody
              messageText={item.message}
              messageId={item.id}
              messageUserId={item.user_id}
              messageDate={item.created_at}
              messageAttachments={item.attachments}
              userFirstName={item.user.first_name}
              userLastName={item.user.last_name}
            />
          )}
        />
      )}
      <MessageInput userSelectId={userSelectId} listType="2" />
    </SafeAreaView>
  );
};

export default AdminMessageList;
