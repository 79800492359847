import React from "react";
import { ActivityIndicator, View } from "react-native";

type Props = {};

const Loader = (props: Props) => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: 44,
        width: "100%",
      }}
    >
      <ActivityIndicator />
    </View>
  );
};

export default Loader;
