import api from "./instance";
import { API_PATHS } from "./paths";
import {
  ProfileType,
  ChangeProfileType,
  UserActivityType,
} from "../types/profile";

export const loadProfile = () => api.get<ProfileType>(`${API_PATHS.PROFILE}`);

export const changeProfile = (params: Partial<ProfileType>) =>
  api.put<ChangeProfileType>(`${API_PATHS.CHANGE_PROFILE}`, params);

export const loadUserActivity = () =>
  api.get<{ [key: string]: UserActivityType }>(`${API_PATHS.USER_ACTIVITY}`);

export const downloadAvatar = (params: FormData) =>
  api.post<any>(`${API_PATHS.DOWNLOAD_AVATAR}`, params);
