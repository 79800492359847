import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  Pressable,
  Dimensions,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { WebView } from "react-native-webview";
import { navigate } from "../../navigation/actions";
import {
  selectCalendarText,
  selectMainCalendarData,
  selectMainCalendarLoading,
  selectProgram,
  selectProgramIsLoading,
} from "../../store/selectors/workout";
import { MainCalendarItem } from "../../types/workout";
import { colors } from "../../assets/theme";
import { numberOf } from "../../helpers/word";
import Loader from "../../components/Loader";
import styles from "./styles";
import Button from "../../components/Button";
import DailyMetrics from "../../components/DailyMetrics";
import { loadUserMetricsRequest } from "../../store/actions/recomendations";
import { selectSaveMetricsData } from "../../store/selectors/recomendations";
import MetricsPopUp from "../../components/MetricsPopUp";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { loadProgramRequest } from "../../store/actions/workout";
import AutoHeightWebView from "react-native-autoheight-webview";

const ListItem = ({ isActive, text }: { isActive: boolean; text: string }) => {
  return (
    <View style={styles.workoutList}>
      <View style={styles.workoutListItem}>
        <Text
          style={[
            styles.workoutListItemDot,
            isActive ? { color: colors.white } : null,
          ]}
        >
          •
        </Text>
        <Text
          style={[
            styles.workoutListItemText,
            isActive ? { color: colors.grayLight } : null,
          ]}
        >
          {text}
        </Text>
      </View>
    </View>
  );
};

type Props = {};

const Training = ({}: Props) => {
  const dispatch = useDispatch();
  const calendarData = useSelector(selectMainCalendarData);
  const isLoading = useSelector(selectProgramIsLoading);
  const programData = useSelector(selectProgram);
  const [modalVisible, setModalVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const calendarText = useSelector(selectCalendarText);
  const [trainingInfo, setTrainingInfo] = useState<any | undefined>();

  const saveMetricsLoading = useSelector(selectSaveMetricsData);

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const [today] = useState(moment.utc().format("YYYY-MM-DD"));

  const showModal = () => {
    setModalVisible(!modalVisible);
  };

  const getStorageData = async () => {
    try {
      const programId = await AsyncStorage.getItem("currentProgramId");
      const trainingId = await AsyncStorage.getItem("currentTrainingId");
      const isTrainigActive = await AsyncStorage.getItem("active");
      if (trainingId) return { programId, trainingId, isTrainigActive };
    } catch (e) {
      console.log("asyncStorageError", e);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (!programData) {
        const storageData = await getStorageData();
        if (storageData && storageData.programId) {
          const { programId } = storageData;
          dispatch(loadProgramRequest(programId));
        }
      }
    };
    getData();
    console.log("programData", programData);
  }, []);

  useEffect(() => {
    if (!programData) return;
    const setTrainingData = async () => {
      const storageData = await getStorageData();
      if (storageData && storageData.trainingId) {
        const { trainingId, isTrainigActive } = storageData;
        if (trainingId === "false") {
          const training = programData[0];
          setTrainingInfo(training ?? null);
          await AsyncStorage.setItem(
            "currentTrainingId",
            programData[0].id.toString()
          );
        }
        const training = programData?.filter(
          (e) => e.id === Number(trainingId)
        )[0];
        setIsActive(isTrainigActive === "true");
        console.log("training", training);
        setTrainingInfo(training ?? null);
      }
    };
    setTrainingData();
  }, [programData]);

  // const getTrainingInfo = () => {
  //   if (calendarData) {
  //     const activeTrainingInfo = calendarData?.find(
  //       (calendar) => calendar.status === 2
  //     );
  //     if (activeTrainingInfo) {
  //       setTrainingInfo(activeTrainingInfo);
  //       setIsActive(true);
  //     } else {
  //       const trainingInfo = calendarData?.find(
  //         (calendar) => calendar.status === 1
  //       );
  //       setTrainingInfo(trainingInfo);
  //       setIsActive(false);
  //     }
  //     console.log("calendarData", calendarData);
  //     console.log("trainingInfo", trainingInfo);
  //     console.log("activeTrainingInfo", activeTrainingInfo);
  //   }
  // };

  useEffect(() => {
    const copyToday = moment.utc();
    const begin = copyToday.subtract(2, "weeks").format("YYYY-MM-DD");
    const end = copyToday.add(2, "months").format("YYYY-MM-DD");
    dispatch(
      loadUserMetricsRequest({ data: { date_begin: begin, date_end: end } })
    );
  }, [saveMetricsLoading]);

  if (isLoading && !programData) {
    return (
      <View
        style={{
          width: windowWidth,
          height: windowHeight - 50,
          justifyContent: "center",
        }}
      >
        <Loader />;
      </View>
    );
  }

  return (
    <View style={{ marginBottom: 30, paddingTop: 10 }}>
      <View>
        <View style={{ marginTop: 30 }}>
          <View style={{ marginTop: 15 }}>
            {calendarText
              ?.sort((a, b) => a.type - b.type)
              .map((item, index) => (
                <View>
                  {item.type === 3 && (
                    <Text style={styles.title}>Рекомендация дня</Text>
                  )}
                  <View
                    style={[
                      styles.recomendationContent,
                      {
                        borderColor:
                          item.type === 2 ? colors.purple : colors.grayDark_2,
                      },
                    ]}
                  >
                    <Text
                      style={[styles.workoutListItemText, { marginTop: 0 }]}
                    >
                      {item.text}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
          <Text style={styles.title}>Тренировка</Text>
        </View>
        {trainingInfo && (
          <>
            <View
              style={[
                isActive ? { marginTop: 50 } : null,
                { marginBottom: 20 },
              ]}
            >
              <View
                style={[
                  styles.workout,
                  {
                    backgroundColor: isActive
                      ? colors.purple
                      : colors.grayLight,
                  },
                ]}
              >
                <View
                  style={[
                    styles.workoutTitle,
                    {
                      borderColor: colors.grayLight_1,
                      opacity: isActive ? 0.3 : 1,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.workoutName,
                      isActive ? { color: colors.grayLight } : null,
                    ]}
                  >
                    {trainingInfo?.day ?? ""}
                  </Text>
                </View>
                {trainingInfo?.exercises.length ? (
                  <>
                    <ListItem
                      isActive={isActive}
                      text={`${trainingInfo?.exercises?.length} ${numberOf(
                        trainingInfo?.exercises?.length,
                        "упражнен",
                        ["ий", "ия", "ий"]
                      )}`}
                    />
                    <ListItem
                      isActive={isActive}
                      text={trainingInfo?.exercises
                        ?.reduce(
                          (prev: string[], curr) => [...prev, curr.exercise],
                          []
                        )
                        ?.join(", ")}
                    />
                  </>
                ) : (
                  <Text
                    style={[
                      styles.workoutName,
                      isActive ? { color: colors.grayLight } : null,
                      { marginTop: 12 },
                    ]}
                  >
                    {trainingInfo?.description ?? ""}
                  </Text>
                )}
              </View>
            </View>
            <Button
              onPress={() => {
                navigate("Quiz", {
                  programDayId: trainingInfo?.id,
                });
              }}
              title={isActive ? "Продолжить тренировку" : "Начать тренировку"}
            />
          </>
        )}
        <View style={{ marginTop: 30, marginBottom: 24 }}>
          <DailyMetrics
            title="Измерения"
            btnHandler={() => showModal()}
            date={today}
          />
        </View>
      </View>

      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <Pressable
          onPress={() => setModalVisible(false)}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Pressable
            onPress={(e) => {
              e.stopPropagation();
            }}
          >
            <MetricsPopUp
              date={today}
              closePopUp={() => setModalVisible(false)}
            />
          </Pressable>
        </Pressable>
      </Modal>
    </View>
  );
};

export default Training;
