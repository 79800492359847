import { Dimensions, Platform, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    // paddingTop: 68,
    paddingBottom: 72,
  },
  contentBlock: {
    paddingHorizontal: 20,
  },
  title: {
    fontSize: fontSizes.big,
    lineHeight: fontSizes.big,
    fontFamily: "bold",
    color: colors.black,
  },
  purpleBlock: {
    marginTop: 15,
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: 8,

    padding: 12,
    paddingBottom: 26,
    backgroundColor: colors.purple,
  },
  purpleBlockText: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.white,
  },
  whiteBlock: {
    marginTop: 15,
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: 8,

    padding: 12,
    paddingBottom: 26,
    backgroundColor: colors.white,
  },
  whiteBlockText: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },

  circle: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 16,
    height: 16,
    backgroundColor: colors.black,
    borderRadius: 8,
  },
  timeline: {
    position: "absolute",
    top: 18,
    left: 7,
    width: 2,
    height: 24,
    backgroundColor: colors.grayDark,
  },
  timelineItem: {
    position: "absolute",
    top: -42,
    left: 7,
    width: 2,
    height: 44,
    backgroundColor: colors.grayDark,
  },
  loader: {
    alignItems: "center",
    justifyContent: "center",
    height: 44,
    width: "100%",
  },
  timelineTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.black,
    fontFamily: "regular",
  },
  timelineDescr: {
    marginBottom: 12,
    fontSize: 12,
    lineHeight: 20,
    color: colors.grayDark_1,
    fontFamily: "regular",
  },
  timelineCircle: {
    position: "absolute",
    top: 6,
    left: 4,
    width: 8,
    height: 8,
    backgroundColor: colors.grayDark,
    borderRadius: 8,
  },
  timelineLine: {
    position: "absolute",
    top: 14,
    left: 7,
    width: 2,
  },

  day: {
    paddingLeft: 28,
  },
  dayTitle: {
    marginBottom: 16,
    marginLeft: 30,

    fontSize: 12,
    color: "#1C0638",
    fontFamily: "bold",
    lineHeight: 20,
    textTransform: "uppercase",
    letterSpacing: 0.7,
  },
  dayCircle: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 16,
    height: 16,
    backgroundColor: "#1C0638",
    borderRadius: 8,
  },
  dayTimelineBottom: {
    position: "absolute",
    top: 18,
    left: 7,
    width: 2,
    height: 24,
    backgroundColor: "#DDDAE1",
  },
  dayTimelineTop: {
    position: "absolute",
    top: -42,
    left: 7,
    width: 2,
    height: 44,
    backgroundColor: "#DDDAE1",
  },
  exerciseTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: "#1C0638",
    fontFamily: "regular",
    // marginRight: 15,
    width: 50,
  },
  exerciseSubtitle: {
    marginBottom: 12,
    fontSize: 12,
    lineHeight: 20,
    color: "#8E839C",
    fontFamily: "regular",
    marginHorizontal: 5,
  },
  exerciseCircle: {
    position: "absolute",
    top: 6,
    left: 4,
    width: 8,
    height: 8,
    backgroundColor: "#DDDAE1",
    borderRadius: 8,
  },
  exerciseTimeline: {
    position: "absolute",
    top: 14,
    left: 7,
    width: 2,
    backgroundColor: "#DDDAE1",
  },
  calendarDate: {
    fontSize: 16,
    fontFamily: "semibold",
    marginBottom: 10,
  },
  dayCircleTitle: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 16,
    height: 16,
    backgroundColor: "#1C0638",
    borderRadius: 8,
  },
  dayTimelineTitleBottom: {
    position: "absolute",
    top: 18,
    left: 7,
    width: 2,
    height: 24,
    backgroundColor: "#DDDAE1",
  },
  sliderContainer: {
    padding: 20,
  },
  sliderTitleContainer: {
    flexDirection: "row",
  },
  sliderTitle: {
    fontSize: fontSizes.middle,
    fontFamily: "bold",
    color: colors.black,
  },
  sliderTitleRed: {
    fontSize: fontSizes.middle,
    fontFamily: "bold",
    color: colors.red,
  },
  sliderSubTitle: {
    fontSize: fontSizes.small_2,
    fontFamily: "bold",
    color: colors.black,
  },
  sliderCalText: {
    fontSize: fontSizes.small,
    fontFamily: "bold",
    color: colors.white,
  },
  caloriesBlock: {
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: 8,
    padding: 5,
    backgroundColor: colors.purple,
  },
  sliderTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
  },
});

export default styles;
