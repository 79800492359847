import React, { FunctionComponent, useEffect, useState } from "react";
import {
  FlatList,
  Text,
  TouchableOpacity,
  View,
  ListRenderItem,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import IconDropdown from "../../assets/images/svg/IconDropdown";
import {
  loadWorkoutsRequest,
  workoutsProgramRequest,
} from "../../store/actions/workout";
import { selectWorkoutData } from "../../store/selectors/workout";
import { WorkoutsType } from "../../types/workout";

import styles from "./styles";

export type DataProps = {
  name: string;
  id: number | "";
  withLine?: string;
  gender?: string;
};

interface Props {
  name: string;
  data: DataProps[] | null;
  selected: DataProps | null;
  onSelect: (item: DataProps) => void;
  position?: "top";
}

const Dropdown = ({ data, name, onSelect, selected, position }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [height, setHeight] = useState(0);

  // const capitalize = (str: string) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  const toggleDropdown = () => {
    setVisible((visible) => !visible);
  };

  const onItemPress = (item: DataProps) => {
    // dispatch(workoutsProgramRequest(item?.id));
    onSelect(item);
    setVisible(false);
  };

  const renderItem: ListRenderItem<DataProps> = ({ item, index }) => {
    return (
      <>
        {item.withLine === "top" && <View style={styles.itemLine}></View>}
        <TouchableOpacity
          style={[
            styles.item,
            item.id === selected?.id ? styles.itemActive : {},
          ]}
          onPress={() => onItemPress(item)}
        >
          <Text style={styles.itemText}>{item?.name}</Text>
        </TouchableOpacity>
        {item.withLine === "bottom" && <View style={styles.itemLine}></View>}
      </>
    );
  };

  const renderDropdown = () => {
    return (
      <>
        {visible && (
          <View
            style={[
              styles.dropdown,
              position === "top" ? { top: -height } : {},
            ]}
            onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
          >
            <FlatList
              data={data}
              renderItem={renderItem}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        )}
      </>
    );
  };

  return (
    <View style={position !== "top" ? { zIndex: 123 } : {}}>
      <TouchableOpacity
        activeOpacity={0.8}
        style={[styles.button, visible ? styles.buttonActive : {}]}
        onPress={toggleDropdown}
      >
        <Text
          style={[styles.buttonText, !!selected ? styles.buttonTextActive : {}]}
        >
          {(!!selected?.name && selected?.name) || name}
        </Text>
        <IconDropdown
          style={{ transform: [{ rotate: visible ? "180deg" : "0deg" }] }}
        />
      </TouchableOpacity>
      {renderDropdown()}
    </View>
  );
};

export default Dropdown;
