import React from "react";
import {TouchableOpacity} from "react-native";

import styles from "./style";
import IconCheckboxCheck from '../../assets/images/svg/IconCheckboxCheck';

const Checkbox = ({ value, onValueChange, ...props }: Props) => {
  const onPress = () => {
    if (value === 0) {
      onValueChange(1);
    } else {
      onValueChange(0);
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, value === 1 ? styles.true : styles.false]}
      {...props}
    >
      {value === 1 && <IconCheckboxCheck />}
    </TouchableOpacity>
  );
};

export default Checkbox;
