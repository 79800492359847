import { handleActions } from "redux-actions";
import { DaysResponse } from "../../../types/recomendations";

import * as actions from "../../actions/recomendations";

type State = typeof initialState;

const initialState = {
  data: null as null | DaysResponse,
  isLoading: false,
  error: "",
};

const daysReducer = handleActions<State, any>(
  {
    [actions.loadDaysRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadDaysSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadDaysSuccess>
    ) => ({
      ...state,
      isLoading: false,
      error: "",
      data: payload,
    }),
    [actions.loadDaysFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadDaysFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default daysReducer;
