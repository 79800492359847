import { combineReducers } from "redux";

import userMetricsReducer from "./userMetrics";
import saveMetricsReducer from "./saveMetrics";
import metricsListReducer from "./metricsList";
import recomendationsListReducer from "./recomendations";
import daysReducer from "./days";

const adsReducer = combineReducers({
  userMetrics: userMetricsReducer,
  saveMetrics: saveMetricsReducer,
  metricsList: metricsListReducer,
  recomendationsList: recomendationsListReducer,
  days: daysReducer,
});

export default adsReducer;
