import { createAction } from "redux-actions";
import { DefaultMeta } from "../../types/api";

import { CheckoutTinkof, CheckoutTinkofRequest } from "../../types/checkout";

const scope = "CHECKOUT";

export const loadCheckoutTariffsRequest = createAction(
  `${scope}/LOAD_СHECKOUT_TARIFFS_REQUEST`
);
export const loadCheckoutTariffsSuccess = createAction(
  `${scope}/LOAD_СHECKOUT_TARIFFS_SUCCESS`
);
export const loadCheckoutTariffsFailure = createAction<string>(
  `${scope}/LOAD_СHECKOUT_TARIFFS_FAILURE`
);

export const createCheckoutTinkofRequest = createAction<
  {
    data: CheckoutTinkofRequest;
  },
  {
    onSuccess?(data: CheckoutTinkof): void;
    onFailed?(): void;
  }
>(
  `${scope}/CREATE_CHECKOUT_TINKOF_REQUEST`,
  (payload) => payload,
  (_, meta) => meta
);
export const createCheckoutTinkofSuccess = createAction(
  `${scope}/CREATE_CHECKOUT_TINKOF_SUCCESS`
);
export const createCheckoutTinkofFailure = createAction<string>(
  `${scope}/CREATE_CHECKOUT_TINKOF_FAILURE`
);
