export const API_PATHS = {
  LOGIN: "/api/login",
  SIGNUP: "/api/register",
  PASSWORD_RECOVERY: "/api/password_recovery",

  PROFILE: "/api/me",
  CHANGE_PROFILE: "/api/me",
  USER_ACTIVITY: "/api/book/activity_levels",
  DOWNLOAD_AVATAR: "/api/avatar",

  WORKOUTS: "/api/book/groups",
  WORKOUT_PROGRAM: "/api/program/index",
  WORKOUT_LOCATIONS: "api/book/program_locations",

  PROGRAM: "/api/program/days_with_exercises_by_program_id?program_id=",
  PROGRAM_BY_DAY: "/api/program/exercises_by_program_day_id?program_day_id=",
  PROGRAM_SAVE: "/api/program/save_user_location",
  PROGRAM_DELETE: "/api/program/delete_user_location",
  TEST: "/api/program/test",
  SAVE_TEST: "/api/program/save_test_result",

  EXERCISES: "/api/program/exercises_by_day?day=",
  EXERCISES_PROGRAM_BY_DAY_ID: "/api/program/exercises_by_day?program_day_id=",
  EXERCISE_UPDATE_DAY_DATE_STATUS: "/api/calendar/update/",

  SAVE_APPROACH: "/api/program/save_user_approach",

  CALENDAR_MAIN: "/api/calendar/index",

  CHECKOUT_TARIFFS: "/api/checkout/tariffs",
  CHECKOUT_METHODS: "/api/checkout/methods",
  CHECKOUT_TINKOF_CREATE: "/api/checkout/tinkoff/create",

  MESSAGES_LOAD: "api/chat/index",
  MESSAGE_CREATE: "api/chat/create",
  MESSAGE_DELETE: "api/chat/message/",
  MESSAGE_CREATE_ATTACHMENT: "api/chat/create_attachment/",

  MESSAGES_NOT_VIEWED_COUNT: "api/chat/count_not_view",
  MESSAGES_SAVE_VIEWS: "api/chat/save_message_views",

  ATTACHMENT_DELETE: "api/chat/attachment/",

  CALENDAR_TEXT: "api/calendar/text",

  RECOMENDATIONS_LOAD: "api/recommendation/index",
  SAVE_ONE_METRIC: "api/recommendation/save_metric",
  GET_METRICS_LIST: "api/book/metrics",
  SAVE_METRICS: "api/recommendation/save_metrics",
  GET_USER_METRICS: "api/recommendation/metrics",
  GET_DAYS: "api/recommendation/last_days",
};
