import React, { useEffect, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import Container from "../../components/Container";
import { colors } from "../../assets/theme";

import styles from "./styles";
import { navigate } from "../../navigation/actions";
import {
  loadWorkoutTestRequest,
  saveTestRequest,
} from "../../store/actions/workout";
import { useDispatch, useSelector } from "react-redux";
import {
  selectWorkoutTestData,
  selectWorkoutTestIsLoading,
} from "../../store/selectors/workout";
import QuizScreen from "./QuizScreen";
import QuizFinalScreen from "./QuizFinalScreen";
import Loader from "../../components/Loader";
import { WorkoutTestType } from "../../types/workout";

type NavProps = {
  route: RouteProp<AppParamsList, "Quiz">;
  navigation: StackNavigationProp<AppParamsList, "Quiz">;
};

const QuizContainer = ({ navigation, route }: NavProps) => {
  const dispatch = useDispatch();
  const workoutTest = useSelector(selectWorkoutTestData);
  const isLoading = useSelector(selectWorkoutTestIsLoading);

  const [isAllQuestionAnswered, setIsAllQuestionAnswered] = useState(false);
  const [quiz, setQuiz] = useState<WorkoutTestType | undefined>(undefined);
  const [quizPage, setQuizPage] = useState(0);
  const [sumBalls, setSumBalls] = useState(0);
  const { programDayId } = route?.params;

  const handleAnswerPress = (balls: number) => {
    setSumBalls((sumBalls) => sumBalls + balls);

    if (workoutTest && workoutTest?.length > quizPage) {
      setQuizPage((quizPage) => quizPage + 1);
    } else {
      dispatch(saveTestRequest({ data: { result: sumBalls + balls } }));
      setIsAllQuestionAnswered(true);
    }
  };

  useEffect(() => {
    if (workoutTest) {
      setQuiz(workoutTest[quizPage - 1]);
    }
  }, [quizPage]);

  useEffect(() => {
    if (!workoutTest) {
      dispatch(loadWorkoutTestRequest());
    } else {
      if (workoutTest.length) {
        setQuizPage(1);
      }
    }
  }, [workoutTest]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        bounces={false}
        showsVerticalScrollIndicator={false}
      >
        <Header showBackButton={true} backgroundColor={colors.white} />
        <View style={styles.contentBlock}>
          <View style={styles.container}>
            {isAllQuestionAnswered ? (
              <QuizFinalScreen id={programDayId} />
            ) : (
              <QuizScreen
                quiz={quiz}
                quizPage={quizPage}
                handleAnswerPress={handleAnswerPress}
              />
            )}
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

export default QuizContainer;
