import moment from "moment";
import React, { useState } from "react";
import { ScrollView, View, Text } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useSelector } from "react-redux";
import { RouteProp } from "@react-navigation/native";

import { AppParamsList } from "../../navigation";

import IconCheck from "../../assets/images/svg/IconCheck";
import { colors } from "../../assets/theme";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Header from "../../components/Header";
import WorkoutPlanner from "../../components/WorkoutPlanner";
import Error from "../../components/Error";
import useSaveProgram from "../../hooks/useSaveProgram";
import { navigate } from "../../navigation/actions";
import { selectProfile } from "../../store/selectors/profile";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "PlanningProgram">;
  navigation: StackNavigationProp<AppParamsList, "PlanningProgram">;
};

const PlanningProgram = ({ route, navigation }: NavProps) => {
  const {
    deleteThenSaveProgram,
    deleteCurrentProgram,
    saveProgram,
    isLoading,
    error,
  } = useSaveProgram("");
  const { title, id } = route.params;
  const profile = useSelector(selectProfile);
  const [recordDone, setRecordDone] = useState(false);
  // "YYYY-MM-DD"
  const [newDate, setNewDate] = useState("");

  const handlePressDone = async () => {
    // api here
    if (profile?.program_id) {
      await deleteThenSaveProgram(
        {
          location_id: id,
        },
        "",
        () => setRecordDone(true)
      );
      return;
    }
    await saveProgram(
      {
        location_id: id,
      },
      "",
      () => setRecordDone(true)
    );
  };

  const handlePressCancel = async () => {
    if (profile?.program_id && profile.program_date_begin) {
      await deleteThenSaveProgram(
        {
          location_id: profile?.location_id,
        },
        "",
        () => setRecordDone(false)
      );
      return;
    } else {
      alert("Ошибка, невозможно отменить программу");
    }
  };

  const handleChangeData = (date: string) => {
    setNewDate(date);
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header
          showBackButton={true}
          backButtonText={title}
          backgroundColor={colors.white}
        />
        {!recordDone ? (
          <View style={styles.contentBlock}>
            <View>
              <Text style={styles.planningTitle}>Запланировать тренировку</Text>
              <WorkoutPlanner
                type="program"
                handleChangeData={handleChangeData}
              />
            </View>
          </View>
        ) : null}
        {recordDone ? (
          <View style={styles.contentBlock}>
            <View style={styles.completeDoneBlock}>
              <View style={styles.checkIcon}>
                <IconCheck />
              </View>
              {!!newDate ? (
                <Text style={{ ...styles.planningTitle, marginBottom: 0 }}>
                  Тренировка «{title}» запланирована на{" "}
                  {moment
                    .utc(newDate, "YYYY-MM-DD")
                    .locale("ru")
                    .format("D MMM")}
                </Text>
              ) : (
                <Text style={{ ...styles.planningTitle, marginBottom: 0 }}>
                  Тренировка не запланирована
                </Text>
              )}
            </View>
            <Button
              title="Отменить"
              isSecondaryButton
              // style={{ backgroundColor: colors.grayDark }}
              // textStyle={{ color: colors.black }}
              onPress={handlePressCancel}
            />
          </View>
        ) : null}
      </ScrollView>
      <View style={styles.buttonBlock}>
        <Error error={error} />
        {recordDone ? (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            title="Вернуться на главную"
            onPress={() => navigate("Main")}
          />
        ) : (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            title={true ? "Запланировать" : "Сохранить"}
            onPress={handlePressDone}
          />
        )}
      </View>
    </Container>
  );
};

export default PlanningProgram;
