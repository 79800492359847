import { handleActions } from "redux-actions";

import * as actions from "../actions/profile";
import { ProfileType, UserActivityType } from "../../types/profile";

type ProfileState = typeof initialState;

const initialState = {
  data: null as null | ProfileType,
  isLoading: false,
  isProfileUpdating: false,
  isAvatarUpdating: false,
  error: "",
  updateError: "",
  avatarError: "",
};

const profileReducer = handleActions<ProfileState, any>(
  {
    [actions.loadProfileRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadProfileSuccess.toString()]: (
      state,
      // { payload }: ReturnType<typeof actions.loadProfileSuccess>
      { payload }: { payload: ProfileType }
    ) => ({
      ...state,
      isLoading: false,
      data: { ...state.data, ...payload },
    }),
    [actions.loadProfileFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadProfileFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    [actions.changeProfileRequest.toString()]: (state) => ({
      ...state,
      isProfileUpdating: true,
      updateError: "",
    }),
    [actions.changeProfileSuccess.toString()]: (state) => ({
      ...state,
      isProfileUpdating: false,
      updateError: "",
    }),
    [actions.changeProfileFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadProfileFailure>
    ) => ({
      ...state,
      isProfileUpdating: false,
      updateError: payload,
    }),
    // [actions.loadUserActivityRequest.toString()]: (state) => ({
    //   ...state,
    //   isLoading: true,
    //   error: "",
    // }),
    // [actions.loadUserActivitySuccess.toString()]: (
    //   state,
    //   // { payload }: ReturnType<typeof actions.loadUserActivitySuccess>
    //   { payload }: { payload: UserActivityType }
    // ) => ({
    //   ...state,
    //   isLoading: false,
    //   data: state.data ? { ...state.data, activity_level_id: [...payload] } : null,
    // }),
    // [actions.loadUserActivityFailure.toString()]: (
    //   state,
    //   { payload }: ReturnType<typeof actions.loadUserActivityFailure>
    // ) => ({
    //   ...state,
    //   isLoading: false,
    //   error: payload,
    // }),
    [actions.downloadAvatarRequest.toString()]: (state) => ({
      ...state,
      isAvatarUpdating: true,
      avatarError: "",
    }),
    [actions.downloadAvatarSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.downloadAvatarSuccess>
    ) => ({
      ...state,
      isAvatarUpdating: false,
      data: state?.data ? { ...state.data, avatar: payload } : null,
    }),
    [actions.downloadAvatarFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.downloadAvatarFailure>
    ) => ({
      ...state,
      isAvatarUpdating: false,
      avatarError: payload,
    }),
  },
  initialState
);

export default profileReducer;
