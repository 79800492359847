import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";

import { Approach } from "../../types/workout";

import styles from "./styles";

type Props = {
  title: string;
  item: Approach;
  index: number;
  isLastIndex: boolean;
  highlightLine: boolean;
};

const ApproachItem = ({
  title,
  item,
  index,
  isLastIndex,
  highlightLine,
}: Props) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [subtitleHeight, setSubtitleHeight] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    setIsCompleted(!!item.user_result);
  }, []);

  return (
    <View style={styles.day}>
      <Text
        onLayout={(event) => setTitleHeight(event.nativeEvent.layout.height)}
        style={[styles.exerciseTitle, isCompleted ? { color: "#8E839C" } : {}]}
      >
        {/*{title}*/}
      </Text>
      <Text
        onLayout={(event) => setSubtitleHeight(event.nativeEvent.layout.height)}
        style={styles.exerciseSubtitle}
      >
        {item?.user_result?.repeat
          ? `${item?.user_result?.repeat} раз, `
          : `${item?.repeat} раз, `}
        {item.user_result?.weight
          ? `${item?.user_result?.weight} кг, `
          : `${item?.weight} кг, `}
        {item.user_result?.weight
          ? `RPE ${item?.user_result?.pre}`
          : `RPE ${item?.pre}`}
      </Text>
      <View
        style={[
          styles.exerciseCircle,
          isCompleted ? { backgroundColor: "#9CF444" } : {},
        ]}
      />
      {/*{!isProgramIndex && (*/}
      {/*  <View*/}
      {/*    style={[*/}
      {/*      styles.exerciseTimeline,*/}
      {/*      highlightLine ? { backgroundColor: "#9CF444" } : {},*/}
      {/*      {*/}
      {/*        height: titleHeight + subtitleHeight + 4,*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*)}*/}
      {!isLastIndex && (
        <View
          style={[
            styles.exerciseTimeline,
            highlightLine ? { backgroundColor: "#9CF444" } : {},
            {
              height: titleHeight + subtitleHeight + 4,
            },
          ]}
        />
      )}
    </View>
  );
};

export default ApproachItem;
