import { StyleSheet } from "react-native";
import { colors } from "../../assets/theme";

const styles = StyleSheet.create({
  title: {
    marginBottom: 16,
    fontSize: 12,
    color: colors.black,
    fontFamily: "bold",
    lineHeight: 20,
  },
  circle: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 16,
    height: 16,
    backgroundColor: colors.black,
    borderRadius: 8,
  },
  timeline: {
    position: "absolute",
    top: 18,
    left: 7,
    width: 2,
    height: 24,
    backgroundColor: colors.grayDark,
  },
  timelineItem: {
    position: "absolute",
    top: -42,
    left: 7,
    width: 2,
    height: 44,
    backgroundColor: colors.grayDark,
  },
  loader: {
    alignItems: "center",
    justifyContent: "center",
    height: 44,
    width: "100%",
  },
  timelineTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.black,
    fontFamily: "regular",
  },
  timelineDescr: {
    marginBottom: 12,
    fontSize: 12,
    lineHeight: 20,
    color: colors.grayDark_1,
    fontFamily: "regular",
  },
  timelineCircle: {
    position: "absolute",
    top: 6,
    left: 4,
    width: 8,
    height: 8,
    backgroundColor: colors.grayDark,
    borderRadius: 8,
  },
  timelineLine: {
    position: "absolute",
    top: 14,
    left: 7,
    width: 2,
  },

  day: {
    paddingLeft: 28,
  },
  dayTitle: {
    marginBottom: 16,

    fontSize: 12,
    color: "#1C0638",
    fontFamily: "bold",
    lineHeight: 20,
    textTransform: "uppercase",
    letterSpacing: 0.7,
  },
  dayCircle: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 16,
    height: 16,
    backgroundColor: "#1C0638",
    borderRadius: 8,
  },
  dayTimelineBottom: {
    position: "absolute",
    top: 18,
    left: 7,
    width: 2,
    height: 24,
    backgroundColor: "#DDDAE1",
  },
  dayTimelineTop: {
    position: "absolute",
    top: -42,
    left: 7,
    width: 2,
    height: 44,
    backgroundColor: "#DDDAE1",
  },
  exerciseTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: "#1C0638",
    fontFamily: "regular",
  },
  exerciseSubtitle: {
    marginBottom: 12,
    fontSize: 12,
    lineHeight: 20,
    color: "#8E839C",
    fontFamily: "regular",
  },
  exerciseCircle: {
    position: "absolute",
    top: 6,
    left: 4,
    width: 8,
    height: 8,
    backgroundColor: "#DDDAE1",
    borderRadius: 8,
  },
  exerciseTimeline: {
    position: "absolute",
    top: 14,
    left: 7,
    width: 2,
    backgroundColor: "#DDDAE1",
  },
  calendarDate: {
    fontSize: 16,
    fontFamily: "semibold",
    marginBottom: 10,
  },
  programDescription: {
    fontSize: 16,
    fontFamily: "semibold",
    marginBottom: 20,
  },
});

export default styles;
