import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { ScrollView, View, Text } from "react-native";

import IconCheck from "../../assets/images/svg/IconCheck";
import { colors } from "../../assets/theme";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { AppParamsList } from "../../navigation";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "PasswordInstruction">;
  navigation: StackNavigationProp<AppParamsList, "PasswordInstruction">;
};

const PasswordInstruction = ({ navigation, route }: NavProps) => {
  const email = route.params?.email;
  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView
        bounces={false}
        showsVerticalScrollIndicator={false}
        // style={{ justifyContent: "center" }}
        // style={{ justifyContent: "center" }}
        contentContainerStyle={{ justifyContent: "center", height: "100%" }}
      >
        <View style={styles.contentBlock}>
          <View style={styles.completeDoneBlock}>
            <View style={styles.checkIcon}>
              <IconCheck />
            </View>
            <Text style={styles.completeTextBlockTitle}>
              Инструкция со сменой пароля отправлена на почту {email || ""}
            </Text>
            <View
              style={{
                width: "100%",
                paddingTop: 11,
                paddingBottom: 20,
                paddingHorizontal: 20,
              }}
            >
              <Button
                disabled={false}
                title={"Вернуться в Профиль"}
                style={styles.button}
                isSecondaryButton
                // textStyle={{ color: colors.black }}
                onPress={() => navigation.navigate("Profile")}
              />
              <Button
                disabled={false}
                title={"Перейти на главную"}
                style={styles.button}
                isSecondaryButton
                // textStyle={{ color: colors.black }}
                onPress={() => navigation.navigate("Main")}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

export default PasswordInstruction;
