import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 16,
    // marginTop: 29,
  },
  completeDoneBlock: {
    alignItems: "center",

    // marginTop: 60,
    // marginBottom: 34,
  },
  checkIcon: {
    justifyContent: "center",
    alignItems: "center",

    width: 120,
    height: 120,

    marginBottom: 32,

    borderRadius: 60,
    backgroundColor: colors.purple,
  },
  completeTextBlockTitle: {
    maxWidth: 343,

    marginBottom: 16,

    textAlign: "center",
    fontSize: fontSizes.middle,
    fontFamily: "semibold",
    color: colors.black,
  },
  button: {
    backgroundColor: colors.grayDark,
    marginBottom: 12,
    maxWidth: 300,
    width: "100%",
    alignSelf: "center",
  },
});

export default styles;
