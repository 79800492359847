import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 16,
    marginTop: 47,
    marginBottom: 20,
  },
  title: {
    marginBottom: 18,
    textAlign: "center",
    fontSize: fontSizes.big,
    fontFamily: "bold",
    color: colors.black,
  },
  fieldWrap: {
    marginVertical: 6,
  },
  buttonBlock: {
    // position: "absolute",
    // bottom: 0,
    // left: 0,
    // right: 0,

    width: "100%",
    // paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 16,
  },
  conditions: {
    marginTop: 10,

    textAlign: "center",
    fontSize: fontSizes.small,
    lineHeight: fontSizes.middle,
    fontFamily: "regular",
    color: colors.grayDark_1,
    letterSpacing: 0.7,
  },
  errorContainer: {
    position: "absolute",
    top: -20,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
  },
});

export default styles;
