import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingTop: 60,
    paddingHorizontal: 16,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
  planningTitle: {
    marginBottom: 23,
    textAlign: "center",
    fontSize: fontSizes.middle,
    fontFamily: "semibold",
    color: colors.black,
  },
  field: {
    width: "100%",
    marginTop: 8,
  },
  completeDoneBlock: {
    marginBottom: 34,
    alignItems: "center",
  },
  checkIcon: {
    justifyContent: "center",
    alignItems: "center",
    width: 120,
    height: 120,
    marginBottom: 32,
    borderRadius: 60,
    backgroundColor: colors.purple,
  },
  buttonBlock: {
    width: "100%",
    paddingTop: 11,
    paddingBottom: 10,
    paddingHorizontal: 16,
  },
});

export default styles;
