import React from "react";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ScrollView, Text, View } from "react-native";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import Container from "../../components/Container";
import Subscription from "../../components/Subscription";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "Subscription">;
  navigation: StackNavigationProp<AppParamsList, "Subscription">;
};

const SubscriptionContainer = ({ navigation }: NavProps) => {
  return <SubscriptionScreen />;
};

const SubscriptionScreen = () => {
  return (
    <Container>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header showBackButton={true} />
        <View style={styles.contentBlock}>
          <Text style={styles.title}>Подписки</Text>
          <Subscription showDetailSubs />
          {/* <Subscription fullcard='full'/> */}
        </View>
      </ScrollView>
    </Container>
  );
};

export default SubscriptionContainer;
