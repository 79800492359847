import React from "react";
import { View, Text, StyleSheet } from "react-native";

import { colors, fontSizes } from "../../assets/theme";
import Timeline from "../../components/Timeline";
import { ProgramType } from "../../types/workout";

type Props = {
  // id: number;
  program: ProgramType | null;
  error: string;
};

const Workouts = ({ program, error }: Props) => {
  return (
    <>
      {!program ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 44,
            width: "100%",
          }}
        >
          <Text style={styles.error}>{error}</Text>
        </View>
      ) : (
        <View style={{ marginTop: 34, marginBottom: 50 }}>
          {!!program && <Timeline item={program} />}
        </View>
      )}
    </>
  );
};

export default Workouts;

const styles = StyleSheet.create({
  error: {
    fontSize: fontSizes.small_2,
    lineHeight: fontSizes.middle,
    color: colors.red,
    fontFamily: "light",
  },
});
