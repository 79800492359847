import { Platform, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  selectWorkout: {
    marginTop: 20,
    zIndex: 1,
  },
  workoutTypes: {
    marginTop: 24,
  },
  workoutList: {},
  workoutListTitle: {
    marginTop: 25,
    textTransform: "uppercase",
    fontSize: fontSizes.mini,
    fontFamily: "bold",
    color: colors.grayText,
  },

  workoutItem: {
    marginTop: 6,
    paddingVertical: 8,
    paddingHorizontal: 12,
    paddingBottom: 16,
    borderRadius: 8,
    backgroundColor: colors.grayLight,
  },
  workoutItemTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.grayLight_1,
  },
  workoutItemTitleText: {
    fontSize: fontSizes.small,
    fontFamily: "bold",
    color: colors.black,
  },
  workoutItemTime: {
    alignSelf: "center",

    marginRight: 4,
    paddingHorizontal: 8,
    paddingVertical: 2,

    borderRadius: 8,
    backgroundColor: colors.grayLight_1,
  },
  workoutItemTimeText: {
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.grayDark_1,
  },
  workoutItemDescr: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  //@ts-expect-error
  buttonBlock: {
    ...Platform.select({
      web: {
        position: "fixed",
      },
      default: {
        position: "absolute",
      },
    }),
    bottom: 0,
    width: "100%",
    paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
});

export default styles;
