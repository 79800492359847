import React from "react";
import styles from "./styles";
import { Text, View } from "react-native";
import Button from "../Button";
import IconRight from "../../assets/images/svg/IconRight";
import { useSelector } from "react-redux";
import {
  selectUserMetricsData,
  selectUserMetricsError,
  selectUserMetricsLoading,
} from "../../store/selectors/recomendations";
import Loader from "../Loader";

type Props = {
  title: string;
  btnHandler: () => void;
  date: string;
};

const DailyMetrics = ({ title, btnHandler, date }: Props) => {
  const usersMetrics = useSelector(selectUserMetricsData);
  const isLoading = useSelector(selectUserMetricsLoading);
  const isError = useSelector(selectUserMetricsError);

  const thisDayMetrics = usersMetrics?.filter((e) => e.date === date);
  const weight = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "weight"
  );
  const waist = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "waist"
  );
  const hips = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "hips"
  );
  const hip = thisDayMetrics?.filter((metric) => metric.metric.slug === "hip");
  const biceps = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "biceps"
  );
  const calories = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "calories"
  );
  const bfp = thisDayMetrics?.filter((metric) => metric.metric.slug === "bfp");

  if (isLoading) {
    return <Loader />;
  }

  if (isError !== "") {
    return <Text>{isError}</Text>;
  }

  return (
    <View style={styles.workoutSlide}>
      <View style={styles.workoutSlideTop}>
        <Text style={styles.title}>{title}</Text>
        <View>
          <Button
            title={
              thisDayMetrics?.length !== undefined && thisDayMetrics?.length > 0
                ? "Обновить"
                : "Добавить"
            }
            whiteButton={true}
            Icon={IconRight}
            onPress={btnHandler}
          />
        </View>
      </View>

      {thisDayMetrics?.length !== undefined && thisDayMetrics?.length > 0 && (
        <View style={styles.workoutData}>
          <View style={styles.workoutDataBlock}>
            {weight?.length !== undefined &&
              weight?.length > 0 &&
              weight[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {weight[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${weight[0].value} кг`}
                  </Text>
                </View>
              )}
            {bfp?.length !== undefined &&
              bfp?.length > 0 &&
              bfp[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {bfp[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${bfp[0].value} %`}
                  </Text>
                </View>
              )}
            {hips?.length !== undefined &&
              hips?.length > 0 &&
              hips[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {hips[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${hips[0].value} см`}
                  </Text>
                </View>
              )}

            {biceps?.length !== undefined &&
              biceps?.length > 0 &&
              biceps[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {biceps[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${biceps[0].value} см`}
                  </Text>
                </View>
              )}
          </View>
          <View style={styles.workoutDataBlock}>
            {calories?.length !== undefined &&
              calories?.length > 0 &&
              calories[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {calories[0].metric.name}
                  </Text>
                  <Text
                    style={styles.workoutDataParam}
                  >{`${calories[0].value} ккал`}</Text>
                </View>
              )}
            {waist?.length !== undefined &&
              waist?.length > 0 &&
              waist[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {waist[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${waist[0].value} см`}
                  </Text>
                </View>
              )}
            {hip?.length !== undefined &&
              hip?.length > 0 &&
              hip[0].value !== 0 && (
                <View style={styles.workoutDataRow}>
                  <Text style={styles.workoutDataTitle}>
                    {hip[0].metric.name}
                  </Text>
                  <Text style={styles.workoutDataParam}>
                    {`${hip[0].value} см`}
                  </Text>
                </View>
              )}
          </View>
        </View>
      )}
    </View>
  );
};

export default DailyMetrics;
