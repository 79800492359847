import { Text, View } from "react-native";
import React from "react";

import styles from "./styles";

type Props = {
  isStarted: boolean;
  exerciseIndex: number;
  title: string;
};

const TimelineTitle = ({ isStarted, exerciseIndex, title }: Props) => {

  return (
    <View
      style={styles.day}
      // onLayout={(event) => setHeight(event.nativeEvent.layout.height)}
    >
      <Text style={[styles.dayTitle, isStarted ? { color: "#8E839C" } : {}]}>
        {/*Упражнение {exerciseIndex + 1}*/}
        { title }
      </Text>
      <View
        style={[
          styles.dayCircle,
          isStarted ? { backgroundColor: "#9CF444" } : {},
        ]}
      />
      <View
        style={[
          styles.dayTimelineBottom,
          isStarted ? { backgroundColor: "#9CF444" } : {},
        ]}
      />
      {exerciseIndex !== 0 && (
        <View
          style={[
            styles.dayTimelineTop,
            isStarted ? { backgroundColor: "#9CF444" } : {},
          ]}
        />
      )}
    </View>
  );
};

export default TimelineTitle;
