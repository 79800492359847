import { combineReducers } from "redux";

import calendarReducer from "./calendar";
import workoutReducer from "./workout";
import currentTrainingReducerReducer from "./currentTraining";
import workoutTestReducer from "./test";
import exerciseDayReducer from "./exercisesByProgramDayId";
import calendarTextReducer from "./calendarText";
import locationsReducer from "./locations";

const adsReducer = combineReducers({
  calendar: calendarReducer,
  workout: workoutReducer,
  currentTraining: currentTrainingReducerReducer,
  test: workoutTestReducer,
  exerciseDay: exerciseDayReducer,
  calendarText: calendarTextReducer,
  locations: locationsReducer,
});

export default adsReducer;
