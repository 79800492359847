import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ScrollView,
  View,
  Text,
  FlatList,
  TouchableOpacity,
  ListRenderItem,
  Platform,
} from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useDispatch, useSelector } from "react-redux";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import WorkoutDate from "../../components/WorkoutDate";
import {
  selectProgram,
  selectProgramError,
  selectProgramIsLoading,
  selectWorkoutData,
  selectWorkoutProgram,
} from "../../store/selectors/workout";
import {
  loadProgramRequest,
  workoutsProgramRequest,
} from "../../store/actions/workout";
import { selectProfile } from "../../store/selectors/profile";
import { changeProfileRequest } from "../../store/actions/profile";
import {
  SaveProgramType,
  WorkoutProgramType,
  WorkoutsType,
} from "../../types/workout";

import styles from "./styles";
import DropdownWithGoal from "../../components/DropdownWithGoal";
import WorkoutListItem from "../../components/WorkoutListItem";
import DropdownWithFilter, {
  defaultDropdownSelect,
} from "../../components/Dropdown/DropdownWithFilter";
import Button from "../../components/Button";
import useSaveProgram from "../../hooks/useSaveProgram";
import moment from "moment";

type NavProps = {
  route: RouteProp<AppParamsList, "WorkoutList">;
  navigation: StackNavigationProp<AppParamsList, "WorkoutList">;
};

const WorkoutListContainer = ({ navigation }: NavProps) => {
  const dispatch = useDispatch();
  const workoutProgramData = useSelector(selectWorkoutProgram);
  const data = useSelector(selectProgram);

  const profile = useSelector(selectProfile);

  const {
    deleteThenSaveProgram,
    deleteCurrentProgram,
    saveProgram,
    isLoading,
    error,
  } = useSaveProgram("");

  const [selected, setSelected] = useState<WorkoutsType | null>(
    defaultDropdownSelect
  );

  const handlePressStart = async () => {
    if (!workoutProgramData) return;
    const requestData: {
      data: SaveProgramType;
    } = {
      data: {
        location_id: workoutProgramData[0]?.location_id,
        program_id: workoutProgramData[0].id,
        date_begin: "",
        first_training_id: "",
      },
    };

    if (!workoutProgramData[0]?.date_begin) {
      requestData.data.date_begin = moment.utc().format("YYYY-MM-DD");
    }

    if (data) {
      requestData.data.first_training_id = data[0].id.toString();
    }

    if (profile?.location_id) {
      await deleteThenSaveProgram(requestData.data, "Main");
      return;
    }
    await saveProgram(requestData.data, "Main");
  };

  useEffect(() => {
    if (selected) {
      dispatch(workoutsProgramRequest(selected?.id));
    }
  }, [selected]);

  useEffect(() => {
    if (!workoutProgramData) return;
    dispatch(loadProgramRequest(workoutProgramData[0].id));
  }, [workoutProgramData]);

  const renderItem: ListRenderItem<WorkoutProgramType> = useCallback(
    ({ item, index }) => {
      return <WorkoutListItem item={item} index={index} />;
    },
    []
  );

  return (
    <ScrollView
      bounces={false}
      style={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <Header
        showBackButton={false}
        title="Программы"
        screenName="WorkoutList"
      />
      <View style={{ paddingHorizontal: 20 }}>
        <View style={styles.selectWorkout}>
          <DropdownWithFilter selected={selected} setSelected={setSelected} />
        </View>
        {selected?.id !== "" && (
          <View style={styles.workoutTypes}>
            <FlatList data={workoutProgramData} renderItem={renderItem} />
          </View>
        )}
      </View>
      <View style={styles.buttonBlock}>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          title="Выбрать локацию"
          onPress={handlePressStart}
        />
      </View>
    </ScrollView>
  );
};

export default WorkoutListContainer;
