import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { WorkoutTestType } from "../../../types/workout";

type WorkoutTestState = typeof initialState;

const initialState = {
  data: null as null | WorkoutTestType[],
  isLoading: false,
  error: "",
};

const workoutTestReducer = handleActions<WorkoutTestState, any>(
  {
    [actions.loadWorkoutTestRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadWorkoutTestSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadWorkoutTestSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadWorkoutTestFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadWorkoutTestFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default workoutTestReducer;
