export enum StatusCodes {
  success = 200,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  unprocessable = 422,
}

export type DefaultMeta = {
  onSuccess?(): void;
  onFailed?(): void;
};
