import { createAction } from "redux-actions";
import {
  ChangeProfileType,
  ProfileType,
  UserActivityType,
} from "../../types/profile";

const scope = "PROFILE";

export const loadProfileRequest = createAction(`${scope}/LOAD_REQUEST`);
export const loadProfileSuccess = createAction<Partial<ProfileType>>(
  `${scope}/LOAD_SUCCESS`
);
export const loadProfileFailure = createAction<string>(`${scope}/LOAD_FAILURE`);

export const changeProfileRequest = createAction<{
  data: Partial<ChangeProfileType>;
}>(`${scope}/CHANGE_PROFILE_REQUEST`);
export const changeProfileSuccess = createAction(
  `${scope}/CHANGE_PROFILE_SUCCESS`
);
export const changeProfileFailure = createAction<string>(
  `${scope}/CHANGE_PROFILE_FAILURE`
);

export const loadUserActivityRequest = createAction(
  `${scope}/LOAD_USER_ACTIVITY_REQUEST`
);
export const loadUserActivitySuccess = createAction<UserActivityType[]>(
  `${scope}/LOAD_USER_ACTIVITY_SUCCESS`
);
export const loadUserActivityFailure = createAction<string>(
  `${scope}/LOAD_USER_ACTIVITY_FAILURE`
);

export const downloadAvatarRequest = createAction<{ data: FormData }>(
  `${scope}/DOWNLOAD_AVATAR_REQUEST`
);
export const downloadAvatarSuccess = createAction<string>(
  `${scope}/DOWNLOAD_AVATAR_SUCCESS`
);
export const downloadAvatarFailure = createAction<string>(
  `${scope}/DOWNLOAD_AVATAR_FAILURE`
);
