import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: 45,
    backgroundColor: colors.white,
    borderRadius: 6,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.grayLight_1,
  },
  input: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 12,
    fontSize: fontSizes.small_2,
    fontFamily: "regular",
    color: colors.black,
  },
  center: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "flex-start",
    paddingHorizontal: 16,
  },
  placeholder: {
    fontSize: fontSizes.small_2,
    fontFamily: "regular",
    color: colors.grayText,
  },
  icon: {
    marginRight: 12,
  },
  errorBorder: {
    borderRadius: 6,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: colors.red,
  },
  errorContainer: {
    position: "absolute",
    top: "102%",
    left: 0,
    right: 0,
    alignItems: "center",
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
  },
});

export default styles;
