import { handleActions } from "redux-actions";

import * as actions from "../actions/workout";
import { ProgramType } from "../../types/workout";

type ProgramState = typeof initialState;

const initialState = {
  data: null as null | ProgramType[],
  isLoading: false,
  error: "",
};

const programReducer = handleActions<ProgramState, any>(
  {
    [actions.loadProgramRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadProgramSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadProgramSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadProgramFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadProgramFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default programReducer;
