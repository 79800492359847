import { StyleSheet } from "react-native";
import { colors } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: 'flex-end',
  },
});

export default styles;
