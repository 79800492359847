import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FlatList,
  ListRenderItem,
  View,
  Text,
  TouchableOpacity,
  InteractionManager,
  Modal,
  Pressable,
  Dimensions,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { WebView } from "react-native-webview";

import { navigate } from "../../navigation/actions";
import {
  selectCalendarText,
  selectMainCalendarData,
  selectMainCalendarLoading,
  selectProgram,
} from "../../store/selectors/workout";
import { MainCalendarItem } from "../../types/workout";
import { colors } from "../../assets/theme";
import { numberOf } from "../../helpers/word";
import Loader from "../../components/Loader";

import styles from "./styles";
import UserStat from "../../components/UserStat";
import Button from "../../components/Button";
import {
  loadCalendarTextRequest,
  loadMainCalendarRequest,
  loadProgramRequest,
} from "../../store/actions/workout";
import { useFocusEffect } from "@react-navigation/native";
import DailyMetrics from "../../components/DailyMetrics";
import {
  loadDaysRequest,
  loadUserMetricsRequest,
} from "../../store/actions/recomendations";
import {
  selectDaysData,
  selectSaveMetricsData,
  selectSaveMetricsLoading,
  selectUserMetricsLoading,
} from "../../store/selectors/recomendations";
import MetricsPopUp from "../../components/MetricsPopUp";
import { ScrollView } from "react-native-gesture-handler";

type CalendarType = { date: string };

const ListItem = ({ isActive, text }: { isActive: boolean; text: string }) => {
  return (
    <View style={styles.workoutList}>
      <View style={styles.workoutListItem}>
        <Text
          style={[
            styles.workoutListItemDot,
            isActive ? { color: colors.white } : null,
          ]}
        >
          •
        </Text>
        <Text
          style={[
            styles.workoutListItemText,
            isActive ? { color: colors.grayLight } : null,
          ]}
        >
          {text}
        </Text>
      </View>
    </View>
  );
};

const calendarScheme = {
  missed: {
    text: "пропущена",

    background: colors.grayLight,
    border: colors.grayLight_1,
    borderOpacity: 1,

    timeWrap: colors.red,
    time: colors.grayLight,

    isActive: false,
  },
  completed: {
    text: "завершена",

    background: colors.grayLight,
    border: colors.grayLight_1,
    borderOpacity: 1,

    timeWrap: colors.green,
    time: "#2A4E07",

    isActive: false,
  },
  next: {
    text: "",

    background: "#7133BF",
    border: colors.grayLight_1,
    borderOpacity: 0.3,

    timeWrap: colors.green,
    time: colors.purpleText,

    isActive: true,
  },
  default: {
    text: "",

    background: colors.grayLight,
    border: colors.grayLight_1,
    borderOpacity: 1,

    timeWrap: colors.grayLight_1,
    time: colors.grayDark_1,

    isActive: false,
  },
};

const CalendarItem = ({
  item,
  index,
  firstElementIndexWithStatusOneOrTwo,
  showInfoOnBottom,
  showModal,
}: {
  item: MainCalendarItem | CalendarType;
  index: number;
  firstElementIndexWithStatusOneOrTwo: number | null;
  showInfoOnBottom: boolean;
  showModal: Function;
}) => {
  const calendarText = useSelector(selectCalendarText);
  const dispatch = useDispatch();
  const [statusStyle, setStatusStyle] = useState({ opacity: 1 });
  const twoWeeksDays = useSelector(selectDaysData);
  const [today] = useState(moment.utc());

  const windowHeight = Dimensions.get("window").height;

  // const [date, setDate] = useState<moment.Moment | null>(null);
  // const [isActive, setIsActive] = useState(false);

  const date = moment.utc(item.date, "YYYY-MM-DD").locale("RU");

  const [scheme, setScheme] = useState(calendarScheme.default);
  // const [showInfo, setShowInfo] = useState(false);
  // const [showDate, setShowDate] = useState(true);
  // const [showInfoOnBottom, setShowInfoOnBottom] = useState(false);

  useEffect(() => {
    if ("status" in item) {
      if (item.status === 3 || item.status === 4) {
        // setShowDate(true);
        setScheme(calendarScheme.completed);
      } else if (
        // firstElementIndexWithStatusOneOrTwo === index &&
        today.isSame(date, "days")
      ) {
        // setShowDate(false);
        setScheme(calendarScheme.next);
      } else if (
        typeof firstElementIndexWithStatusOneOrTwo === "number" &&
        index < firstElementIndexWithStatusOneOrTwo
      ) {
        // setShowDate(true);
        setScheme(calendarScheme.missed);
      } else {
        // setShowDate(true);
        setScheme(calendarScheme.default);
      }
    }
  }, [firstElementIndexWithStatusOneOrTwo, index, item]);

  // useFocusEffect(
  //   useCallback(() => {
  //     dispatch(loadCalendarTextRequest({ date: item.date }));
  //   }, [])
  // );

  return (
    <>
      <View style={{ height: windowHeight - 80 }}>
        <View style={[statusStyle, { marginTop: 30 }]}>
          {today.isSame(date, "days") && (
            <Text style={styles.workoutDate}>Сегодня</Text>
          )}
          {/* {showInfo && <TodayInfo />}
        {showDate && (
          <Text style={styles.workoutDate}>{date?.format("D MMM")}</Text>
        )} */}
          <Text style={styles.workoutDate}>{date?.format("D MMMM")}</Text>
          {/* {calendarText?.get(item.date) && (
            <WebView
              originWhitelist={["*"]}
              source={{ html: calendarText?.get(item.date) }}
            />
          )} */}
          {"id" in item && <Text style={styles.title}>Тренировка</Text>}
        </View>
        {"id" in item && (
          <TouchableOpacity
            style={[styles.workoutContainer, statusStyle]}
            onPress={() => {
              if (item?.status > 2) {
                navigate("WorkoutDay", {
                  programDayId: item?.program_day_id,
                });
              } else {
                navigate("Quiz", {
                  programDayId: item?.program_day_id,
                });
              }
            }}
          >
            <View
              style={[
                styles.workout,
                // isActive ? { backgroundColor: colors.purple } : null,
                { backgroundColor: scheme.background },
              ]}
            >
              <View
                style={[
                  styles.workoutTitle,
                  // isActive ? { borderColor: "rgba(221,218,225, .3)" } : null,
                  { borderColor: scheme.border, opacity: scheme.borderOpacity },
                ]}
              >
                <Text
                  style={[
                    styles.workoutName,
                    scheme.isActive ? { color: colors.grayLight } : null,
                  ]}
                >
                  {/* Заголовок */}
                </Text>
                {!!scheme?.text && (
                  <View
                    style={[
                      styles.workoutTimeWrap,
                      { backgroundColor: scheme.timeWrap },
                    ]}
                  >
                    <Text style={[styles.workoutTime, { color: scheme.time }]}>
                      {/* {item.duration === 0 ? "<1" : item.duration} мин */}
                      {scheme.text}
                    </Text>
                  </View>
                )}
              </View>
              <ListItem
                isActive={scheme.isActive}
                text={`${item?.exercises?.length} ${numberOf(
                  item?.exercises?.length,
                  "упражнен",
                  ["ий", "ия", "ий"]
                )}`}
              />
              <ListItem
                isActive={scheme.isActive}
                // text={`${item?.exercises?.map((exercise, index, arr) => {
                //   return `${index !== 0 ? " " : ""}${exercise.exercise}`;
                // })}`}
                text={item?.exercises
                  ?.reduce(
                    (prev: string[], curr) => [...prev, curr.exercise],
                    []
                  )
                  ?.join(", ")}
              />
            </View>
          </TouchableOpacity>
        )}

        <View style={{ marginTop: 30, marginBottom: 24 }}>
          <DailyMetrics
            title="Измерения"
            btnHandler={() => showModal(item.date)}
            date={item.date}
          />
        </View>
      </View>
    </>
  );
};

type Props = {};

const Calendar = ({}: Props) => {
  const dispatch = useDispatch();
  const calendarData = useSelector(selectMainCalendarData);
  const isLoading = useSelector(selectMainCalendarLoading);
  const programData = useSelector(selectProgram);
  const [modalVisible, setModalVisible] = useState(false);
  const [dayDate, setdayDate] = useState<string>("");
  const [everyDayCalendar, setEveryDayCalendar] = useState<
    (CalendarType | MainCalendarItem)[] | null
  >(null);
  const saveMetricsLoading = useSelector(selectSaveMetricsData);
  const [initialIndex, setInitialIndex] = useState(0);

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const [today] = useState(moment.utc());

  useFocusEffect(
    useCallback(() => {
      getDatesArray();
    }, [])
  );

  const ref = React.useRef<FlatList | null>(null);

  const [
    firstElementIndexWithStatusOneOrTwo,
    setFirstElementIndexWithStatusOneOrTwo,
  ] = useState<number | null>(null);

  const showModal = (date: string) => {
    setdayDate(date);
    setModalVisible(!modalVisible);
  };

  // const setNewCalendarData = () => {
  //   const newDATA: (CalendarType | MainCalendarItem)[] = [];
  //   DATA?.forEach((e) => {
  //     const filtredCalenderData = calendarData?.filter(
  //       (i) => e.date === i.date
  //     );
  //     if (filtredCalenderData?.length && filtredCalenderData?.length > 0) {
  //       newDATA.push(filtredCalenderData[0]);
  //     } else newDATA.push(e);
  //   });
  //   setEveryDayCalendar(newDATA);
  //   const index = newDATA?.findIndex((elem) => {
  //     const date = moment.utc(elem.date);
  //     return date.isSame(today, "days");
  //   });
  //   console.log("index", index);
  //   setInitialIndex(index);
  // };

  const getDatesArray = () => {
    let startDate = moment();
    let endDate = moment();
    startDate = startDate.subtract(1, "month");
    endDate = endDate.add(1, "month");
    const datesArray = [];
    dispatch(
      loadMainCalendarRequest({
        params: {
          start_date: startDate.format("YYYY-MM-DD"),
          end_date: endDate.format("YYYY-MM-DD"),
        },
      })
    );
    dispatch(
      loadUserMetricsRequest({
        data: {
          date_begin: startDate.format("YYYY-MM-DD"),
          date_end: endDate.format("YYYY-MM-DD"),
        },
      })
    );
    let currentDate = moment(startDate);
    while (currentDate <= endDate) {
      datesArray.push({ date: moment(currentDate).format("YYYY-MM-DD") });
      currentDate = moment(currentDate).add(1, "days");
    }
    console.log("programData", programData);
    setEveryDayCalendar(datesArray);
    console.log("dateArray", datesArray);

    const index = datesArray?.findIndex((elem) => {
      const date = moment.utc(elem.date);
      return date.isSame(today, "days");
    });
    console.log("index", index);
    setInitialIndex(index);
  };

  //   let currentDate = moment(startDate);
  //   while (currentDate <= endDate) {
  //     datesArray.push({ date: moment(currentDate).format("YYYY-MM-DD") });
  //     currentDate = moment(currentDate).add(1, "days");
  //   }

  //   const newDATA: (CalendarType | MainCalendarItem)[] = [];
  //   datesArray?.forEach((e) => {
  //     const filtredCalenderData = calendarData?.filter(
  //       (i) => e.date === i.date
  //     );
  //     console.log("calendarData", calendarData);
  //     if (filtredCalenderData?.length && filtredCalenderData?.length > 0) {
  //       newDATA.push(filtredCalenderData[0]);
  //     } else newDATA.push(e);
  //   });
  //   console.log(newDATA);
  //   setEveryDayCalendar(newDATA);
  //   const index = newDATA?.findIndex((elem) => {
  //     const date = moment.utc(elem.date);
  //     return date.isSame(today, "days");
  //   });
  //   console.log("index", index);
  //   setInitialIndex(index);

  //   console.log(startDate.format("YYYY-MM-DD"));
  //   console.log(endDate.format("YYYY-MM-DD"));
  //   console.log("datesArray", datesArray);
  // };

  const renderItem: ListRenderItem<MainCalendarItem | CalendarType> = ({
    item,
    index,
  }) => {
    return (
      <CalendarItem
        item={item}
        index={index}
        firstElementIndexWithStatusOneOrTwo={
          firstElementIndexWithStatusOneOrTwo
        }
        showInfoOnBottom={
          firstElementIndexWithStatusOneOrTwo === calendarData?.length &&
          firstElementIndexWithStatusOneOrTwo - 1 === index
        }
        showModal={showModal}
      />
    );
  };

  useFocusEffect(
    useCallback(() => {
      InteractionManager.runAfterInteractions(() => {
        ref.current?.scrollToIndex({
          animated: true,
          index: initialIndex,
        });
      });
    }, [initialIndex])
  );

  // useEffect(() => {
  //   // getDatesArray();
  //   if (calendarData) {
  //     const index = calendarData?.findIndex((calendar) => {
  //       const status = calendar.status === 1 || calendar.status === 2;

  //       const isBefore = moment
  //         .utc(calendar?.date, "YYYY-MM-DD")
  //         .isBefore(today, "days");

  //       return status && !isBefore;
  //     });

  //     if (index >= 0) {
  //       setFirstElementIndexWithStatusOneOrTwo(index);
  //     } else {
  //       setFirstElementIndexWithStatusOneOrTwo(calendarData?.length);
  //     }
  //   } else {
  //     setFirstElementIndexWithStatusOneOrTwo(-1);
  //   }
  // }, [calendarData]);

  // useEffect(() => {
  //   if (
  //     ref?.current &&
  //     calendarData &&
  //     firstElementIndexWithStatusOneOrTwo &&
  //     firstElementIndexWithStatusOneOrTwo > 0 &&
  //     firstElementIndexWithStatusOneOrTwo < calendarData?.length &&
  //     !isLoading
  //   ) {
  //     const isToday =
  //       calendarData[firstElementIndexWithStatusOneOrTwo].date ===
  //       today.format("YYYY-MM-DD");

  //     InteractionManager.runAfterInteractions(() => {
  //       ref.current?.scrollToIndex({
  //         animated: false,
  //         index: firstElementIndexWithStatusOneOrTwo,
  //         viewOffset: isToday ? -50 : 0,
  //       });

  //       setTimeout(() => {
  //         ref.current?.scrollToIndex({
  //           animated: false,
  //           index: firstElementIndexWithStatusOneOrTwo,
  //           viewOffset: isToday ? -50 : 0,
  //         });
  //       }, 100);
  //     });
  //   }
  // }, [ref, calendarData, firstElementIndexWithStatusOneOrTwo, isLoading]);

  // useEffect(() => {
  //   const copyToday = moment.utc();
  //   const begin = copyToday.subtract(1, "month").format("YYYY-MM-DD");
  //   const end = copyToday.add(1, "month").format("YYYY-MM-DD");
  //   dispatch(
  //     loadUserMetricsRequest({ data: { date_begin: begin, date_end: end } })
  //   );
  // }, [saveMetricsLoading]);

  if (isLoading && !calendarData) {
    return (
      <View
        style={{
          width: windowWidth,
          height: windowHeight - 50,
          justifyContent: "center",
        }}
      >
        <Loader />;
      </View>
    );
  }

  return (
    <View style={{ width: windowWidth, height: windowHeight - 50 }}>
      <FlatList
        style={{ width: windowWidth, height: windowHeight - 80 }}
        ref={ref}
        getItemLayout={(data, index) => ({
          length: windowHeight - 80,
          offset: (windowHeight - 80) * index,
          index,
        })}
        initialNumToRender={everyDayCalendar?.length}
        data={everyDayCalendar}
        renderItem={renderItem}
        keyExtractor={(item) => String(item.date)}
        contentContainerStyle={{ paddingHorizontal: 20 }}
        pagingEnabled
      />
      <View style={{ marginBottom: 30, paddingTop: 10 }}>
        <Button
          title="Посмотреть список всех программ"
          whiteButton={true}
          onPress={() => navigate("WorkoutList")}
        />
        <Modal animationType="slide" transparent={true} visible={modalVisible}>
          <Pressable
            onPress={() => setModalVisible(false)}
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <Pressable
              onPress={(e) => {
                e.stopPropagation();
              }}
            >
              <MetricsPopUp
                date={dayDate}
                closePopUp={() => setModalVisible(false)}
              />
            </Pressable>
          </Pressable>
        </Modal>
      </View>
    </View>
  );
};

export default Calendar;
