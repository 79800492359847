import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { ProgramType } from "../../../types/workout";

type State = typeof initialState;

const initialState = {
  data: null as null | ProgramType,
  isLoading: false,
  error: "",
};

const exercisesByProgramDayIdReducer = handleActions<State, any>(
  {
    [actions.loadExercisesByProgramDayIdRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadExercisesByProgramDayIdSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadExercisesByProgramDayIdSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadExercisesByProgramDayIdFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadExercisesByProgramDayIdFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default exercisesByProgramDayIdReducer;
