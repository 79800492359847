import { handleActions } from "redux-actions";

import * as actions from "../actions/authentication";
import { SignInErrorsType } from "../../types/authentication";

type State = typeof initialState;

const initialState = {
  data: null,
  isLoading: false,
  error: null as string | null,
};

const signInReducer = handleActions<State, any>(
  {
    [actions.signInRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [actions.signInSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.signInSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.signInFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.signInFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default signInReducer;
