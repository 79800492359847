import { StyleSheet } from "react-native";
import {colors, fontSizes} from "../../../../assets/theme";

const styles = StyleSheet.create({
  container: {
    minHeight: 56, 
    height: 'fit-content'
  },
  separator: {
    width:'100%', 
    height:1, 
    backgroundColor: '#DDDAE1', 
    marginBottom: 11
  },
  mainWrapper: {
    flexDirection: 'row', 
    justifyContent: 'space-evenly', 
    width: '100%', 
    marginBottom: 12
  },
  inputAndPlusWrapper: {
    flexDirection: 'row'
  },
  plusWrapper: {
    width: 32, 
    height: 32, 
    alignItems: 'center', 
    justifyContent: 'center', 
    marginRight: 4
  },
  deleteIcon: {
    width:12, 
    height: 12, 
    borderRadius: 2, 
    backgroundColor: colors.grayDark, 
    position: 'absolute', 
    left: 46, 
    top: 2, 
    alignItems: 'center', 
    justifyContent: 'center'
  }
})

export default styles;