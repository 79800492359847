import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={16}
      height={9}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M8.53028 8.39429C8.23738 8.68718 7.76251 8.68718 7.46962 8.39429L1.10566 2.03033C0.812763 1.73744 0.812763 1.26256 1.10566 0.96967C1.39855 0.676777 1.87342 0.676777 2.16632 0.96967L7.99995 6.8033L13.8336 0.96967C14.1265 0.676777 14.6013 0.676777 14.8942 0.96967C15.1871 1.26256 15.1871 1.73744 14.8942 2.03033L8.53028 8.39429Z" fill="#D2CDD8" />
    </Svg>
  )
}

export default SvgComponent
