import React, { useCallback, useEffect } from "react";
import { AppParamsList } from "../../navigation";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { View, Text, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { navigate } from "../../navigation/actions";
import Header from "../../components/Header";
import Button from "../../components/Button";
import {
  loadCalendarTextRequest,
  loadMainCalendarRequest,
  loadProgramRequest,
} from "../../store/actions/workout";

import styles from "./styles";
import { colors } from "../../assets/theme";
import moment from "moment";
import Training from "./Training";

type NavProps = {
  route: RouteProp<AppParamsList, "Main">;
  navigation: StackNavigationProp<AppParamsList, "Main">;
};

const MainContainer = ({ navigation, route }: NavProps) => {
  const today = moment().locale("ru").format("D MMMM");
  const date = moment().format("DD-MM-YYYY");
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      dispatch(
        loadMainCalendarRequest({
          params: { start_date: today, end_date: today },
        })
      );
    });

    return unsubscribe;
  }, [navigation]);

  // useFocusEffect(
  //   useCallback(() => {
  //     dispatch(loadProgramRequest(1));
  //   }, [])
  // );

  useFocusEffect(
    useCallback(() => {
      console.log("date", date);
      dispatch(loadCalendarTextRequest({ date }));
    }, [])
  );

  return (
    <>
      <Header
        showBackButton={false}
        title="Сегодня"
        screenName="Main"
        isContainerFixedTop
        showBorderOnFixed={false}
        backgroundColor={colors.white}
      >
        <Text style={[styles.workoutDate, { paddingLeft: 20 }]}>{today}</Text>
      </Header>
      <ScrollView style={{ paddingTop: 50, paddingHorizontal: 20 }}>
        <Training />
        <Button
          title="Написать всем"
          onPress={() => navigation.push("Chat", { initChat: "converse" })}
        />

        <Button
          title="Написать тренеру"
          onPress={() => navigation.push("Chat", { initChat: "private" })}
        />
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Button
            title="Посмотреть список всех программ"
            whiteButton={true}
            onPress={() => navigate("WorkoutList")}
          />
        </View>
      </ScrollView>
    </>
  );
};

export default MainContainer;
