import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  takeLatest,
} from "redux-saga/effects";
import AsyncStorage from "@react-native-async-storage/async-storage";

import api from "../../api/instance";
import * as authApi from "../../api/authentication";
import axios from "axios";
import * as authActions from "../actions/authentication";

import { navigate } from "../../navigation/actions";
import {
  clearAuthentication,
  resetStore,
  setAuthentication,
} from "../actions/application";
import { loadProfileRequest, loadProfileSuccess } from "../actions/profile";
import { StatusCodes } from "../../types/api";

type signInResponse = SagaReturnType<typeof authApi.signIn>;

function* signIn({ payload }: ReturnType<typeof authActions.signInRequest>) {
  try {
    const response: signInResponse = yield call(authApi.signIn, payload.data);

    if (response.status === StatusCodes.success) {
      yield call(AsyncStorage.setItem, "token", response.data.api_token);

      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.api_token}`;

      yield put(
        setAuthentication({
          token: response.data.api_token,
          user: { id: response.data.id },
        })
      );
      yield put(loadProfileRequest());
      yield put(authActions.signInSuccess());
    } else {
      yield put(
        authActions.signInFailure(
          "Ошибка. Проверьте данные и попробуйте ещё раз"
        )
      );
    }
  } catch (err) {
    yield put(
      authActions.signInFailure("Ошибка. Проверьте данные и попробуйте ещё раз")
    );
  }
}

function* signOut() {
  try {
    // const token: string | null = yield call(AsyncStorage.getItem, 'token');

    // if (token) {
    yield call(AsyncStorage.removeItem, "token");
    yield call(AsyncStorage.removeItem, "user");
    yield put(clearAuthentication());
    yield put(resetStore());
    // navigate('SignIn');
    // }
  } catch (err) {
    console.warn(err);
    //const msg = 'Ошибка выхода';
    // yield call(errorHandler, msg, err, authActions.stopSignOut);
  }
}

type signUpResponse = SagaReturnType<typeof authApi.signUp>;

function* signUp({ payload }: ReturnType<typeof authActions.signUpRequest>) {
  try {
    const response: signUpResponse = yield call(authApi.signUp, payload.data);

    if (response.status === StatusCodes.success) {
      const token = response.data.user.api_token;
      yield call(AsyncStorage.setItem, "token", token);

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      yield put(
        setAuthentication({
          token,
          user: { id: response.data.user.id },
        })
      );

      yield put(loadProfileRequest());

      yield put(authActions.signUpSuccess());
    } else {
      yield put(
        authActions.signUpFailure(
          "Ошибка. Проверьте данные и попробуйте ещё раз"
        )
      );
    }
  } catch (err) {
    yield put(
      authActions.signUpFailure("Ошибка. Проверьте данные и попробуйте ещё раз")
    );
  }
}

type passwordRecoveryResponse = SagaReturnType<typeof authApi.passwordRecovery>;

function* passwordRecovery({
  payload,
}: ReturnType<typeof authActions.passwordRecoveryRequest>) {
  try {
    const response: passwordRecoveryResponse = yield call(
      authApi.passwordRecovery,
      { email: payload.email }
    );

    if (response.status === StatusCodes.success) {
      payload?.onSuccess && payload.onSuccess();
    } else {
    }
  } catch (err) {
    //const msg = 'Ошибка выхода';
    // yield call(errorHandler, msg, err, authActions.stopSignOut);
  }
}

export default function* () {
  yield all([takeLatest(authActions.signInRequest, signIn)]);
  yield all([takeLatest(authActions.signOutRequest, signOut)]);
  yield all([takeLatest(authActions.signUpRequest, signUp)]);
  yield all([
    takeLatest(authActions.passwordRecoveryRequest, passwordRecovery),
  ]);
}
