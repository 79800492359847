import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";
import * as ImagePicker from "expo-image-picker";
import { useDispatch, useSelector } from "react-redux";

import { downloadAvatarRequest } from "../../store/actions/profile";
import IconProfileBig from "../../assets/images/svg/IconProfileBig";
import {
  selectAvatar,
  selectAvatarUpdatingError,
  selectIsAvatarUpdating,
} from "../../store/selectors/profile";
import { colors, fontSizes } from "../../assets/theme";
import Button from "../Button";
import Loader from "../Loader";

const windowWidth = Dimensions.get("window").width;

type Props = {};

const Avatar = (props: Props) => {
  const [numberForResetCache, setNumberForResetCache] = useState(0);
  const dispatch = useDispatch();

  const avatar = useSelector(selectAvatar);
  const isLoading = useSelector(selectIsAvatarUpdating);
  const error = useSelector(selectAvatarUpdatingError);

  const handleImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 1,
      base64: true,
      allowsMultipleSelection: false,
    });

    if (result.cancelled) return;

    fetch(result.uri)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const img = new File([blob], "avatar.jpeg");
        formData.append("avatar", img);
        dispatch(
          downloadAvatarRequest({
            data: formData,
          })
        );
      });
  };

  useEffect(() => {
    if (!isLoading) {
      setNumberForResetCache(new Date().getTime());
    }
  }, [isLoading]);

  const renderImage = () => {
    if (isLoading) return <Loader />;

    if (avatar)
      return (
        <Image
          style={styles.profileInfoImage}
          source={{
            uri:
              avatar.length > 60
                ? `${avatar}?bust=${numberForResetCache}`
                : `http://brazgovsky.testbed.ru/storage/users/avatar/${avatar}?bust=${numberForResetCache}`,
          }}
        />
      );

    return <IconProfileBig />;
  };
  Platform.select;
  return (
    <View style={styles.profileInfoDownloadImage}>
      <View style={styles.profileInfoImageContainer}>{renderImage()}</View>
      <Button
        title={"Загрузить фотографию"}
        whiteButton={true}
        onPress={() => handleImage()}
      />
      <Text style={styles.error}>{error}</Text>
    </View>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  profileInfoDownloadImage: {
    alignItems: "center",
    marginBottom: 20,
  },
  profileInfoImageContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: colors.grayDark_2,
    marginBottom: 12,
  },
  profileInfoImage: {
    width: "100%",
    height: "100%",
    borderRadius: 40,
  },
  error: {
    position: "absolute",
    bottom: -20,

    maxWidth: windowWidth - 20,

    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",

    ...Platform.select({
      web: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      default: {},
    }),
  },
});
