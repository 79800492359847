import { Alert } from "react-native";
import { call, put, fork } from "redux-saga/effects";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  setAuthentication,
  startInitialization,
  stopInitialization,
} from "../actions/application";
import api from "../../api/instance";
import { ProfileType } from "../../types/profile";
import { loadProfileRequest } from "../actions/profile";

export default function* () {
  try {
    yield put(startInitialization());
    console.log("start initialization");
    const userJSON: string | null = yield call(AsyncStorage.getItem, "user");
    const token: string | null = yield call(AsyncStorage.getItem, "token");
    const user: ProfileType = userJSON && JSON.parse(userJSON);

    if (token && user) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      yield put(setAuthentication({ token, user: { id: user.id } }));
      yield put(loadProfileRequest());
    }

    yield put(stopInitialization());
  } catch (err) {
    yield put(stopInitialization());
    const msg = "Application initialization error";
    Alert.alert(msg);
    console.log(msg, err);
    yield call(AsyncStorage.removeItem, "user");
    yield call(AsyncStorage.removeItem, "token");
  }
}
