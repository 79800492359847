import React, { useEffect } from "react";
import { WorkoutsType } from "../../types/workout";
import { useDispatch, useSelector } from "react-redux";
import { selectWorkoutData } from "../../store/selectors/workout";
import { loadWorkoutsRequest } from "../../store/actions/workout";
import Dropdown from "../Dropdown";
import { changeProfileRequest } from "../../store/actions/profile";
import { selectProfile } from "../../store/selectors/profile";

type DropdownWithGoal = {
  selected: WorkoutsType | null;
  setSelected: React.Dispatch<React.SetStateAction<WorkoutsType | null>>;
};

const DropdownWithGoal = ({ selected, setSelected }: DropdownWithGoal) => {
  const profile = useSelector(selectProfile);
  const workoutData = useSelector(selectWorkoutData);
  const dispatch = useDispatch();

  // загружаем список для dropdown если не был загружен
  useEffect(() => {
    if (!workoutData) {
      dispatch(loadWorkoutsRequest());
    }
  }, [workoutData]);

  // смотрим какое сохранено для профиля и его выводим
  useEffect(() => {
    if (workoutData && profile?.group_id) {
      const data =
        workoutData.find((workout) => workout.id === profile?.group_id) || null;

      setSelected(data);
    }
  }, [workoutData, profile]);

  // сохраняем данные для профиля при изменении
  useEffect(() => {
    if (!selected) return;
    if (profile?.group_id === selected.id) return;
    if (typeof selected.id !== "number") return;

    dispatch(
      changeProfileRequest({
        data: {
          group_id: selected.id,
        },
      })
    );
  }, [selected]);

  return (
    <Dropdown
      data={workoutData}
      name="Цель тренировок"
      // data={workoutData}
      selected={selected}
      onSelect={(item) => setSelected(item)}
    />
  );
};

export default DropdownWithGoal;
