import { handleActions } from "redux-actions";
import { SaveMetricsResponse } from "../../../types/recomendations";

import * as actions from "../../actions/recomendations";

type State = typeof initialState;

const initialState = {
  data: null as null | SaveMetricsResponse[],
  isLoading: false,
  error: "",
};

const saveMetricsReducer = handleActions<State, any>(
  {
    [actions.saveMetricsRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.saveMetricsSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.saveMetricsSuccess>
    ) => ({
      ...state,
      isLoading: false,
      error: "",
      data: payload,
    }),
    [actions.saveMetricsFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.saveMetricsFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default saveMetricsReducer;
