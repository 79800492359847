import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  View,
  Text,
  StyleSheet,
} from "react-native";

import { loadExercisesByDay, loadProgram } from "../../api/workout";
import { colors, fontSizes } from "../../assets/theme";
import Timeline from "../../components/Timeline";
import { StatusCodes } from "../../types/api";
import { ProgramType } from "../../types/workout";

type Props = {
  id: number;
  day?: number;
  type: "workout" | "list";
};

const Workouts = ({ id, day = 0, type }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [program, setProgram] = useState<ProgramType[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let response;
        if (type === "list") {
          response = await loadProgram(id);
        } else {
          response = await loadExercisesByDay(day);
        }

        if (response.status === StatusCodes.success) {
          setProgram(
            Array.isArray(response.data) ? response.data : [response.data]
          );
        } else {
          setError("Ошибка. Не удалось загрузить");
        }
      } catch (error) {
        setError("Ошибка. Не удалось загрузить");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().catch((err) => console.log(err));
  }, [setProgram]);

  return (
    <>
      {isLoading ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 44,
            width: "100%",
          }}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <>
          {!!error ? (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: 44,
                width: "100%",
              }}
            >
              <Text style={styles.error}>{error}</Text>
            </View>
          ) : (
            <FlatList
              style={{ marginTop: 34, marginBottom: 50 }}
              // scrollEnabled={false}
              data={program}
              renderItem={({
                item,
                index,
              }: {
                item: ProgramType;
                index: number;
              }) => <Timeline item={item} programDatas={true} />}
            />
          )}
        </>
      )}
    </>
  );
};

export default Workouts;

const styles = StyleSheet.create({
  error: {
    fontSize: fontSizes.small_2,
    lineHeight: fontSizes.middle,
    color: colors.red,
    fontFamily: "light",
  },
});
