import { Dimensions, Platform, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-end",
    // paddingHorizontal: 20,
  },
  workoutBlock: {
    paddingBottom: 15,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.grayLight_1,
  },
  workoutName: {
    fontSize: fontSizes.regular_1,
    fontFamily: "bold",
    color: colors.black,
  },
  workoutTimeBlock: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 8,
  },
  workoutTime: {
    marginRight: 4,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 8,
    backgroundColor: colors.grayLight_1,
  },
  workoutTimeText: {
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.grayDark_1,
  },
  workoutDate: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 8,
    backgroundColor: colors.green,
  },
  workoutDateText: {
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.greenDark,
  },
  workoutDescription: {
    marginTop: 15,
  },
  workoutDescriptionText: {
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  //@ts-expect-error
  buttonBlock: {
    ...Platform.select({
      web: {
        position: "fixed",
      },
      default: {
        position: "absolute",
      },
    }),
    bottom: 0,
    width: "100%",
    paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
  error: {
    fontSize: fontSizes.small_2,
    lineHeight: fontSizes.middle,
    color: colors.red,
    fontFamily: "light",
  },
});

export default styles;
