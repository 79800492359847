import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  takeLatest,
} from "redux-saga/effects";

import * as api from "../../api/checkout";
import * as actions from "../actions/checkout";

import { StatusCodes } from "../../types/api";

type LoadChecoutTariffsResponse = SagaReturnType<
  typeof api.loadCheckoutTariffs
>;

function* loadCheckoutTariffs() {
  try {
    const response: LoadChecoutTariffsResponse = yield call(
      api.loadCheckoutTariffs
    );

    if (response.status === StatusCodes.success) {
      yield put(actions.loadCheckoutTariffsSuccess(response.data));
    } else {
      yield put(
        actions.loadCheckoutTariffsFailure(
          "Не удалось загрузить список тарифов"
        )
      );
    }
  } catch (err) {
    yield put(
      actions.loadCheckoutTariffsFailure("Не удалось загрузить список тарифов")
    );
  }
}

type CreateCheckoutTariffsResponse = SagaReturnType<
  typeof api.createCheckoutTinkof
>;

function* createCheckoutTinkof({
  payload,
  meta,
}: ReturnType<typeof actions.createCheckoutTinkofRequest>) {
  // if (meta.onSuccess)
  //   meta.onSuccess({ payment_url: "https://www.google.com/" });
  try {
    const response: CreateCheckoutTariffsResponse = yield call(
      api.createCheckoutTinkof,
      payload.data
    );

    if (response.status === StatusCodes.success) {
      if (meta?.onSuccess) meta?.onSuccess(response.data);
    } else {
      if (meta?.onFailed) meta.onFailed();
    }
  } catch (err) {
    if (meta?.onFailed) meta.onFailed();
  }
}

export default function* () {
  yield all([
    takeLatest(actions.loadCheckoutTariffsRequest, loadCheckoutTariffs),
    takeLatest(actions.createCheckoutTinkofRequest, createCheckoutTinkof),
  ]);
}
