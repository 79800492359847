import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={56}
      height={54}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M21.7637 53.3584C23.5599 53.3584 24.9424 52.6217 25.9111 51.1484L54.2471 7.3125C54.5902 6.78776 54.8424 6.2832 55.0039 5.79883C55.1654 5.31445 55.2461 4.83008 55.2461 4.3457C55.2461 3.15495 54.8525 2.17611 54.0654 1.40918C53.2783 0.642253 52.2692 0.258789 51.0381 0.258789C50.1904 0.258789 49.484 0.430339 48.9189 0.773438C48.3538 1.09635 47.8089 1.66146 47.2842 2.46875L21.6426 43.0957L8.50391 26.4453C7.55534 25.2546 6.38477 24.6592 4.99219 24.6592C3.76107 24.6592 2.74186 25.0527 1.93457 25.8398C1.12728 26.627 0.723633 27.626 0.723633 28.8369C0.723633 29.3818 0.814453 29.9066 0.996094 30.4111C1.19792 30.9157 1.52083 31.4303 1.96484 31.9551L17.6465 51.2695C18.7767 52.6621 20.1491 53.3584 21.7637 53.3584Z" fill="#F4F3F5" />
    </Svg>
  )
}

export default SvgComponent
