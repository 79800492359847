import {
  DaysResponse,
  GetMetricsParams,
  GetMetricsResponse,
  MetricsType,
  SaveMetricsParams,
  SaveMetricsResponse,
} from "../types/recomendations";
import api from "./instance";
import { API_PATHS } from "./paths";

export const loadRecomendations = () =>
  api.get<any>(`${API_PATHS.RECOMENDATIONS_LOAD}`);

export const saveMetrics = (params: SaveMetricsParams) =>
  api.post<SaveMetricsResponse[]>(`${API_PATHS.SAVE_METRICS}`, params);

export const loadMetricsList = () =>
  api.get<MetricsType[]>(`${API_PATHS.GET_METRICS_LIST}`);

export const loadDays = () => api.get<DaysResponse[]>(`${API_PATHS.GET_DAYS}`);

export const loadUserMetrics = (params: GetMetricsParams) =>
  api.get<GetMetricsResponse[][]>(`${API_PATHS.GET_USER_METRICS}`, { params });
