import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {

};

function SvgComponent({...props }: Props) {
  return (
    <Svg
      width="40"
      height="43"
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M4.50781 42.1406H35.9375C37.1875 42.1406 38.1641 41.8594 38.8672 41.2969C39.5859 40.75 39.9453 39.9922 39.9453 39.0234C39.9453 37.5234 39.4844 35.9375 38.5625 34.2656C37.6562 32.5938 36.3438 31.0312 34.625 29.5781C32.9219 28.1094 30.8516 26.9219 28.4141 26.0156C25.9922 25.0938 23.2578 24.6328 20.2109 24.6328C17.1797 24.6328 14.4453 25.0938 12.0078 26.0156C9.58594 26.9219 7.51562 28.1094 5.79688 29.5781C4.07812 31.0312 2.76562 32.5938 1.85938 34.2656C0.953125 35.9375 0.5 37.5234 0.5 39.0234C0.5 39.9922 0.851562 40.75 1.55469 41.2969C2.27344 41.8594 3.25781 42.1406 4.50781 42.1406ZM20.2344 20.4375C21.9062 20.4375 23.4531 19.9844 24.875 19.0781C26.2969 18.1562 27.4375 16.9141 28.2969 15.3516C29.1719 13.7891 29.6094 12.0312 29.6094 10.0781C29.6094 8.15625 29.1719 6.4375 28.2969 4.92188C27.4375 3.39062 26.2969 2.1875 24.875 1.3125C23.4531 0.4375 21.9062 0 20.2344 0C18.5625 0 17.0156 0.445312 15.5938 1.33594C14.1719 2.22656 13.0234 3.4375 12.1484 4.96875C11.2891 6.5 10.8594 8.21875 10.8594 10.125C10.8594 12.0625 11.2891 13.8125 12.1484 15.375C13.0234 16.9219 14.1719 18.1562 15.5938 19.0781C17.0156 19.9844 18.5625 20.4375 20.2344 20.4375Z" fill="#F4F3F5"
      />
    </Svg>
  );
}

export default SvgComponent;
