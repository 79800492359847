import { StyleSheet } from "react-native";
import React from "react";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  slideContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  scrollViewStyle: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBlock: {
    // paddingHorizontal: 20,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
    textAlign: "center",
    flex: 1,
  },
  quizContent: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",

    width: "100%",

    marginTop: 20,
  },
  quizDescr: {
    maxWidth: 343,
    marginHorizontal: "auto",

    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  quizCountBlock: {
    flex: 1,
    justifyContent: "center",
  },
  quizCount: {
    position: "relative",
    top: 15,
    alignItems: "center",
    // paddingRight: 20,
    fontSize: fontSizes.big,
    fontFamily: "black",
    zIndex: -1,
  },
  quizTitle: {
    maxWidth: 343,
    marginHorizontal: "auto",
    fontSize: fontSizes.middle,
    fontFamily: "semibold",
  },
  buttonBlock: {
    width: "100%",
    paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },

  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    // backgroundColor: '#FFFFFF',
  },
  scrollViewContainerStyle: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    // height: 600
  },
});

export default styles;
