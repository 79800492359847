import React, { useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import moment from "moment";

import { AppParamsList } from "../../navigation";
import Header from "../../components/Header";
import Button from "../../components/Button";
import WorkoutPlanner from "../../components/WorkoutPlanner";
import Container from "../../components/Container";
import { colors } from "../../assets/theme";
import IconCheck from "../../assets/images/svg/IconCheck";
import useUpdateExerciseData from "../../hooks/useUpdateExerciseData";
import { navigate } from "../../navigation/actions";
import Error from "../../components/Error";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "Planning">;
  navigation: StackNavigationProp<AppParamsList, "Planning">;
};

const PlanningContainer = ({ navigation, route }: NavProps) => {
  const { updateExercise, isLoading, error } = useUpdateExerciseData("");
  const { id, initDate, status } = route.params;
  const [recordDone, setRecordDone] = useState(false);
  // "YYYY-MM-DD"
  const [newDate, setNewDate] = useState("");

  const handlePressDone = () => {
    if (newDate) {
      updateExercise(id, newDate, 1, () => setRecordDone(true));
    } else {
      updateExercise(id, initDate, 4, () => setRecordDone(true));
    }
  };

  const handlePressCancel = () => {
    updateExercise(id, initDate, status, () => setRecordDone(false));
  };

  const handleChangeData = (date: string) => {
    setNewDate(date);
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header
          showBackButton={true}
          backButtonText={"Тренировка"}
          backgroundColor={colors.white}
        />
        {!recordDone ? (
          <View style={styles.contentBlock}>
            <View>
              <Text style={styles.planningTitle}>
                Перепланировать тренировку
              </Text>
              <WorkoutPlanner handleChangeData={handleChangeData} />
            </View>
          </View>
        ) : null}
        {recordDone ? (
          <View style={styles.contentBlock}>
            <View style={styles.completeDoneBlock}>
              <View style={styles.checkIcon}>
                <IconCheck />
              </View>
              {!!newDate ? (
                <Text style={{ ...styles.planningTitle, marginBottom: 0 }}>
                  Тренировка запланирована на{" "}
                  {moment
                    .utc(newDate, "YYYY-MM-DD")
                    .locale("ru")
                    .format("D MMM")}
                </Text>
              ) : (
                <Text style={{ ...styles.planningTitle, marginBottom: 0 }}>
                  Тренировка не запланирована
                </Text>
              )}
            </View>
            <Button
              title="Отменить"
              isSecondaryButton
              // style={{ backgroundColor: colors.grayDark }}
              // textStyle={{ color: colors.black }}
              onPress={handlePressCancel}
            />
          </View>
        ) : null}
      </ScrollView>
      <View style={styles.buttonBlock}>
        <Error error={error} />
        {recordDone ? (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            title="Вернуться на главную"
            onPress={() => navigate("Main")}
          />
        ) : (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            title={false ? "Запланировать" : "Сохранить"}
            onPress={handlePressDone}
          />
        )}
      </View>
    </Container>
  );
};

export default PlanningContainer;
