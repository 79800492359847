import {ScrollView, StyleSheet} from "react-native";
import React from "react";
import {colors, fontSizes} from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 20,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
  exerciseTitleBlock: {
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 20,
  },
  exerciseTitle: {
    marginBottom: 2,
    letterSpacing: '2%',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontSize: fontSizes.mini,
    fontFamily: 'bold',
    color: colors.grayText,
  },
  exerciseType: {
    fontSize: fontSizes.middle,
    fontFamily: 'semibold',
    color: colors.black,
  },
  exerciseCounts: {
    height: 187,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: 32,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: colors.grayLight_1,
    boxSizing: 'border-box',
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 95,
    height: 95,
    marginBottom: 8,
    borderRadius: '50%',
    backgroundColor: colors.purple
  },
  circleText: {
    fontSize: fontSizes.middle_2,
    fontFamily: 'bold',
    color: colors.white,
  },
  circleDescr: {
    height: 35,
    textAlign: 'center',
    fontSize: fontSizes.small,
    fontFamily: 'regular',
    color: colors.grayDark_1,
  },
  form: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    marginBottom: 15,
  },
  field: {
    width: 106,
  },
  buttonBlock: {
    width: '100%',
    paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 20,

  },
});

export default styles;
