import * as React from "react";
import Svg, { SvgProps, Path, Rect } from "react-native-svg";

type Props = SvgProps & {
  color?: string;
};

function SvgComponent({ ...props }: Props) {
  return (
    <Svg
      width={24}
      height={24}
      fill='none'
      viewBox="0 0 24 24"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M11.7 3C11.2029 3 10.8 3.40294 10.8 3.9V10.8H3.9C3.40294 10.8 3 11.2029 3 11.7C3 12.1971 3.40294 12.6 3.9 12.6H10.8V19.5C10.8 19.9971 11.2029 20.4 11.7 20.4C12.1971 20.4 12.6 19.9971 12.6 19.5V12.6H19.5C19.9971 12.6 20.4 12.1971 20.4 11.7C20.4 11.2029 19.9971 10.8 19.5 10.8H12.6V3.9C12.6 3.40294 12.1971 3 11.7 3Z"
        fill="#7133BF"
      />
    </Svg>
  );
}

export default SvgComponent;