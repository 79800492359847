import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {

};

function SvgComponent({...props }: Props) {
  return (
    <Svg
      width="30"
      height="30"
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M7.25391 25.5703H22.9688C23.5938 25.5703 24.082 25.4297 24.4336 25.1484C24.793 24.875 24.9727 24.4961 24.9727 24.0117C24.9727 23.2617 24.7422 22.4688 24.2812 21.6328C23.8281 20.7969 23.1719 20.0156 22.3125 19.2891C21.4609 18.5547 20.4258 17.9609 19.207 17.5078C17.9961 17.0469 16.6289 16.8164 15.1055 16.8164C13.5898 16.8164 12.2227 17.0469 11.0039 17.5078C9.79297 17.9609 8.75781 18.5547 7.89844 19.2891C7.03906 20.0156 6.38281 20.7969 5.92969 21.6328C5.47656 22.4688 5.25 23.2617 5.25 24.0117C5.25 24.4961 5.42578 24.875 5.77734 25.1484C6.13672 25.4297 6.62891 25.5703 7.25391 25.5703ZM15.1172 14.7188C15.9531 14.7188 16.7266 14.4922 17.4375 14.0391C18.1484 13.5781 18.7188 12.957 19.1484 12.1758C19.5859 11.3945 19.8047 10.5156 19.8047 9.53906C19.8047 8.57812 19.5859 7.71875 19.1484 6.96094C18.7188 6.19531 18.1484 5.59375 17.4375 5.15625C16.7266 4.71875 15.9531 4.5 15.1172 4.5C14.2812 4.5 13.5078 4.72266 12.7969 5.16797C12.0859 5.61328 11.5117 6.21875 11.0742 6.98438C10.6445 7.75 10.4297 8.60938 10.4297 9.5625C10.4297 10.5312 10.6445 11.4062 11.0742 12.1875C11.5117 12.9609 12.0859 13.5781 12.7969 14.0391C13.5078 14.4922 14.2812 14.7188 15.1172 14.7188Z" fill="#F4F3F5"
      />
    </Svg>
  );
}

export default SvgComponent;
