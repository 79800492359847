import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";

export type StartedTrainingInfoState = typeof initialState;

const initialState = {
  data: { programDayId: null as null | number },
};

const currentTrainingReducer = handleActions<StartedTrainingInfoState, any>(
  {
    [actions.saveStartedTrainingInfo.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.saveStartedTrainingInfo>
    ) => ({
      ...state,
      data: payload,
    }),
  },
  initialState
);

export default currentTrainingReducer;
