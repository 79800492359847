import { Dimensions, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  background: {
    flex: 1,
    // alignItems: "flex-start",
    paddingTop: 16,
    paddingHorizontal: 16,

    backgroundColor: "#F4F3F5",
  },
  // listContainer: {
  // },
  closeButton: {
    // position: "absolute",
    // top: 0,
    // right: 0,

    width: 36,
    height: 36,

    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",

    marginBottom: 16,
  },
  navigationButtonContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  navigationButton: {
    justifyContent: "center",
    height: 60,
    marginBottom: 4,
    paddingHorizontal: 12,
    borderRadius: 8,
  },
  navigationButtonText: {
    fontSize: fontSizes.regular_1,
    lineHeight: fontSizes.regular_1,
    color: colors.black,
    fontFamily: "bold",
  },
  logoutButtonContainer: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayLight_1,
  },
  logoutButton: {
    paddingHorizontal: 12,
    paddingVertical: 15,
    paddingBottom: 30,

    backgroundColor: "#F4F3F5",
  },
  unviewedCircle: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: colors.purple,
  },
  unviewedText: {
    fontSize: fontSizes.small,
    lineHeight: 16,
    fontWeight: '700',
    color: colors.white
  }
});

export default styles;
