import { createAction } from "redux-actions";

import {
  SignInErrorsType,
  SignInFormType,
  SignUpErrorsType,
  SignUpFormType,
} from "../../types/authentication";

const scope = "AUTHENTICATION";

export const signInRequest = createAction<{ data: SignInFormType }>(
  `${scope}/SIGN_IN_REQUEST`
);
export const signInSuccess = createAction(`${scope}/SIGN_IN_SUCCESS`);
export const signInFailure = createAction<string | null>(
  `${scope}/SIGN_IN_FAILURE`
);

export const signUpRequest = createAction<{ data: SignUpFormType }>(
  `${scope}/SIGN_UP_REQUEST`
);
export const signUpSuccess = createAction(`${scope}/SIGN_UP_SUCCESS`);
export const signUpFailure = createAction<string | null>(
  `${scope}/SIGN_UP_FAILURE`
);

export const signOutRequest = createAction(`${scope}/SIGN_OUT_REQUEST`);

export const passwordRecoveryRequest = createAction<{
  email: string;
  onSuccess?(): void;
}>(`${scope}/PASSWORD_RECOVERY_REQUEST`);
