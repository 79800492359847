import React, { useEffect, useState } from "react";
import { Linking, Platform, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { colors } from "../../assets/theme";
import Button from "../Button";
import { selectProfile } from "../../store/selectors/profile";
import PressableButton from "../Pressable";
import {
  selectCheckoutTariffs,
  selectCheckoutTariffsError,
  selectCheckoutTariffsIsLoading,
} from "../../store/selectors/checkout";
import Loader from "../Loader";
import {
  createCheckoutTinkofRequest,
  loadCheckoutTariffsRequest,
} from "../../store/actions/checkout";
import { CheckoutTinkof } from "../../types/checkout";
import Error from "../Error";

import styles from "./styles";

const SubsDetails = () => {
  const dispatch = useDispatch();
  const tariffs = useSelector(selectCheckoutTariffs);
  const isLoading = useSelector(selectCheckoutTariffsIsLoading);
  const error = useSelector(selectCheckoutTariffsError);
  const [onLoadCheckout, setOnLoadCheckout] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");

  const handlePress = (id: number) => {
    setOnLoadCheckout(true);
    setCheckoutError("");
    dispatch(
      createCheckoutTinkofRequest(
        {
          data: {
            tariff_id: id,
          },
        },
        {
          onSuccess: async (data: CheckoutTinkof) => {
            const canBeOpened = await Linking.canOpenURL(data.payment_url);

            setOnLoadCheckout(false);

            if (Platform.OS === "web" && canBeOpened) {
              window.location.href = data.payment_url;
            } else {
              setCheckoutError("Ошибка. Попробуйте ещё раз.");
            }
          },
          onFailed: () => {
            setOnLoadCheckout(false);
            setCheckoutError("Ошибка. Попробуйте ещё раз.");
          },
        }
      )
    );
  };

  useEffect(() => {
    if (!tariffs) {
      dispatch(loadCheckoutTariffsRequest());
    }
  }, []);

  if (error) {
    return (
      <View style={styles.subscriptionPeriodBlockWrap}>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{error}</Text>
        </View>
      </View>
    );
  }

  if (isLoading && !tariffs) {
    return (
      <View style={styles.subscriptionPeriodBlockWrap}>
        <Loader />
      </View>
    );
  }

  return (
    <>
      <View style={styles.subscriptionPeriodBlockWrap}>
        {tariffs &&
          tariffs.map((tariff) => {
            return (
              <View key={tariff.id} style={styles.subscriptionPeriodBlock}>
                <Text style={styles.subscriptionPeriodText}>{tariff.name}</Text>
                <PressableButton
                  disabled={onLoadCheckout}
                  style={styles.subscriptionButton}
                  onPress={() => handlePress(tariff.id)}
                >
                  {onLoadCheckout ? (
                    <Loader />
                  ) : (
                    <>
                      {!!tariff.price_old && (
                        <Text style={styles.subscriptionOldPrice}>
                          {tariff.price_old} ₽
                        </Text>
                      )}
                      <Text style={styles.subscriptionNewPrice}>
                        {tariff?.price} ₽
                      </Text>
                    </>
                  )}
                </PressableButton>
              </View>
            );
          })}
      </View>
      {!!checkoutError && (
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{checkoutError}</Text>
        </View>
      )}
    </>
  );
};

const Item = ({ text }: { text: string }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text
        style={[
          { paddingTop: 6, marginRight: 5, color: colors.white },
          { color: colors.grayDark_1 },
        ]}
      >
        •
      </Text>
      <Text style={[styles.subscriptionListItem, { color: colors.grayDark_1 }]}>
        {text}
      </Text>
    </View>
  );
};

const SubsStatus = ({ isActive = false }) => {
  if (!isActive) return null;

  return (
    <View
      style={[
        styles.subscriptionDateWrap,
        isActive ? {} : { backgroundColor: colors.grayDark },
      ]}
    >
      <Text
        style={[
          styles.subscriptionDate,
          isActive ? {} : { color: colors.grayDark_1 },
        ]}
      >
        Активна
      </Text>
    </View>
  );
};

const Subscription = ({ showDetailSubs = false }) => {
  const profile = useSelector(selectProfile);

  const isActive = !!profile?.subscriptionsData?.length;

  return (
    <View style={[styles.subscription, { backgroundColor: colors.grayLight }]}>
      <Text
        style={[
          styles.subscriptionType,
          { color: colors.grayDark, opacity: 0.5 },
        ]}
      >
        Basic
      </Text>
      <View style={styles.subscriptionTitle}>
        <Text style={[styles.subscriptionName, { color: colors.black }]}>
          Базовая
        </Text>
        <SubsStatus isActive={isActive} />
      </View>
      <View style={styles.subscriptionList}>
        <Item text="Стандартный набор тренировок." />
        <Item text="Общий чат с тренером." />
      </View>
      {showDetailSubs ? (
        <View>
          <SubsDetails />
          {isActive ? (
            <Text style={styles.subscriptionPeriodDescr}>
              После оплаты, дата окончания подписки увеличится.
            </Text>
          ) : null}
        </View>
      ) : null}
    </View>
  );
  // } else {
  //   return (
  //     <View style={styles.subscription}>
  //       <Text style={styles.subscriptionType}>Premium</Text>
  //       <View style={styles.subscriptionTitle}>
  //         <Text style={styles.subscriptionName}>Продвинутая</Text>
  //         <View style={styles.subscriptionDateWrap}>
  //           <Text style={styles.subscriptionDate}>До 13 мая</Text>
  //         </View>
  //       </View>
  //       <View style={styles.subscriptionList}>
  //         <View style={{flexDirection: 'row'}}>
  //           <Text style={{paddingTop: 6, marginRight: 5, color: colors.white}}>•</Text>
  //           <Text style={styles.subscriptionListItem}>Набор тренировок от тренера на основе ваших целей и измерений.</Text>
  //         </View>
  //         <View style={{flexDirection: 'row'}}>
  //           <Text style={{paddingTop: 6, marginRight: 5, color: colors.white}}>•</Text>
  //           <Text style={styles.subscriptionListItem}>Индивидуальный чат с тренером.</Text>
  //         </View>
  //       </View>
  //       {
  //         fullcard
  //         ? <View>
  //             <View style={styles.subscriptionPeriodBlockWrap}>
  //               <View style={styles.subscriptionPeriodBlock}>
  //                 <Text style={styles.subscriptionPeriodText}>На месяц</Text>
  //                 <View style={styles.subscriptionButton}>
  //                   <Text style={styles.subscriptionOldPrice}>590 ₽</Text>
  //                   <Text style={styles.subscriptionNewPrice}>390 ₽</Text>
  //                 </View>
  //               </View>
  //               <View style={styles.subscriptionPeriodBlock}>
  //                 <Text style={styles.subscriptionPeriodText}>На 3 месяца</Text>
  //                 <View style={styles.subscriptionButton}>
  //                   <Text style={styles.subscriptionOldPrice}>1770 ₽</Text>
  //                   <Text style={styles.subscriptionNewPrice}>990 ₽</Text>
  //                 </View>
  //               </View>
  //             </View>
  //             <Text style={styles.subscriptionPeriodDescr}>После оплаты, дата окончания подписки увеличится.</Text>
  //           </View>
  //         : null
  //       }
  //     </View>
  //   )
  // }
};

export default Subscription;
