import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg";

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={36}
    height={36}
    fill="none"
    // xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M8.322 8.322a1.35 1.35 0 0 0 0 1.91l7.319 7.318-7.319 7.319a1.35 1.35 0 0 0 1.91 1.909l7.318-7.319 7.319 7.319a1.35 1.35 0 0 0 1.909-1.91l-7.319-7.318 7.319-7.319a1.35 1.35 0 0 0-1.91-1.909l-7.318 7.319-7.319-7.319a1.35 1.35 0 0 0-1.909 0Z"
        fill="#1C0638"
        {...props}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h36v36H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
