import { Store } from "../reducers";
import { SignInErrorsType, SignUpErrorsType } from "../../types/authentication";

export const selectSignInData = (state: Store): null => state.signin.data;
export const selectSignInIsLoading = (state: Store): boolean =>
  state.signin.isLoading;
export const selectSignInError = (state: Store): string | null =>
  state.signin.error;

export const selectSignUpData = (state: Store): null => state.signup.data;
export const selectSignUpIsLoading = (state: Store): boolean =>
  state.signup.isLoading;
export const selectSignUpError = (state: Store): string | null =>
  state.signup.error;
