import React, { useCallback, useEffect, useState } from "react";
import { Alert, Linking, ScrollView, Text, View } from "react-native";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { AppParamsList } from "../../navigation";

import Header from "../../components/Header";
import Button from "../../components/Button";
import { colors } from "../../assets/theme";
import Container from "../../components/Container";
import WorkoutDate from "../../components/WorkoutDate";
import Loader from "../../components/Loader";
import Workouts from "./Workouts";

import { WorkoutStatus } from "../../types/workout";
import useUpdateExerciseData from "../../hooks/useUpdateExerciseData";

import { striptext } from "../../helpers/word";
import {
  selectExercisesByProgramDayIdData,
  selectExercisesByProgramDayIdError,
  selectExercisesByProgramDayIdIsLoading,
  selectProgram,
  selectWorkoutProgram,
} from "../../store/selectors/workout";
import {
  loadExercisesByProgramDayIdRequest,
  workoutsProgramRequest,
} from "../../store/actions/workout";
import useFindFirstUpcomingExercise from "../../hooks/useFindFirstUpcomingExercise";

import styles from "./styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { selectProfile } from "../../store/selectors/profile";
import { replace } from "../../navigation/actions";

type NavProps = {
  route: RouteProp<AppParamsList, "WorkoutDay">;
  navigation: StackNavigationProp<AppParamsList, "WorkoutDay">;
};

const WorkoutDay = ({ navigation, route }: NavProps) => {
  const exerciseHook = useUpdateExerciseData("");
  const dispatch = useDispatch();
  const isLoading = useSelector(selectExercisesByProgramDayIdIsLoading);
  const error = useSelector(selectExercisesByProgramDayIdError);
  const program = useSelector(selectExercisesByProgramDayIdData);
  const programData = useSelector(selectProgram);
  const profile = useSelector(selectProfile);
  const workoutProgramData = useSelector(selectWorkoutProgram);
  const [startedVideoExercises, setStartedVideoExercises] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { exercise, exerciseIndex } = useFindFirstUpcomingExercise();

  const isVideoActive =
    startedVideoExercises || (!program?.exercises.length && isActive);

  const { programDayId } = route?.params;

  useEffect(() => {
    dispatch(loadExercisesByProgramDayIdRequest({ id: programDayId }));
  }, [programDayId]);

  // useFocusEffect(
  //   useCallback(() => {
  //     dispatch(loadExercisesByProgramDayIdRequest({ id: programDayId }));
  //   }, [navigation, programDayId])
  // );
  const handlePress = async (url: string) => {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const isActive = await AsyncStorage.getItem("active");
      if (isActive === "true") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (profile?.location_id) {
      dispatch(workoutsProgramRequest(profile.location_id));
    }
  }, [profile?.location_id]);

  const handlePressStart = async () => {
    if (!program) return null;
    const isActive = await AsyncStorage.getItem("active");
    if (isActive !== "true") {
      await AsyncStorage.setItem("active", "true");
    }
    if (!program.exercises.length) {
      setStartedVideoExercises(true);
      return;
    }

    // Обновляем статус о том что тренировка началась и переходим к первой не выполненной
    if (exercise)
      exerciseHook.updateExercise(
        program.calendar.id,
        moment.utc().format("YYYY-MM-DD"),
        program?.calendar?.status === WorkoutStatus.default
          ? WorkoutStatus.start
          : undefined,
        (data) => {
          navigation.navigate("Exercise", {
            programDayId,
            id: exercise?.id,
            additional: exercise?.is_additional,
          });
        }
      );
  };

  const handlePressFinish = async () => {
    if (programData && workoutProgramData) {
      const currentProgramId = await AsyncStorage.getItem("currentProgramId");
      await AsyncStorage.setItem("active", "false");
      const trainingIndex = programData?.findIndex(
        (e) => e.id === programDayId
      );

      if (trainingIndex + 1 === programData.length) {
        const programIndex: number = workoutProgramData.findIndex(
          (e) => e.id === Number(currentProgramId)
        );
        if (programIndex + 1 === workoutProgramData.length) {
          await AsyncStorage.setItem(
            "currentProgramId",
            workoutProgramData[0].id.toString()
          );
        } else {
          console.log("ddaaad");
          await AsyncStorage.setItem(
            "currentProgramId",
            workoutProgramData[programIndex + 1].id.toString()
          );
          await AsyncStorage.setItem("currentTrainingId", "false");
        }
      } else {
        await AsyncStorage.setItem(
          "currentTrainingId",
          programData[trainingIndex + 1].id.toString()
        );
      }
    }
    replace("Main");
  };

  return (
    <>
      <Container>
        <ScrollView
          bounces={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 130 }}
        >
          <Header backButtonFunc={() => navigation.navigate("Main")} />
          <View style={{ paddingHorizontal: 15 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <View style={styles.workoutBlock}>
                  <Text style={styles.workoutName}>
                    {program?.program.name}
                  </Text>
                  <View style={styles.workoutTimeBlock}>
                    <WorkoutDate
                      id={program?.id}
                      date_begin={program?.calendar?.date}
                    />
                    {/* <View style={styles.workoutTime}>
                          <Text style={styles.workoutTimeText}>{data?.date_begin}</Text>
                        </View>
                        <View style={styles.workoutDate}>
                          <Text style={styles.workoutDateText}>{data?.date_begin}</Text>
                        </View> */}
                  </View>
                </View>
                <View style={styles.workoutDescription}>
                  <Text style={styles.workoutDescriptionText}>
                    {striptext(program?.program.description)}
                  </Text>
                </View>
                {program?.exercises.length ? (
                  <Workouts program={program} error={error} />
                ) : (
                  <View>
                    <Text style={styles.calendarDate}>
                      {program?.description}
                    </Text>
                    {program?.link && (
                      <Button
                        title="Видео"
                        onPress={() => handlePress(program?.link)}
                      />
                    )}
                  </View>
                )}
              </>
            )}
          </View>
        </ScrollView>
      </Container>
      <View style={styles.buttonBlock}>
        {!isVideoActive && (
          <Button
            // disabled={!exercise}
            title={
              exerciseIndex && exerciseIndex > 0
                ? "Продолжить"
                : "Начать тренировку"
            }
            onPress={handlePressStart}
          />
        )}
        {isVideoActive && (
          <Button title="Закончить тренировку" onPress={handlePressFinish} />
        )}
        {/* <Button
          title={"Перепланировать"}
          style={{ backgroundColor: colors.grayDark }}
          textStyle={{ color: colors.black }}
          onPress={() => {
            if (program?.calendar) {
              navigation.navigate("Planning", {
                id: program?.calendar?.id,
                initDate: program?.calendar?.date,
                status: program?.calendar?.status,
              });
            }
          }}
          disabled={
            !exercise ||
            (!!program?.calendar?.status && program?.calendar?.status > 2)
          }
        /> */}
      </View>
    </>
  );
};

export default WorkoutDay;
