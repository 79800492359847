import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={8}
      height={7}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M3.17681 6.86686C3.43536 6.86686 3.64068 6.75026 3.79278 6.51705L7.26806 1.04176C7.31369 0.970788 7.35425 0.894743 7.38973 0.813627C7.43029 0.727442 7.45057 0.641257 7.45057 0.555072C7.45057 0.387772 7.3872 0.253424 7.26046 0.15203C7.13371 0.0506361 6.99176 -6.10352e-05 6.8346 -6.10352e-05C6.62167 -6.10352e-05 6.44423 0.114007 6.30228 0.342144L3.14639 5.40678L1.64829 3.46762C1.55703 3.34595 1.46578 3.26483 1.37452 3.22427C1.28834 3.17865 1.19202 3.15583 1.08555 3.15583C0.923321 3.15583 0.783904 3.21667 0.6673 3.33834C0.555767 3.45495 0.5 3.5969 0.5 3.7642C0.5 3.92136 0.558302 4.07852 0.674905 4.23568L2.53042 6.51705C2.62674 6.63872 2.7256 6.72744 2.827 6.78321C2.93346 6.83898 3.05006 6.86686 3.17681 6.86686Z" fill="white" />
    </Svg>
  )
}

export default SvgComponent
