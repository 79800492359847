import { createAction } from "redux-actions";
import {
  CalendarText,
  LoadCalendarParams,
  MainCalendarItem,
  ProgramType,
  SaveApproachParams,
  SaveProgramType,
  SaveTestType,
} from "../../types/workout";

const scope = "WORKOUTS";

export const loadWorkoutsRequest = createAction(
  `${scope}/LOAD_WORKOUTS_REQUEST`
);
export const loadWorkoutsSuccess = createAction(
  `${scope}/LOAD_WORKOUTS_SUCCESS`
);
export const loadWorkoutsFailure = createAction(
  `${scope}/LOAD_WORKOUTS_FAILURE`
);

export const loadLocationsRequest = createAction(
  `${scope}/LOAD_LOACATIONS_REQUEST`
);
export const loadLocationsSuccess = createAction(
  `${scope}/LOAD_LOACATIONS_SUCCESS`
);
export const loadLocationsFailure = createAction(
  `${scope}/LOAD_LOACATIONS_FAILURE`
);

export const workoutsProgramRequest = createAction(
  `${scope}/WORKOUT_PROGRAM_REQUEST`
);
export const workoutsProgramSuccess = createAction(
  `${scope}/WORKOUT_PROGRAM_SUCCESS`
);
export const workoutsProgramFailure = createAction(
  `${scope}/WORKOUT_PROGRAM_FAILURE`
);

export const loadProgramRequest = createAction(`${scope}/PROGRAM_REQUEST`);
export const loadProgramSuccess = createAction(`${scope}/PROGRAM_SUCCESS`);
export const loadProgramFailure = createAction(`${scope}/PROGRAM_FAILURE`);

export const exercisesRequest = createAction(`${scope}/EXERCISES_REQUEST`);
export const exercisesSuccess = createAction(`${scope}/EXERCISES_SUCCESS`);
export const exercisesFailure = createAction(`${scope}/EXERCISES_FAILURE`);

export const saveProgramRequest = createAction<{
  data: SaveProgramType;
}>(`${scope}/SAVE_PROGRAM_REQUEST`);

export const deleteProgramRequest = createAction(
  `${scope}/DELETE_PROGRAM_REQUEST`
);

export const saveApproachRequest = createAction<{
  data: SaveApproachParams;
  stopLoading(): void;
  func(): void;
  errorFunc(message: string): void;
}>(`${scope}/SAVE_APPROACH_REQUEST`);

export const loadMainCalendarRequest = createAction<{
  params: LoadCalendarParams;
}>(`${scope}/LOAD_MAIN_CALENDAR_REQUEST`);
export const loadMainCalendarSuccess = createAction<MainCalendarItem[]>(
  `${scope}/LOAD_MAIN_CALENDAR_SUCCESS`
);
export const loadMainCalendarFailure = createAction<string>(
  `${scope}/LOAD_MAIN_CALENDAR_FAILURE`
);

export const saveStartedTrainingInfo = createAction<{
  programDayId: number | null;
}>(`${scope}/SAVE_STARTED_TRAINING_INFO`);

export const loadWorkoutTestRequest = createAction(
  `${scope}/LOAD_WORKOUT_TEST_REQUEST`
);
export const loadWorkoutTestSuccess = createAction(
  `${scope}/LOAD_WORKOUT_TEST_SUCCESS`
);
export const loadWorkoutTestFailure = createAction(
  `${scope}/LOAD_WORKOUT_TEST_FAILURE`
);

export const saveTestRequest = createAction<{
  data: SaveTestType;
}>(`${scope}/SAVE_TEST_REQUEST`);

// загрузка программ дня по id дня
export const loadExercisesByProgramDayIdRequest = createAction<{
  id: number;
}>(`${scope}/LOAD_EXERCIES_BY_PROGRAM_DAY_ID_REQUEST`);
export const loadExercisesByProgramDayIdSuccess = createAction<ProgramType>(
  `${scope}/LOAD_EXERCIES_BY_PROGRAM_DAY_ID_SUCCESS`
);
export const loadExercisesByProgramDayIdFailure = createAction<string>(
  `${scope}/LOAD_EXERCIES_BY_PROGRAM_DAY_ID_FAILURE`
);

export const loadCalendarTextRequest = createAction<{
  date: string;
  type?: number;
}>(`${scope}/LOAD_CALENDAR_TEXT_REQUEST`);
export const loadCalendarTextSucces = createAction<CalendarText[]>(
  `${scope}/LOAD_CALENDAR_TEXT_SUCCES`
);
export const loadCalendarTextFailure = createAction<string>(
  `${scope}/LOAD_CALENDAR_TEXT_FAILURE`
);
