import { View, Text, Modal, Image, Dimensions } from "react-native";
import React, { useState } from "react";
import styles from "./styles";
import PressableButton from "../../../../components/Pressable";
import * as chatActions from "../../../../store/actions/chat";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "../../../../navigation/actions";
import {
  selectProfile,
} from "../../../../store/selectors/profile";
import IconComment from "../../../../assets/images/svg/IconComment";

import moment from "moment";
import { selectOpenThreadId } from "../../../../store/selectors/chat";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

type Props = {
  messageText: string;
  messageId: number;
  messageUserId: number;
  messageDate?: string;
  messageAttachments: any;
  isCommon?: boolean;
  userFirstName?: string;
  userLastName?: string;
  childs?: any[];
};

const MessageBody = ({
  messageText,
  messageId,
  messageUserId,
  messageDate,
  messageAttachments,
  isCommon,
  userFirstName,
  userLastName,
  childs,
}: Props) => {
  const dispath = useDispatch();
  const profile = useSelector(selectProfile);
  const [modalY, setModalY] = useState(0);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [imageUri, setImageUri] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const threadParentId = useSelector(selectOpenThreadId);
  const isOwn = profile?.id === messageUserId;

  const openModal = (e: any) => {
    setModalVisible(true);
    setModalY(e.nativeEvent.pageY);
  };

  const multiAttachmentsView = messageAttachments.map(
    (item: any, index: number, array: []) => {
      return (
        <PressableButton
          key={item.id}
          onPress={() =>
            navigate("FullScreenImage", {
              imageUri: item.file,
              isOwn: isOwn,
              attacmentId: item.id,
              allAttachments: array,
              startIndex: index
            })
          }
        >
          <Image
            key={item.id}
            source={{ uri: item.file }}
            style={[
              { width: windowWidth * 0.2, height: windowHeight * 0.1 },
              index === 0 && { borderTopLeftRadius: 6 },
              index === 1 && { borderTopRightRadius: 6 },
            ]}
          />
        </PressableButton>
      );
    }
  );

  const deleteMessage = (id: number) => {
    if (childs && childs?.length > 0) {
      setOpenDeleteConfirm(true);
      setModalVisible(false);
    } else {
      dispath(chatActions.deleteMessageRequest({ id: id.toString() }));
    }
  };
  const confirmDeleteMessage = (id: number) => {
      dispath(chatActions.deleteMessageRequest({ id: id.toString() }));
  };

  return (
    <View>
      <PressableButton
        onPress={() => setModalVisible(false)}
        hoverStyles={{ opacity: 1 }}
      >
        <Modal animationType="fade" transparent={true} visible={modalVisible}>
          <View
            style={[
              styles.modalContainer,
              { top: modalY - 74 },
              isOwn ? { right: 20 } : { left: 20 },
            ]}
          >
            {isCommon && !threadParentId && (
              <PressableButton
                style={{
                  borderRadius: 6,
                  paddingHorizontal: 8,
                  paddingVertical: 6,
                  marginBottom: 4,
                }}
                onPress={() => dispath(chatActions.openThread(messageId))}
                hoverStyles={{ backgroundColor: "#F4F3F5" }}
                pressedStyles={{ backgroundColor: "#F4F3F5" }}
              >
                <Text style={styles.modalText}>Открыть тред</Text>
              </PressableButton>
            )}
            {isOwn && (
              <PressableButton
                style={{
                  borderRadius: 6,
                  paddingHorizontal: 8,
                  paddingVertical: 6,
                }}
                onPress={() => deleteMessage(messageId)}
                hoverStyles={{ backgroundColor: "#F4F3F5" }}
                pressedStyles={{ backgroundColor: "#F4F3F5" }}
              >
                <Text style={styles.modalText}>Удалить</Text>
              </PressableButton>
            )}
          </View>
        </Modal>
      </PressableButton>
      <PressableButton
        style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        onPress={() => setOpenDeleteConfirm(false)}
        hoverStyles={{ opacity: 1 }}
      >
        <Modal
          animationType="fade"
          transparent={true}
          visible={openDeleteConfirm}
        >
          <View
            style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
          >
            <View
              style={{
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "90%",
                height: "30%",
                backgroundColor: "white",
                borderRadius: 8,
                opacity: 1
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  lineHeight: 26,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {childs?.length} ответа будут удалены вместе с сообщением
              </Text>
              <PressableButton
                style={{
                  borderRadius: 6,
                  paddingHorizontal: 12,
                  paddingVertical: 20,
                  backgroundColor: "#7133BF",
                  width: "90%",
                }}
                onPress={() => confirmDeleteMessage(messageId)}
              >
                <Text
                  style={{
                    fontSize: 16,
                    lineHeight: 20,
                    fontWeight: "700",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Удалить
                </Text>
              </PressableButton>
              <PressableButton onPress={() => setOpenDeleteConfirm(false)}>
                <Text
                  style={{
                    fontSize: 16,
                    lineHeight: 20,
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#7133BF",
                  }}
                >
                  Вернуться
                </Text>
              </PressableButton>
            </View>
          </View>
        </Modal>
      </PressableButton>
      {isOwn ? (
        <PressableButton
          style={styles.ownMessageBubble}
          onPress={(e) => openModal(e)}
        >
          {messageAttachments.length > 1 && (
            <View style={styles.multiAttachmentsContainer}>
              {multiAttachmentsView}
            </View>
          )}
          {messageAttachments.length === 1 && (
            <PressableButton
              onPress={() =>
                navigate("FullScreenImage", {
                  imageUri: messageAttachments[0].file,
                  isOwn: profile?.id === messageUserId,
                  attacmentId: messageAttachments[0].id,
                  allAttachments: messageAttachments,
                  startIndex: 0
                })
              }
            >
              <Image
                source={{ uri: messageAttachments[0].file }}
                style={styles.imageStyle}
              />
            </PressableButton>
          )}
          <View
            style={[
              styles.messageContainer,
              childs && childs.length > 0
                ? { flexDirection: "column" }
                : { flexDirection: "row" },
            ]}
          >
            <View
              style={{
                maxWidth: "80%",
              }}
            >
              <Text style={styles.messageText}>{messageText}</Text>
            </View>
            <View
              style={{ flexDirection: "row", justifyContent: "flex-start" }}
            >
              {childs && childs.length > 0 && (
                <View style={{ flexDirection: "row" }}>
                  <IconComment fill="#8E839C" />
                  <Text style={styles.commentCount}>{childs?.length}</Text>
                </View>
              )}
              <Text style={styles.messageDate}>
                {moment(messageDate).locale("ru").format("LT")}
              </Text>
            </View>
          </View>
          <View style={styles.rightArrow}></View>
          <View style={styles.rightArrowOverlap}></View>
        </PressableButton>
      ) : (
        <PressableButton
          style={styles.otherMessageBubble}
          onPress={(e) => openModal(e)}
          disabled={!isCommon || !!threadParentId}
        >
          {messageAttachments.length > 1 && (
            <View style={styles.multiAttachmentsContainer}>
              {multiAttachmentsView}
            </View>
          )}
          {messageAttachments.length === 1 && (
            <PressableButton
              onPress={() =>
                navigate("FullScreenImage", {
                  imageUri: messageAttachments[0].file,
                  allAttachments: messageAttachments,
                  isOwn: isOwn,
                  startIndex: 0
                })
              }
            >
              <Image
                source={{ uri: messageAttachments[0].file }}
                style={styles.imageStyle}
              />
            </PressableButton>
          )}
          <View>
            {isCommon && (
              <Text style={styles.userName}>
                {userFirstName} {userLastName?.slice(0, 1)}
                {"."}
              </Text>
            )}
            <View
              style={[
                styles.messageContainer,
                childs && childs.length > 0
                  ? { flexDirection: "column" }
                  : { flexDirection: "row" },
              ]}
            >
              <View style={{ maxWidth: "80%" }}>
                <Text style={styles.messageText}>{messageText}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                {childs && childs.length > 0 && (
                  <View style={{ flexDirection: "row" }}>
                    <IconComment />
                    <Text style={styles.commentCount}>{childs?.length}</Text>
                  </View>
                )}
                <Text style={styles.messageDate}>
                  {moment(messageDate).locale("ru").format("LT")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.leftArrow}></View>
          <View style={styles.leftArrowOverlap}></View>
        </PressableButton>
      )}
    </View>
  );
};

export default MessageBody;
