import React from "react";
import { AppParamsList } from '../../navigation';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import styles from './styles';

type NavProps = {
  route: RouteProp<AppParamsList, 'Articles'>;
  navigation: StackNavigationProp<AppParamsList, 'Articles'>;
};

const ArticlesContainer = ({navigation}: NavProps) => {
  return (
    <ArticlesScreen />
  )
}

const ArticlesScreen = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Articles</Text>
    </View>
  );
}

export default ArticlesContainer;
