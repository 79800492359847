import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { MainCalendarItem } from "../../../types/workout";

type State = typeof initialState;

const initialState = {
  data: null as null | MainCalendarItem[],
  isLoading: false,
  error: "",
};

const calendarReducer = handleActions<State, any>(
  {
    [actions.loadMainCalendarRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadMainCalendarSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMainCalendarSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadMainCalendarFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadMainCalendarFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default calendarReducer;
