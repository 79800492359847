import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {
  color?: string;
};

function SvgComponent({...props }: Props) {
  return (
    <Svg
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M4.91251 14.5688C5.03751 14.5688 5.15001 14.5354 5.25001 14.4688C5.35418 14.4063 5.48126 14.3063 5.63126 14.1688L7.88751 12.1063L12.1125 12.1125C12.7333 12.1125 13.2563 12.0021 13.6813 11.7813C14.1063 11.5563 14.4271 11.2313 14.6438 10.8063C14.8646 10.3813 14.975 9.86251 14.975 9.25001V4.46251C14.975 3.85001 14.8646 3.33126 14.6438 2.90626C14.4271 2.48126 14.1063 2.15834 13.6813 1.93751C13.2563 1.71251 12.7333 1.60001 12.1125 1.60001H4.06251C3.44168 1.60001 2.91876 1.71251 2.49376 1.93751C2.06876 2.15834 1.74585 2.48126 1.52501 2.90626C1.30835 3.33126 1.20001 3.85001 1.20001 4.46251V9.25001C1.20001 9.86251 1.30835 10.3813 1.52501 10.8063C1.74585 11.2313 2.06876 11.5542 2.49376 11.775C2.91876 11.9958 3.44168 12.1063 4.06251 12.1063H4.35626V13.925C4.35626 14.1208 4.40418 14.2771 4.50001 14.3938C4.59585 14.5104 4.73335 14.5688 4.91251 14.5688Z"
        fill="#DDDAE1"
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
