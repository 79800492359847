import React, { useEffect } from "react";
import { LocationsType, WorkoutsType } from "../../types/workout";
import { useDispatch, useSelector } from "react-redux";

import {
  selectLocationsData,
  selectWorkoutData,
} from "../../store/selectors/workout";
import {
  loadLocationsRequest,
  loadWorkoutsRequest,
} from "../../store/actions/workout";
import { changeProfileRequest } from "../../store/actions/profile";
import { selectProfile } from "../../store/selectors/profile";

import Dropdown, { DataProps } from "../Dropdown";

export const defaultDropdownSelect: DataProps = {
  name: "Выбор локации",
  id: "",
};

type DropdownWithGoal = {
  selected: any | null;
  setSelected: React.Dispatch<React.SetStateAction<WorkoutsType | null>>;
};

const DropdownWithGoal = ({ selected, setSelected }: DropdownWithGoal) => {
  const workoutData = useSelector(selectWorkoutData);
  const locationsData = useSelector(selectLocationsData);
  const dispatch = useDispatch();

  // загружаем список для dropdown если не был загружен
  useEffect(() => {
    if (!locationsData) {
      dispatch(loadLocationsRequest());
    }
  }, [locationsData]);

  return (
    <Dropdown
      data={!!locationsData ? locationsData : null}
      name="Локация тренировок"
      selected={selected}
      onSelect={(item) => setSelected(item)}
    />
  );
};

export default DropdownWithGoal;
