import React from "react";
import { Text, TouchableOpacity, View, StyleSheet } from "react-native";

import WorkoutDate from "../WorkoutDate";
import { WorkoutProgramType } from "../../types/workout";
import { navigate } from "../../navigation/actions";
import { colors, fontSizes } from "../../assets/theme";
import { striptext } from "../../helpers/word";

const WorkoutListItem = ({
  item,
  index,
}: {
  item: WorkoutProgramType;
  index: number;
}) => {
  const handlePressWorkout = (data: WorkoutProgramType, index: number) => {
    navigate("Workout", { id: data.id, location_id: data.location_id });
  };

  return (
    <TouchableOpacity onPress={() => handlePressWorkout(item, index)}>
      <View style={styles.workoutItem}>
        <View style={styles.workoutItemTitle}>
          <Text style={styles.workoutItemTitleText}>{item?.name}</Text>
          <WorkoutDate id={item?.id} date_begin={item?.date_begin} />
        </View>
        <Text style={styles.workoutItemDescr}>
          {striptext(item?.description)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default WorkoutListItem;

const styles = StyleSheet.create({
  workoutItem: {
    marginTop: 6,
    paddingVertical: 8,
    paddingHorizontal: 12,
    paddingBottom: 16,
    borderRadius: 8,
    backgroundColor: colors.grayLight,
  },
  workoutItemTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.grayLight_1,
  },
  workoutItemTitleText: {
    fontSize: fontSizes.small,
    fontFamily: "bold",
    color: colors.black,
  },
  workoutItemDescr: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
});
