import React from "react";
import { Text, TouchableOpacity, View, Image } from "react-native";
import { useSelector } from "react-redux";
import { baseURL } from "../../api/instance";

import IconProfile from "../../assets/images/svg/IconProfile";
import IconRight from "../../assets/images/svg/IconRight";
import { navigate } from "../../navigation/actions";
import { selectProfile } from "../../store/selectors/profile";

import styles from "./styles";

type Props = {};

const ProfileAvatarName = ({}: Props) => {
  const profile = useSelector(selectProfile);

  return (
    <TouchableOpacity
      style={styles.profileBlock}
      onPress={() => navigate("ProfileInfo")}
    >
      <View style={styles.profileData}>
        <View style={styles.profileImage}>
          {profile?.avatar ? (
            <Image
              style={{ width: "100%", height: "100%" }}
              source={{
                uri:
                  profile?.avatar.length > 60
                    ? `${profile?.avatar}?bust=${new Date().getTime()}`
                    : `${baseURL}/storage/users/avatar/${
                        profile?.avatar
                      }?bust=${new Date().getTime()}`,
              }}
            />
          ) : (
            <IconProfile />
          )}
        </View>
        <Text style={styles.profileName}>
          {profile?.first_name} {profile?.last_name}
        </Text>
      </View>
      <IconRight />
    </TouchableOpacity>
  );
};

export default ProfileAvatarName;
