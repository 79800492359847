import { Dimensions, Platform, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    // paddingTop: 68,
    paddingBottom: 72,
  },
  contentBlock: {
    paddingHorizontal: 20,
  },
  selectBlock: {
    marginVertical: 16,
    zIndex: 123,
  },
  selectDescr: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  // @ts-expect-error
  buttonBlock: {
    ...Platform.select({
      web: {
        position: "fixed",
      },
      default: {
        position: "absolute",
      },
    }),
    bottom: 0,
    // top: height - 80,
    left: 0,
    right: 0,

    width: "100%",
    paddingTop: 12,
    paddingBottom: 10,
    paddingHorizontal: 16,

    backgroundColor: colors.white,

    borderTopWidth: 1,
    borderTopColor: "#DDDAE1",
  },
  profileBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    marginTop: 12,
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: colors.grayLight,
    borderRadius: 8,
  },
  profileData: {
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    marginRight: 12,
    borderRadius: 20,
    backgroundColor: colors.grayDark_2,
    overflow: "hidden",
  },
  profileName: {
    fontSize: fontSizes.small,
    fontFamily: "semibold",
    color: colors.purple,
  },
  profileInfoTitle: {
    marginBottom: 20,
    fontSize: fontSizes.regular_1,
    fontFamily: "bold",
    color: colors.black,
  },
  profileInfoBlock: {
    marginBottom: 8,
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: colors.grayLight,
  },
  profileInfoBlockZ: {
    zIndex: 999,
  },
  profileInfoBlockTitle: {
    marginBottom: 4,
    fontSize: fontSizes.small,
    fontFamily: "bold",
    color: colors.black,
  },
  profileInfoDescr: {
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayDark_1,
  },
  form: {
    marginTop: 16,
  },
  formTopBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formMiddleBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formBottomBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputField: {
    maxWidth: "30%",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  field: {
    width: "100%",
  },
  errorContainer: {
    // position: "absolute",
    // top: -20,
    // left: 0,
    // right: 0,
    display: "flex",
    alignItems: "center",
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16,
    marginBottom: 20,
  },
  checkboxText: {
    marginLeft: 6,
    fontSize: fontSizes.small,
    fontFamily: "semibold",
    color: colors.black,
  },
  title: {
    marginBottom: 6,
    textTransform: "uppercase",
    fontSize: fontSizes.mini,
    fontFamily: "bold",
    color: colors.grayText,
  },
  recomendationData: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    backgroundColor: colors.grayLight,
    borderRadius: 8,
  },
  recomendationSlide: {
    marginTop: 24,
  },
});

export default styles;
