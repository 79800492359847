import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

type Props = SvgProps & {
  color?: string;
};

function SvgComponent({ color = "#7133BF", ...props }: Props) {
  return (
    <Svg
      width={7}
      height={12}
      fill="none"
      // xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M6.51533 5.57579C6.74965 5.81011 6.74965 6.19 6.51533 6.42432L1.42417 11.5155C1.18985 11.7498 0.809953 11.7498 0.575638 11.5155C0.341324 11.2812 0.341324 10.9013 0.575638 10.667L5.24254 6.00005L0.575638 1.33315C0.341323 1.09884 0.341323 0.718937 0.575638 0.484622C0.809952 0.250307 1.18985 0.250308 1.42417 0.484622L6.51533 5.57579Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
