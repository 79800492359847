import React, { useState } from "react";
import { Pressable, PressableProps, StyleProp, ViewStyle } from "react-native";

type Props = PressableProps & {
  style?: StyleProp<ViewStyle>;
  hoverStyles?: StyleProp<ViewStyle>;
  pressedStyles?: StyleProp<ViewStyle>;
  disabledStyles?: StyleProp<ViewStyle>;
  focusedStyles?: StyleProp<ViewStyle>;
  disabled?: boolean;
  children: React.ReactNode;
};

const PressableButton = ({
  style,
  hoverStyles = { opacity: 0.7 },
  pressedStyles = { opacity: 0.7 },
  disabledStyles,
  focusedStyles,
  disabled = false,
  children,
  ...props
}: Props) => {
  return (
    <Pressable
      disabled={disabled}
      {...props}
      style={(state) => [
        style,
        !disabled && state.focused && focusedStyles,
        !disabled && state.hovered && hoverStyles,
        !disabled && state.pressed && pressedStyles,
        disabled && disabledStyles,
      ]}
    >
      {children}
    </Pressable>
  );
};

export default PressableButton;
