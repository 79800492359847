import { handleActions } from "redux-actions";

import * as actions from "../actions/workout";
import { WorkoutProgramType } from "../../types/workout";

type WorkoutProgramState = typeof initialState;

const initialState = {
  data: null as null | WorkoutProgramType[],
  isLoading: false,
  error: "",
};

const workoutProgramReducer = handleActions<WorkoutProgramState, any>(
  {
    [actions.workoutsProgramRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.workoutsProgramSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.workoutsProgramSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.workoutsProgramFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.workoutsProgramFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default workoutProgramReducer;
