import { handleActions } from 'redux-actions';
import * as actions from '../../store/actions/application';
import { User } from '../../types/authentication';

type AppState = typeof initialState;

const initialState = {
  isAuthenticated: false,
  token: '',
  user: null as null | User,
  isLoading: false,
  // showGlobalLoader: false,
};

const appReducer = handleActions<AppState, any>(
  {
    [actions.setAuthentication.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.setAuthentication>
    ) => ({
      ...state,
      isAuthenticated: true,
      token: payload.token,
      user: payload.user,
    }),
    [actions.clearAuthentication.toString()]: (state) => ({
      ...state,
      isAuthenticated: false,
      token: '',
      user: null,
    }),
    [actions.startInitialization.toString()]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.stopInitialization.toString()]: (state) => ({
      ...state,
      isLoading: false,
    }),
    // [actions.showGlobalLoader.toString()]: (state) => ({
    //   ...state,
    //   showGlobalLoader: true,
    // }),
    // [actions.hideGlobalLoader.toString()]: (state) => ({
    //   ...state,
    //   showGlobalLoader: false,
    // }),
  },
  initialState
);

export default appReducer;
