import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateExerciseDayDateStatus } from "../api/workout";
import { saveStartedTrainingInfo } from "../store/actions/workout";
import { selectActiveTrainingData } from "../store/selectors/workout";
import { StatusCodes } from "../types/api";
import {
  UpdateExerciseRequest,
  UpdateExerciseResponseSuccess,
  WorkoutStatus,
} from "../types/workout";

type Props = {};

const useUpdateExerciseData = (props: Props) => {
  const dispatch = useDispatch();
  const { programDayId } = useSelector(selectActiveTrainingData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const updateExercise = async (
    id: number,
    date: string,
    status?: number,
    func?: (data: UpdateExerciseResponseSuccess) => void
  ) => {
    try {
      setIsLoading(true);

      const data: UpdateExerciseRequest = {
        id,
        data: {
          date,
        },
      };

      if (status) data.data.status = status;

      const response = await updateExerciseDayDateStatus(data);

      if (response.status === StatusCodes.success) {
        if (response.data.status === WorkoutStatus.start) {
          dispatch(
            saveStartedTrainingInfo({
              programDayId: response.data.program_day_id,
            })
          );
        } else if (
          (response.data.status === WorkoutStatus.default ||
            response.data.status === WorkoutStatus.end ||
            response.data.status === WorkoutStatus.skip) &&
          programDayId === response.data.program_day_id
        ) {
          dispatch(
            saveStartedTrainingInfo({
              programDayId: null,
            })
          );
        }

        if (func) func(response.data);
      } else {
        setError("Ошибка. Не получилось сохранить");
      }
    } catch (error) {
      setError("Ошибка. Не получилось сохранить");
    } finally {
      setIsLoading(false);
    }
  };

  return { updateExercise, isLoading, error };
};

export default useUpdateExerciseData;
