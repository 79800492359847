import { View, Text, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import { Slider } from "@miblanchard/react-native-slider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../assets/theme";
import styles from "./styles";
import ArrowDown from "./ArrowDown";

type Props = {
  recCal: number;
};

const FAT_CAL_STORAGE = "FAT_CAL_STORAGE";
const PROTEIN_CAL_STORAGE = "PROTEIN_CAL_STORAGE";

const CaloriesSlider = ({ recCal }: Props) => {
  const defaultProteinCal = Math.round(recCal * 0.23);
  const defaultFats = Math.round(recCal * 0.27);

  const [proteinCal, setProteinCal] = useState(0);
  const [fatCal, setFatCal] = useState(0);
  const [carbohydratesCal, setCarbohydratesCal] = useState(0);

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const getStorageValue = async () => {
    try {
      const fatStorage = await AsyncStorage.getItem(FAT_CAL_STORAGE);
      const proteinStorage = await AsyncStorage.getItem(PROTEIN_CAL_STORAGE);
      if (fatStorage === null || proteinStorage == null) {
        await AsyncStorage.setItem(
          FAT_CAL_STORAGE,
          Math.round(recCal * 0.27).toString()
        );
        await AsyncStorage.setItem(
          PROTEIN_CAL_STORAGE,
          Math.round(recCal * 0.23).toString()
        );
        setFatCal(Math.round(recCal * 0.27));
        setProteinCal(Math.round(recCal * 0.23));
        setCarbohydratesCal(
          recCal - Math.round(recCal * 0.27) - Math.round(recCal * 0.23)
        );
      } else {
        setFatCal(Number(fatStorage));
        setProteinCal(Number(proteinStorage));
        setCarbohydratesCal(
          recCal - Number(fatStorage) - Number(proteinStorage)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFatSlideComplete = async (value: number) => {
    await AsyncStorage.setItem(FAT_CAL_STORAGE, value.toString());
  };

  const onProteinSlideComplete = async (value: number) => {
    await AsyncStorage.setItem(PROTEIN_CAL_STORAGE, value.toString());
  };

  useEffect(() => {
    getStorageValue();
  }, []);

  return (
    <View style={{ paddingVertical: 20 }}>
      <Text style={styles.sliderTitle}>Рекомендуемые БЖУ</Text>
      <View style={{ paddingTop: 20 }}>
        <View style={styles.sliderTextContainer}>
          <Text style={styles.sliderSubTitle}>Белки:</Text>
          <View style={styles.caloriesBlock}>
            <Text style={styles.sliderCalText}>
              {proteinCal}Кк ({Math.round(proteinCal / 4)} грамм)
            </Text>
          </View>
        </View>
        <View>
          <Slider
            disabled
            containerStyle={{
              width: windowWidth - 40,
              marginHorizontal: 20,
              height: 2,
              alignSelf: "center",
              top: 4,
            }}
            minimumValue={0}
            maximumValue={recCal - fatCal}
            step={10}
            renderThumbComponent={(index) => (
              <ArrowDown
                color={defaultFats > fatCal ? colors.red : colors.purple}
              />
            )}
            maximumTrackTintColor={colors.white}
            minimumTrackTintColor={colors.white}
            value={defaultProteinCal}
          />
          <Slider
            containerStyle={{
              width: windowWidth - 40,
              marginHorizontal: 20,
              height: 40,
              alignSelf: "center",
            }}
            minimumValue={0}
            maximumValue={recCal - fatCal}
            step={10}
            thumbTintColor={colors.grayDark_2}
            minimumTrackTintColor={colors.purple}
            maximumTrackTintColor={colors.grayLight}
            value={proteinCal}
            onValueChange={(value) => {
              setProteinCal(value[0]);
              setCarbohydratesCal(recCal - value[0] - fatCal);
            }}
            onSlidingComplete={(value) => onProteinSlideComplete(value[0])}
          />
        </View>
      </View>
      <View>
        <View style={styles.sliderTextContainer}>
          <Text style={styles.sliderSubTitle}>Жиры:</Text>
          <View style={styles.caloriesBlock}>
            <Text style={styles.sliderCalText}>
              {fatCal}Кк ({Math.round(fatCal / 9)} грамм)
            </Text>
          </View>
        </View>
        <View>
          <Slider
            disabled
            containerStyle={{
              width: windowWidth - 40,
              marginHorizontal: 20,
              height: 2,
              alignSelf: "center",
              top: 4,
            }}
            minimumValue={1}
            maximumValue={recCal - proteinCal}
            step={10}
            renderThumbComponent={(index) => (
              <ArrowDown
                color={defaultFats > fatCal ? colors.red : colors.purple}
              />
            )}
            maximumTrackTintColor={colors.white}
            minimumTrackTintColor={colors.white}
            value={defaultFats}
          />
          <Slider
            containerStyle={{
              width: windowWidth - 40,
              marginHorizontal: 20,
              height: 40,
              alignSelf: "center",
            }}
            minimumValue={1}
            maximumValue={recCal - proteinCal}
            step={10}
            thumbTintColor={colors.grayDark_2}
            minimumTrackTintColor={colors.purple}
            maximumTrackTintColor={colors.grayLight}
            value={fatCal}
            onValueChange={(value) => {
              setFatCal(value[0]);
              setCarbohydratesCal(recCal - value[0] - proteinCal);
            }}
            onSlidingComplete={(value) => onFatSlideComplete(value[0])}
          />
        </View>
      </View>
      <View style={styles.sliderTextContainer}>
        <Text style={styles.sliderSubTitle}>Углеводы:</Text>
        <View style={styles.caloriesBlock}>
          <Text style={styles.sliderCalText}>
            {carbohydratesCal}Кк ({Math.round(carbohydratesCal / 4)} грамм)
          </Text>
        </View>
      </View>
    </View>
  );
};

export default CaloriesSlider;
