import {StyleSheet} from "react-native";
import {colors, fontSizes} from "../../assets/theme";

const styles = StyleSheet.create({
  contentBlock: {
    paddingHorizontal: 20,
    marginTop: 60,
  },
  title: {
    textAlign: 'center',
    fontSize: fontSizes.big,
    fontFamily: 'bold',
    color: colors.black,
  },
  conditions: {
    marginTop: 16,
    textAlign: 'center',
    fontSize: fontSizes.small,
    fontFamily: 'regular',
    color: colors.grayDark_1,
  },
  fieldWrap: {
    marginTop: 24,
  },
  completeDoneBlock: {
    marginTop: 60,
    marginBottom: 34,
    alignItems: 'center',
  },
  checkIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    marginBottom: 32,
    borderRadius: '60%',
    backgroundColor: colors.purple,
  },
  completeTextBlockTitle: {
    marginBottom: 16,
    textAlign: 'center',
    fontSize: fontSizes.middle,
    fontFamily: 'semibold',
    color: colors.black,
  },
  forgotPasswordBlock: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  forgotPasswordTitle: {
    fontSize: fontSizes.small,
    fontFamily: 'regular',
    color: colors.grayDark_1,
  },
  forgotPassword: {
    fontSize: fontSizes.small,
    fontFamily: 'semibold',
    color: colors.grayDark_1,
  },
  buttonBlock: {
    width: '100%',
    paddingTop: 11,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
});

export default styles;
