import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { LocationsType, WorkoutsType } from "../../../types/workout";

type LocationsState = typeof initialState;

const initialState = {
  data: null as null | WorkoutsType[],
  isLoading: false,
  error: "",
};

const locationsReducer = handleActions<LocationsState, any>(
  {
    [actions.loadLocationsRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadLocationsSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadLocationsSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadLocationsFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadLocationsFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default locationsReducer;
