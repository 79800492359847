import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Label from "../../components/Label";
import Field from "../../components/Field";
import Container from "../../components/Container";
import { AppParamsList } from "../../navigation";

import styles from "./styles";
import Button from "../../components/Button";
import {
  selectSaveMetricsError,
  selectSaveMetricsLoading,
  selectUserMetricsData,
} from "../../store/selectors/recomendations";
import { ChangeDailyMetrics } from "../../types/recomendations";
import { saveMetricsRequest } from "../../store/actions/recomendations";

type NavProps = {
  route: RouteProp<AppParamsList, "ProfileStats">;
  navigation: StackNavigationProp<AppParamsList, "ProfileStats">;
};

const schema = yup.lazy((value) => {
  if (value === "") {
    return yup.string();
  }

  return yup.number().positive(" ").typeError("Введите число").nullable(true);
});

const ProfileStatsSchema = yup
  .object({
    weight: schema,
    waist: schema,
    hips: schema,
    hip: schema,
    biceps: schema,
    calories: schema,
    bfp: schema,
  })
  .required();

const MetricsPopUp = ({
  date,
  closePopUp,
  isRecomendation,
}: {
  date: string;
  closePopUp: Function;
  isRecomendation?: boolean;
}) => {
  const dispatch = useDispatch();

  const updateError = useSelector(selectSaveMetricsError);
  const isUpdating = useSelector(selectSaveMetricsLoading);

  const usersMetrics = useSelector(selectUserMetricsData);
  const thisDayMetrics = usersMetrics?.filter((e) => e.date === date);
  const weight = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "weight"
  )[0];
  const waist = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "waist"
  )[0];
  const hips = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "hips"
  )[0];
  const hip = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "hip"
  )[0];
  const biceps = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "biceps"
  )[0];
  const calories = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "calories"
  )[0];
  const bfp = thisDayMetrics?.filter(
    (metric) => metric.metric.slug === "bfp"
  )[0];

  const [headerHeight, setHeaderHeight] = useState(68);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState,
  } = useForm<ChangeDailyMetrics>({
    resolver: yupResolver(ProfileStatsSchema),
    defaultValues: {
      weight: weight?.value || null,
      waist: waist?.value || null,
      hips: hips?.value || null,
      hip: hip?.value || null,
      biceps: biceps?.value || null,
      calories: calories?.value || null,
      bfp: bfp?.value || null,
    },
    mode: "all",
  });

  const onСhangeProfile = (data: ChangeDailyMetrics) => {
    let metrics = [];

    data.weight
      ? metrics.push({ metric_id: 2, value: data.weight, date: date })
      : null;
    data.waist
      ? metrics.push({ metric_id: 3, value: data.waist, date: date })
      : null;
    data.hips
      ? metrics.push({ metric_id: 4, value: data.hips, date: date })
      : null;
    data.hip
      ? metrics.push({ metric_id: 5, value: data.hip, date: date })
      : null;
    data.biceps
      ? metrics.push({ metric_id: 6, value: data.biceps, date: date })
      : null;
    data.calories
      ? metrics.push({ metric_id: 7, value: data.calories, date: date })
      : null;
    data.bfp
      ? metrics.push({ metric_id: 8, value: data.bfp, date: date })
      : null;

    const changedData = {
      metrics: metrics,
    };
    console.log(metrics);

    console.log("changedData", changedData);

    dispatch(saveMetricsRequest({ data: changedData }));
    closePopUp();
  };

  useEffect(() => {
    const defaultValue = {
      weight: weight?.value || null,
      waist: waist?.value || null,
      hips: hips?.value || null,
      hip: hip?.value || null,
      biceps: biceps?.value || null,
      calories: calories?.value || null,
      bfp: bfp?.value || null,
    };

    reset(defaultValue);
  }, [
    weight?.value,
    waist?.value,
    hips?.value,
    hip?.value,
    biceps?.value,
    calories?.value,
    bfp?.value,
  ]);

  return (
    <Container>
      <ScrollView
        bounces={false}
        contentContainerStyle={[styles.contentContainer]}
        // showsVerticalScrollIndicator={false}
      >
        <View style={styles.contentBlock}>
          <View style={styles.profileInfoBlock}>
            <Text style={styles.profileInfoTitle}>Измерения</Text>
            <View style={styles.form}>
              <View style={styles.formBottomBlock}>
                <View style={[styles.inputField, { maxWidth: "47%" }]}>
                  <Label> Вес, кг</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder=""
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={50}
                          keyboardType="numeric"
                        />
                      );
                    }}
                    name="weight"
                  />
                </View>
                <View style={[styles.inputField, { maxWidth: "47%" }]}>
                  <Label>Каллорий в день, ккал</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Field
                          // ref={countRef}
                          containerStyle={styles.field}
                          placeholder=""
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value ? String(value) : ""}
                          blurOnSubmit={false}
                          maxLength={50}
                          keyboardType="numeric"
                        />
                      );
                    }}
                    name="calories"
                  />
                </View>
              </View>
              {!isRecomendation && (
                <View style={styles.formTopBlock}>
                  <View style={styles.inputField}>
                    <Label>BFP, %</Label>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Field
                            // ref={countRef}
                            containerStyle={styles.field}
                            placeholder="Нет"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value ? String(value) : ""}
                            blurOnSubmit={false}
                            maxLength={5}
                            error={error?.message}
                            keyboardType="numeric"
                          />
                        );
                      }}
                      name="bfp"
                    />
                  </View>

                  <View style={styles.inputField}>
                    <Label>Талия, см</Label>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Field
                            // ref={countRef}
                            containerStyle={styles.field}
                            placeholder="Нет"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value ? String(value) : ""}
                            blurOnSubmit={false}
                            maxLength={5}
                            error={error?.message}
                            keyboardType="numeric"
                          />
                        );
                      }}
                      name="waist"
                    />
                  </View>

                  <View style={styles.inputField}>
                    <Label>Бёдра, см</Label>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Field
                            // ref={countRef}
                            containerStyle={styles.field}
                            placeholder="Нет"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value ? String(value) : ""}
                            blurOnSubmit={false}
                            maxLength={5}
                            error={error?.message}
                            keyboardType="numeric"
                          />
                        );
                      }}
                      name="hips"
                    />
                  </View>
                </View>
              )}
              {!isRecomendation && (
                <View style={styles.formMiddleBlock}>
                  <View style={styles.inputField}>
                    <Label>Одно бедро, см</Label>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Field
                            //  ref={countRef}
                            containerStyle={styles.field}
                            placeholder="Нет"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value ? String(value) : ""}
                            blurOnSubmit={false}
                            maxLength={5}
                            error={error?.message}
                            keyboardType="numeric"
                          />
                        );
                      }}
                      name="hip"
                    />
                  </View>
                  <View style={styles.inputField}>
                    <Label>Бицепс, см</Label>
                    <Controller
                      control={control}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Field
                            //  ref={countRef}
                            containerStyle={styles.field}
                            placeholder="Нет"
                            onChangeText={onChange}
                            onBlur={onBlur}
                            value={value ? String(value) : ""}
                            blurOnSubmit={false}
                            maxLength={5}
                            error={error?.message}
                            keyboardType="numeric"
                          />
                        );
                      }}
                      name="biceps"
                    />
                  </View>
                  <View style={{ width: "30%" }}></View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>

      {formState?.isDirty && (
        <View style={styles.buttonBlock}>
          {!!updateError && (
            <View style={styles.errorContainer}>
              <Text style={styles.error}>{updateError}</Text>
            </View>
          )}
          <Button
            title="Сохранить"
            onPress={handleSubmit(onСhangeProfile)}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </View>
      )}
    </Container>
  );
};

export default MetricsPopUp;
