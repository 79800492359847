import axios from "axios";

// const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const DEVELOPMENT_URL = "http://brazgovsky.testbed.ru";
// const PRODUCTION_URL = 'http://brazgovsky.testbed.ru';

// export const baseURL = isDev ? DEVELOPMENT_URL : PRODUCTION_URL;
export const baseURL = DEVELOPMENT_URL;
export const imageServerUrl = DEVELOPMENT_URL;

const axiosInstance = axios.create({
  baseURL,
  // withCredentials: true,
});

export default axiosInstance;
