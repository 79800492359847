import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from "redux-saga/effects";
import AsyncStorage from "@react-native-async-storage/async-storage";

import * as profileApi from "../../api/profile";
import * as profileActions from "../actions/profile";
import { StatusCodes } from "../../types/api";
import * as workoutActions from "../actions/workout";
import { capitalize } from "../../helpers/word";

type LoadProfileResponse = SagaReturnType<typeof profileApi.loadProfile>;

function* loadProfile() {
  try {
    const response: LoadProfileResponse = yield call(profileApi.loadProfile);

    if (response.status === StatusCodes.success) {
      yield put(profileActions.loadProfileSuccess(response.data));
      yield call(AsyncStorage.setItem, "user", JSON.stringify(response.data));
    } else {
      yield put(profileActions.loadProfileFailure(""));
    }
  } catch (err) {
    // console.log("err", err);
    yield put(profileActions.loadProfileFailure(""));
  }
}

type ChangeProfileResponse = SagaReturnType<typeof profileApi.changeProfile>;

function* changeProfile({
  payload,
}: ReturnType<typeof profileActions.changeProfileRequest>) {
  try {
    const response: ChangeProfileResponse = yield call(
      profileApi.changeProfile,
      payload.data
    );
    if (response.status === StatusCodes.success) {
      yield put(profileActions.loadProfileSuccess(response.data));
      yield put(profileActions.changeProfileSuccess());
    } else {
      yield put(
        profileActions.changeProfileFailure(
          "Ошибка. Не удалось сохранить данные."
        )
      );
    }
  } catch (err) {
    yield put(
      profileActions.changeProfileFailure(
        "Ошибка. Не удалось сохранить данные."
      )
    );
    // console.log("err", err);
  }
}

type LoadUserActivity = SagaReturnType<typeof profileApi.loadUserActivity>;

function* loadUserActivity() {
  try {
    const response: LoadUserActivity = yield call(profileApi.loadUserActivity);

    if (response.status === StatusCodes.success) {
      const activityData = Object.values(response.data);
      yield put(profileActions.loadUserActivitySuccess(activityData));
    } else {
      yield put(profileActions.loadUserActivityFailure(""));
    }
  } catch (err) {
    // console.log("err", err);
    yield put(profileActions.loadUserActivityFailure(""));
  }
}

type DownloadAvatarResponse = SagaReturnType<typeof profileApi.downloadAvatar>;

function* downloadAvatar({
  payload,
}: ReturnType<typeof profileActions.downloadAvatarRequest>) {
  try {
    const response: DownloadAvatarResponse = yield call(
      profileApi.downloadAvatar,
      payload.data
    );

    if (response.status === StatusCodes.success) {
      yield put(profileActions.downloadAvatarSuccess(response.data));
    } else {
      yield put(
        profileActions.downloadAvatarFailure(
          "Ошибка. Не удалось сохранить данные."
        )
      );
    }
  } catch (err) {
    yield put(
      profileActions.downloadAvatarFailure(
        "Ошибка. Не удалось сохранить данные."
      )
    );
    // console.log("err", err);
  }
}

export default function* () {
  yield all([takeLatest(profileActions.loadProfileRequest, loadProfile)]);
  yield all([takeLatest(profileActions.changeProfileRequest, changeProfile)]);
  yield all([
    takeLatest(profileActions.loadUserActivityRequest, loadUserActivity),
  ]);
  yield all([takeLatest(profileActions.downloadAvatarRequest, downloadAvatar)]);
}
