import { StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

const styles = StyleSheet.create({
  subscription: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    marginVertical: 4,
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 24,
    borderRadius: 8,
    backgroundColor: colors.purple,
  },
  subscriptionType: {
    position: "absolute",
    top: -19,
    right: -6,
    fontSize: fontSizes.big_1,
    fontFamily: "black",
    textTransform: "uppercase",
    color: colors.white,
    opacity: 0.08,
  },
  subscriptionTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subscriptionName: {
    fontSize: fontSizes.small,
    fontFamily: "bold",
    color: colors.grayLight,
  },
  subscriptionDateWrap: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 8,
    backgroundColor: colors.green,
  },
  subscriptionDate: {
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.purpleText,
  },
  subscriptionList: {},
  subscriptionListItem: {
    marginTop: 8,
    fontSize: fontSizes.small,
    fontFamily: "regular",
    color: colors.grayLight,
  },

  subscriptionPeriodBlockWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
    paddingTop: 16,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: colors.grayDark,
  },
  subscriptionPeriodBlock: {
    width: "48%",
  },
  subscriptionPeriodText: {
    marginBottom: 8,
    textAlign: "center",
    fontSize: fontSizes.small,
    fontFamily: "bold",
    // color: colors.white,
    color: "#1C0638",
  },
  subscriptionButton: {
    justifyContent: "center",
    alignItems: "center",

    height: 44,

    // backgroundColor: colors.white,
    backgroundColor: "#7133BF",
    borderRadius: 6,
  },
  subscriptionOldPrice: {
    textDecorationLine: "line-through",
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.grayText,
  },
  subscriptionNewPrice: {
    fontSize: fontSizes.regular,
    fontFamily: "black",
    // color: colors.purple,
    color: "white",
  },
  subscriptionPeriodDescr: {
    marginTop: 12,
    textAlign: "center",
    fontSize: fontSizes.mini,
    fontFamily: "regular",
    // color: colors.grayDark,
    color: "#5E536B",
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
  },
  errorContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

export default styles;
