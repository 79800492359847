import React from "react";
import { View, Text } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { AppParamsList } from "../../navigation";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "Nutrition">;
  navigation: StackNavigationProp<AppParamsList, "Nutrition">;
};

const NutritionContainer = ({ navigation }: NavProps) => {
  return <NutritionScreen />;
};

const NutritionScreen = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Nutrition</Text>
    </View>
  );
};

export default NutritionContainer;
