import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { colors, fontSizes } from "../../assets/theme";
import { selectProfile } from "../../store/selectors/profile";
import AsyncStorage from "@react-native-async-storage/async-storage";

type Props = {
  id?: number;
  date_begin?: string | null;
};

const WorkoutDate = ({ id, date_begin }: Props) => {
  const [isPlanned, setIsPlanned] = useState<boolean>(false);

  useEffect(() => {
    const getcurrentProgramId = async () => {
      const currentId = await AsyncStorage.getItem("currentProgramId");
      setIsPlanned(id === Number(currentId));
    };
    getcurrentProgramId();
  }, []);

  return (
    <View
      style={[
        styles.workoutItemTime,
        isPlanned ? { backgroundColor: colors.green } : null,
      ]}
    >
      <Text
        style={[
          styles.workoutItemTimeText,
          isPlanned ? { color: colors.greenDark } : null,
        ]}
      >
        {isPlanned ? "Выбрана" : date_begin ? date_begin : null}
      </Text>
    </View>
  );
};

export default WorkoutDate;

const styles = StyleSheet.create({
  workoutItemTime: {
    alignSelf: "center",

    marginRight: 4,
    paddingHorizontal: 8,
    paddingVertical: 2,

    borderRadius: 8,
    backgroundColor: colors.grayLight_1,
  },
  workoutItemTimeText: {
    fontSize: fontSizes.mini,
    fontFamily: "semibold",
    color: colors.grayDark_1,
  },
});
