import React, { useEffect, useLayoutEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View, Image } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Label from "../../components/Label";
import Field from "../../components/Field";
import { AppParamsList } from "../../navigation";
import {
  ChangeProfileForm,
  ChangeProfileFormInfo,
  UserGenderType,
} from "../../types/profile";
import {
  selectProfile,
  selectProfileUpdateError,
  selectProfileUpdateIsLoading,
} from "../../store/selectors/profile";
import Header from "../../components/Header";
import Container from "../../components/Container";
import CalendarField from "../../components/CalendarField/CalendarField.web";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DropdownWithGender from "../../components/DropdownWithGender";
import Avatar from "../../components/Avatar";

import styles from "./styles";
import { changeProfileRequest } from "../../store/actions/profile";
import ProfileCalendar from "../../components/ProfileCalendar";
import { passwordRecoveryRequest } from "../../store/actions/authentication";

type NavProps = {
  route: RouteProp<AppParamsList, "ProfileInfo">;
  navigation: StackNavigationProp<AppParamsList, "ProfileInfo">;
  position?: "top";
};

const phoneRegExp =
  /^(([\+][7]{1}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ProfileInfoSchema = yup
  .object({
    first_name: yup
      .string()
      .required("Имя не может быть пустым.")
      .trim()
      .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректное имя."),
    last_name: yup
      .string()
      .required("Фамилия не может быть пустой.")
      .trim()
      .matches(/^[А-Яа-яЁёaA-zZ\s]+$/, "Введите корректную фамилию."),
    email: yup
      .string()
      .required("Электронная почта не может быть пустой.")
      .trim()
      .email("Электронная почта не является действительным."),
    phone: yup.string().trim().nullable().matches(phoneRegExp, {
      message: "Phone number is not valid",
      excludeEmptyString: true,
    }),
  })
  .required();

const ProfileInfo = ({ navigation, route, position }: NavProps) => {
  const profile = useSelector(selectProfile);
  const [newDate, setNewDate] = useState("");
  const dispatch = useDispatch();

  const updateError = useSelector(selectProfileUpdateError);
  const isUpdating = useSelector(selectProfileUpdateIsLoading);
  // console.log(profile);

  const [headerHeight, setHeaderHeight] = useState(68);

  // useEffect(() => {
  //   dispatch(
  //     changeProfileRequest({
  //       data: {
  //         birthday: newDate,
  //       },
  //     })
  //   );
  // }, []);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState,
  } = useForm<ChangeProfileFormInfo>({
    resolver: yupResolver(ProfileInfoSchema),
    defaultValues: {
      first_name: profile?.first_name || "",
      last_name: profile?.last_name || "",
      phone: profile?.phone || null,
      email: profile?.email || "",
      gender: profile?.gender || null,
      birthday: profile?.birthday || null,
      receive_email_notifications: profile?.receive_email_notifications || 0,
    },
    mode: "all",
  });

  const handleChangeData = (date: string) => {
    setNewDate(date);
    console.log(date);
  };

  const onСhangeProfile = (data: ChangeProfileFormInfo) => {
    if (!profile) return;

    const changedData: any = {};

    let key: keyof ChangeProfileFormInfo;
    for (key in data) {
      if (data[key] !== profile[key]) {
        changedData[key] = data[key];
      }
    }

    dispatch(
      changeProfileRequest({
        data: changedData,
      })
    );
  };

  const changePassword = () => {
    if (!profile?.email) return;

    dispatch(
      passwordRecoveryRequest({
        email: profile?.email,
        onSuccess: () =>
          navigation.navigate("PasswordInstruction", {
            email: profile?.email,
          }),
      })
    );
  };

  useEffect(() => {
    const defaultValue = {
      // имя
      first_name: profile?.first_name || "",

      // фамилия
      last_name: profile?.last_name || "",

      // телефон
      phone: profile?.phone || null,

      // почта
      email: profile?.email || "",

      // пол
      gender: profile?.gender || null,

      // день рождения
      birthday: profile?.birthday || null,

      // получение писем на почту
      receive_email_notifications: profile?.receive_email_notifications || 0,
    };

    reset(defaultValue);
  }, [
    profile?.first_name,
    profile?.last_name,
    profile?.phone,
    profile?.email,
    profile?.gender,
    profile?.birthday,
    profile?.receive_email_notifications,
  ]);

  return (
    <Container>
      <Header
        onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
        showBackButton={true}
        backgroundColor="white"
        isContainerFixedTop
        screenName="ProfileInfo"
      />
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.contentContainer,
          { paddingTop: headerHeight },
        ]}
        // showsVerticalScrollIndicator={false}
      >
        <View style={styles.contentBlock}>
          <Text style={styles.profileInfoTitle}>Личные данные</Text>
          <Avatar />
          <View style={[styles.profileInfoBlock, styles.profileInfoBlockZ]}>
            <Text style={styles.profileInfoBlockTitle}>Информация о вас</Text>
            <View style={styles.form}>
              <View style={{ marginBottom: 15, width: "100%" }}>
                <Label>Имя</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        // ref={countRef}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="first_name"
                />
              </View>
              <View style={{ marginBottom: 15, width: "100%" }}>
                <Label>Фамилия</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        // ref={countRef}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="last_name"
                />
              </View>
              <View style={styles.formBottomBlock}>
                <View style={{ width: "47%", marginBottom: 0 }}>
                  <Label>Дата рождения</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <ProfileCalendar
                          value={value}
                          fieldStyle={{ marginTop: 0, marginBottom: 0 }}
                          onChange={onChange}
                        />
                      );
                    }}
                    name="birthday"
                  />
                </View>
                <View style={{ width: "47%", marginBottom: 0 }}>
                  <Label>Пол</Label>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <DropdownWithGender
                          onChange={onChange}
                          genderValue={value}
                        />
                      );
                    }}
                    name="gender"
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.profileInfoBlock}>
            <Text style={styles.profileInfoBlockTitle}>Контакты</Text>
            <View style={styles.form}>
              <View style={{ marginBottom: 15, width: "100%" }}>
                <Label>Телефон</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        // ref={countRef}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="phone"
                />
              </View>
              <View style={{ width: "100%" }}>
                <Label>Электронный адрес</Label>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field
                        // ref={countRef}
                        placeholder=""
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        blurOnSubmit={false}
                        maxLength={50}
                        error={error?.message}
                      />
                    );
                  }}
                  name="email"
                />
              </View>
            </View>
          </View>
          <View style={styles.profileInfoBlock}>
            <Text style={styles.profileInfoBlockTitle}>Аккаунт</Text>
            <View style={styles.checkboxContainer}>
              <Controller
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    value={value}
                    onValueChange={onChange}
                    // error={error?.message}
                  />
                )}
                name="receive_email_notifications"
              />
              <Text style={styles.checkboxText}>
                Получать уведомления на почту
              </Text>
            </View>
            <Button
              title={"Сменить пароль"}
              whiteButton={true}
              onPress={changePassword}
            />
          </View>
        </View>
      </ScrollView>
      {formState?.isDirty && (
        // updateError

        <View style={styles.buttonBlock}>
          {!!updateError && (
            <View style={styles.errorContainer}>
              <Text style={styles.error}>{updateError}</Text>
            </View>
          )}
          <Button
            title="Сохранить"
            onPress={handleSubmit(onСhangeProfile)}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </View>
      )}
    </Container>
  );
};

export default ProfileInfo;
