import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { changeProfileRequest } from "../../store/actions/profile";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile } from "../../store/selectors/profile";
import { UserGenderType } from "../../types/profile";

type DropdownWithGender = {
  // selected: UserGenderType | null;
  // setSelected: React.Dispatch<React.SetStateAction<UserGenderType | null>>;
  onChange(value: string): void;
  genderValue: string | null;
};

const DropdownWithGender = ({
  // selected,
  // setSelected,
  onChange,
  genderValue,
}: DropdownWithGender) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<UserGenderType | null>(null);

  const genderData = [
    { id: 1, name: "Мужской", gender: "male" },
    { id: 2, name: "Женский", gender: "female" },
  ];

  useEffect(() => {
    let defaultSelected = false;
    if (genderData && genderValue && !defaultSelected) {
      setSelected(
        genderData.find((item) => item.gender === genderValue) || null
      );
      defaultSelected = true;
    }
  }, [genderValue]);

  useEffect(() => {
    if (!selected) return;
    if (genderValue === selected.gender) return;

    onChange(selected.gender);
    // dispatch(
    //   changeProfileRequest({
    //     data: {
    //       gender: selected.gender,
    //     },
    //   })
    // );
  }, [selected]);

  return (
    <Dropdown
      data={genderData}
      name="Выберите пол"
      selected={selected}
      //@ts-expect-error
      onSelect={(item) => setSelected(item)}
      position="top"
    />
  );
};

export default DropdownWithGender;
