export const colors = {
  black: "#1C0638",
  white: "#FFFFFF",
  grayText: "#8E839C",
  purple: "#7133BF",
  purpleText: "#5B1CAB",
  grayLight: "#F4F3F5",
  grayLight_1: "#DDDAE1",
  grayDark: "#DDD7E3",
  grayDark_1: "#5E536B",
  grayDark_2: "#D2CDD8",
  green: "#9CF444",
  greenDark: "#2A4E07",
  red: "#E43A59",
};
