import React, { useState } from "react";
import { StatusCodes } from "../types/api";
import * as workoutApi from "../api/workout";
import { navigate, replace } from "../navigation/actions";
import { useDispatch } from "react-redux";
import { loadProfileSuccess } from "../store/actions/profile";
import axios from "axios";
import { SaveProgramType } from "../types/workout";
import AsyncStorage from "@react-native-async-storage/async-storage";

type Props = {};

const useSaveProgram = (props: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const saveDataToAsyncStorage = async (data: SaveProgramType) => {
    try {
      await AsyncStorage.setItem(
        "currentProgramId",
        data.program_id.toString()
      );
      await AsyncStorage.setItem("programDateBeging", data.date_begin);
      await AsyncStorage.setItem("currentTrainingId", data.first_training_id);
      await AsyncStorage.setItem("active", "false");
    } catch (e) {
      console.log("asyncStorageError", e);
    }
  };

  const deleteCurrentProgram = async () => {
    try {
      setIsLoading(true);

      const response = await workoutApi.deleteProgram();

      if (response.status === StatusCodes.success) {
      } else {
        setError("Ошибка. Не получилось сохранить");
      }
    } catch (error) {
      setError("Ошибка. Не получилось сохранить");
    } finally {
      setIsLoading(false);
    }
  };

  const saveProgram = async (
    data: SaveProgramType,
    redirect?: string,
    func?: () => void
  ) => {
    await saveDataToAsyncStorage(data);
    try {
      setIsLoading(true);

      const response = await workoutApi.saveProgram(data);

      if (response.status === StatusCodes.success) {
        dispatch(
          loadProfileSuccess({
            location_id: data.location_id,
            program_id: data.program_id,
          })
        );
        if (redirect) {
          replace(redirect);
        }
        if (func) {
          func();
        }
      } else {
        setError("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
      }
    } catch (error) {
      setError("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteThenSaveProgram = async (
    data: SaveProgramType,
    redirect?: string,
    func?: () => void
  ) => {
    await saveDataToAsyncStorage(data);
    try {
      setIsLoading(true);

      const response = await workoutApi.deleteProgram();

      if (response.status === StatusCodes.success) {
        await saveProgram(data, redirect, func);
      } else {
        setError("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === StatusCodes.unprocessable) {
          await saveProgram(data, redirect, func);
        }
      } else {
        setIsLoading(false);
        setError("Ошибка. Не удалось сохранить программу, попробуйте ещё раз");
      }
    }
  };

  return {
    deleteThenSaveProgram,
    deleteCurrentProgram,
    saveProgram,
    isLoading,
    error,
  };
};

export default useSaveProgram;
