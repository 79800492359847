import { Store } from "../reducers";
import { MessageBodyType, MessageCreateResponse } from "../../types/chat";

export const selectMessagesLoad = (state: Store): MessageBodyType[] | null =>
  state.chat.supportMessages;

export const selectMessagesCommonLoad = (state: Store): MessageBodyType[] | null =>
state.chat.commonMessages;

export const selectMessageIsCreate = (state: Store): boolean =>
  state.chat.isCreateSucces;

export const selectUnviewedCount = (state: Store): {common: number; all: number; support: number} | null =>
state.chat.unviewedCount;

export const selectViewsSave = (state: Store): boolean =>
state.chat.viewsSave;

export const selectMessageCreateResponse = (
  state: Store
): null | MessageCreateResponse => state.chat.createMessageResponse;

export const selectOpenThreadId = (
  state: Store
): null | number => state.chat.openThreadParentId;