import React, { useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import moment from "moment";

import { AppParamsList } from "../../navigation";
import Header from "../../components/Header";
import { colors } from "../../assets/theme";
import Button from "../../components/Button";
import IconCheck from "../../assets/images/svg/IconCheck";
import { navigate, replace } from "../../navigation/actions";
import Container from "../../components/Container";

import styles from "./styles";

type NavProps = {
  route: RouteProp<AppParamsList, "CompleteWorkout">;
  navigation: StackNavigationProp<AppParamsList, "CompleteWorkout">;
};

const CompleteWorkoutContainer = ({ navigation, route }: NavProps) => {
  const { data } = route.params;
  const [newDate, setNewDate] = useState("");
  const [recordDone, setRecordDone] = useState(false);

  const handlePressDone = () => {
    setRecordDone(true);
  };

  const handlePressCancel = () => {
    setRecordDone(false);
  };

  const handleChangeData = (date: string) => {
    setNewDate(date);
  };

  return (
    <Container backgroundColor={colors.grayLight}>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
        <Header
          showBackButton={true}
          backButtonText="Тренировка"
          backgroundColor={colors.white}
          backButtonFunc={() =>
            navigation.navigate("WorkoutDay", {
              programDayId: data.program_day_id,
            })
          }
        />
        {!recordDone ? (
          <View style={styles.contentBlock}>
            <View style={styles.completeTextBlock}>
              <Text style={styles.completeTextBlockTitle}>
                Тренировка окончена.{"\n"}Сегодня ты был на высоте!
              </Text>
              {/* <Text style={styles.completeTextBlockDescr}>
                Тренировка длилась{" "}
                {moment
                  .utc(data?.status_end_at)
                  .diff(moment.utc(data?.status_start_at), "minutes")}{" "}
                минут */}
              {/* ,{"\n"}ты сжёг 242 ккл */}
              {/* </Text> */}
            </View>
            {/* <View style={styles.completeRepeatBlock}>
              <Text style={styles.completeTextBlockDescr}>
                Повторить тренировку?
              </Text>
              <View style={styles.selectRepeatWorkout}>
                <WorkoutPlanner
                  handleChangeData={handleChangeData}
                  position="top"
                  type="program"
                />
              </View>
              <Button
                title="Запланировать"
                style={{ backgroundColor: colors.grayDark, marginTop: 12 }}
                textStyle={{ color: colors.black }}
                onPress={handlePressDone}
              />
            </View> */}
          </View>
        ) : null}
        {recordDone ? (
          <View style={styles.contentBlock}>
            <View style={styles.completeDoneBlock}>
              <View style={styles.checkIcon}>
                <IconCheck />
              </View>
              <Text
                style={{ ...styles.completeTextBlockTitle, marginBottom: 0 }}
              >
                Тренировка запланирована на 28 апреля
              </Text>
            </View>
            <Button
              title="Отменить"
              isSecondaryButton
              // style={{ backgroundColor: colors.grayDark }}
              // textStyle={{ color: colors.black }}
              onPress={handlePressCancel}
            />
          </View>
        ) : null}
      </ScrollView>
      <View style={styles.buttonBlock}>
        <Button title="Вернуться на главную" onPress={() => replace("Main")} />
      </View>
    </Container>
  );
};

export default CompleteWorkoutContainer;
