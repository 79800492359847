import { handleActions } from "redux-actions";

import * as actions from "../../actions/workout";
import { WorkoutsType } from "../../../types/workout";

type WorkoutState = typeof initialState;

const initialState = {
  data: null as null | WorkoutsType[],
  isLoading: false,
  error: "",
};

const workoutReducer = handleActions<WorkoutState, any>(
  {
    [actions.loadWorkoutsRequest.toString()]: (state) => ({
      ...state,
      isLoading: true,
      error: "",
    }),
    [actions.loadWorkoutsSuccess.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadWorkoutsSuccess>
    ) => ({
      ...state,
      isLoading: false,
      data: payload,
    }),
    [actions.loadWorkoutsFailure.toString()]: (
      state,
      { payload }: ReturnType<typeof actions.loadWorkoutsFailure>
    ) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  },
  initialState
);

export default workoutReducer;
