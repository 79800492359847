import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { colors, fontSizes } from "../../assets/theme";

type Props = {
  error: string;
};

const Error = ({ error }: Props) => {
  return (
    <View style={styles.errorContainer}>
      <Text style={styles.error}>{error}</Text>
    </View>
  );
};

export default Error;

const styles = StyleSheet.create({
  errorContainer: {
    position: "absolute",
    top: -20,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  error: {
    fontSize: fontSizes.mini,
    lineHeight: fontSizes.small,
    color: colors.red,
    fontFamily: "light",
  },
});
