export const fontSizes = {
  /**
   * 9
   */
  nano_2: 9,
  /**
   * 8
   */
  nano_1: 8,
  /**
   * 10
   */
  nano: 10,
  /**
   * 11
   */
  micro: 11,
  /**
   * 12
   */
  mini: 12,
  /**
   * 13
   */
  tiny: 13,
  /**
   * 14
   */
  small: 14,
  /**
   * 15
   */
  small_1: 15,
  /**
   * 16
   */
  small_2: 16,
  /**
   * 18
   */
  regular: 18,
  /**
   * 20
   */
  regular_1: 28,
  /**
   * 20
   */
  middle: 20,
  /**
   * 36
   */
  middle_2: 36,
  /**
   * 40
   */
  big: 40,
  big_1: 56,
};

// export const fontFamily = {
//     /**
//      * 300
//      */
//     light: 'SFProDisplay-Light', // 300
//     /**
//      * 400
//      */
//     regular: 'SFProDisplay-Regular', // 400
//     /**
//      * 600
//      */
//     semibold: 'SFProDisplay-Semibold', // 600
//     /**
//      * 700
//      */
//     bold: 'SFProDisplay-Bold', // 700
// };
