import { View, Text } from "react-native";
import React from "react";
import AdminMessageList from "./components/MessageList/AdminMessageList";
import { useSelector } from "react-redux";
import { selectProfile } from "../../store/selectors/profile";
import SupportMessageList from "./components/MessageList/SupportMessageList";

const SupportChat = () => {
  const profile = useSelector(selectProfile);
  const isAdmin = profile?.role[0] === "developer" || profile?.role[0] ==="admin";
  return (
    <View style={{ flex: 1 }}>
      {isAdmin ? (
        <AdminMessageList />
      ) : (
        <SupportMessageList/>
      )}
    </View>
  );
};

export default SupportChat;
