export type WorkoutsType = {
  // [key: string]: {
  //   name: string;
  //   id: number;
  // };
  name: string;
  id: number | "";
};

export type LocationsType = {
  1: string;
  2: string;
  3: string;
};

export type WorkoutProgramType = {
  id: number;
  location_id: number;
  type: number;
  name: string;
  description: string | null;
  days_count: number | null;
  auto: number;
  date_begin: string | null;
  date_end: string | null;
  blocked: number;
  sort: string | null;
  created_at: string;
  updated_at: string;
};

export type ProgramType = {
  day: number;
  id: number;
  program_id: number;
  description: string;
  link: string;
  program: {
    id: number;
    name: string;
    description: string;
    days_count: number;
    auto: number;
    date_begin: string;
  };
  calendar: {
    id: number;
    type: number;
    program_day_id: number;
    date: string;
    status: number;
    duration: null | number;
    kcal: null;
  };
  exercises: Exercises[];
  exercise_ids: [];
  exercise_ids_with_last_result: [];
};

export type Approach = {
  id: number;
  weight: number;
  repeat: number;
  pre: number;
  event_weight: number;
  event_repeat: number;
  user_result: {
    id: number;
    weight: string;
    repeat: number;
    pre: string;
    rm: string;
  } | null;
  last_result: {
    weight: null;
    repeat: null;
    pre: null;
    rm: null;
    view_weight: null;
    view_repeat: null;
    view_pre: null;
    view_rm: null;
  };
  prev_weight: null;
  view_data: {
    weight: null;
    repeat: null;
    pre: null;
    weight_text: null;
  };
  is_additional: number;
};

export type Exercises = {
  id: number;
  book_exercise_id: number;
  is_additional: number;
  exercise: string;
  approachs: Approach[];
};

export type ProgramByDayType = {
  id: number;
  program_id: number;
  day: number;
  link: string;
  program: {
    id: number;
    name: string;
    description: null | string;
    days_count: number;
    auto: number;
    date_begin: string; // YYYY-MM-DD;
  };
  exercises: Exercises[];
  exercise_ids: [];
  exercise_ids_with_last_result: [];
};

export type SaveProgramType = {
  location_id: number;
  program_id: number;
  date_begin: string;
  first_training_id: string;
};

/**
 * created_at example: "2022-06-22T07:27:09.000000Z"
 */
export type SaveApporachSuccess = {
  user_id: number;
  program_id: number;
  program_day_id: number;
  program_day_exercise_id: number;
  program_day_exercise_approach_id: number;
  book_exercise_id: number;
  is_additional: boolean;
  weight: number;
  repeat: number;
  pre: number;
  calc_weight: number;
  calc_repeat: number;
  calc_pre: number;
  created_at: string;
  id: number;
};

export type SaveApproachParams = {
  program_day_exercise_approach_id: number;
  weight: number;
  repeat: number;
  pre: number;
  view_weight: number;
  view_repeat: number;
  view_pre: number;
  is_additional: number;
};

export type MainCalendarRequestSuccess = MainCalendarItem[];

// DEPRECEATED (27.06.22 changed)
// export type MainCalendarRequestSuccess = {
//   [key: string]: {
//     date: string;
//     title: string;
//     day: number;
//   }[];
// };

export type MainCalendarItem = {
  id: number;
  type: number;
  program_day_id: number;
  date: string;
  status: number;
  duration: null | number; // ?
  kcal: null; // ?
  title: string;
  day: number;
  exercises: {
    id: number;
    book_exercise_id: number;
    is_additional: number;
    exercise: string;
  }[];
};

// DEPRECEATED (27.06.22 changed)
// export type MainCalendarItem = {
//   id: number;
//   type: number;
//   user_id: number;
//   program_day_id: number;
//   date: string;
//   status: number;
//   status_at: string;
//   created_at: null;
//   updated_at: null;
//   programDay: {
//     id: number;
//     program_id: number;
//     day: number;
//     blocked: number;
//     created_at: null;
//     updated_at: string;
//     exercises: {
//       id: number;
//       book_exercise_id: number;
//       is_additional: number;
//       exercise: string;
//     }[];
//     exercise_ids: [];
//     exercise_ids_with_last_result: [];
//   };
//   program_day: {
//     id: number;
//     program_id: number;
//     day: number;
//     blocked: number;
//     created_at: null;
//     updated_at: string;
//     exercises: {
//       id: number;
//       program_day_id: number;
//       book_exercise_id: number;
//       is_additional: number;
//       exercise: {
//         id: number;
//         name: string;
//         created_at: string;
//         updated_at: string;
//       };
//       approachs: {
//         id: number;
//         weight: string;
//         repeat: string;
//         pre: number;
//         event_weight: number;
//         event_repeat: number;
//         program_day_exercise_id: number;
//         user_result: null;
//         last_result: {
//           weight: number | null;
//           repeat: number | null;
//           pre: number | null;
//           rm: number | null;
//           view_weight: number | null;
//           view_repeat: number | null;
//           view_pre: number | null;
//           view_rm: number | null;
//         };
//         prev_weight: null;
//         view_data: {
//           weight: number | null;
//           repeat: number | null;
//           pre: number | null;
//           weight_text: null;
//         };
//         is_additional: number; // 0 | 1 === false | true
//       }[];
//     }[];
//     exercise_ids: [];
//     exercise_ids_with_last_result: [];
//   };
// };

export type UpdateExerciseRequest = {
  id: number;
  data: { date: string; status?: number };
};

export type UpdateExerciseResponseSuccess = {
  id: number;
  type: number;
  user_id: number;
  program_day_id: number;
  date: string;
  status: number;
  status_at: null | string;
  status_start_at: null | string;
  status_end_at: null | string;
  created_at: null | string;
  updated_at: null | string;
};

export enum WorkoutStatus {
  default = 1,
  start = 2,
  end = 3,
  skip = 4,
}

export type WorkoutTestType = {
  title: string;
  answers: [{ title: string; balls: number }];
};

export type SaveTestType = {
  result: number;
};

export type LoadCalendarParams = {
  start_date: string;
  end_date: string;
};

export type CalendarText = {
  text: string;
  type: number;
};
